import React from 'react';
import axios from 'axios';
import CssBaseline from '@material-ui/core/CssBaseline';
import PrimarySearchAppBar from '../components/Appbar'
import Helmet from 'react-helmet';
import { Container, Grid, Typography } from '@material-ui/core';
import ListCard from '../components/ListCard';
import CarbonAds from '../components/carbonAds';

let adCount = 0;


const BundleList = (props) => {

    //Ad Rerender
    const [adKey, setadKey] = React.useState(234);
    React.useEffect(() => {
        setInterval(() => {
            if (adCount < 4) {
                setadKey(Math.random() * 10000);
                adCount++;
            }
        }, 1000 * 40);
    }, []);


    return (
        <React.Fragment>
            <CssBaseline />

            <Helmet>
                <title>{`Asset Packs - Poly Pizza`}</title>
                <meta name="description" content={`Download free low poly asset packs in FBX or GLTF. No login required. Ready for Unity, Unreal or Godot.`} />
            </Helmet>
            <PrimarySearchAppBar loggedIn={props.isLoggedIn} history={props.history} isLoading={props.isLoading} />
            <Container maxWidth="xl" className='mt-[5vh]'>
                <Typography variant="h2" component={"h1"} className='!mb-12 text-center !font-light'>Free low poly asset packs</Typography>
                {/* <Typography variant="h6" className='!mb-8'>(This feature is still a WIP - more bundles + search coming soon!)</Typography> */}
                <Grid container spacing={4} id="">
                    {props.bundles.map((list, i: number) => (
                        <Grid item key={list ? list.PublicID : i} xs={12} sm={6} md={4} lg={3}>
                            <ListCard
                                index={i}
                                list={list}
                                noAvatar
                            />
                        </Grid>
                    ))}
                </Grid>
                <CarbonAds key={adKey} />
            </Container>
        </React.Fragment>
    );
}


BundleList.getInitialProps = async ({ req, res, match, history, location, ...ctx }) => {

    //@ts-ignore
    const isLoggedIn = req !== undefined ? ctx.authed : authed;  //if SSR, use authed from context, otherwise use local var;
    let darkMode = ctx.darkMode;
    const bundles = (await axios.get(`${process.env.SITE_ROOT}/api/bundles/all`)).data;
    return { isLoggedIn, darkMode, bundles }
}

export default BundleList;