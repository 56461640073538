import { Grid, IconButton, MenuItem, Paper, Select, Tooltip } from '@material-ui/core';
import React, { useEffect } from 'react';
import CreateGradientString from '../util/CreateGradientString';
import AnimationSlider from './AnimationSlider';

import AutoRotateIcon from '@material-ui/icons/Rotate90DegreesCcw';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { Pause as PauseIcon, PlayArrow } from '@material-ui/icons';
import { modelCardProps, ModelDetail } from '../types/modelcard';
import ModelViewerSetup from '../util/modelViewerInit'
import GetModelSource from '../util/GetModelSrc';

export default function PizzaViewer(props: {
    OpenButton?: boolean;
    model: ModelDetail; className?: string; elevation?: number; paperClassName?: string;
}) {

    //Animation control
    const [playState, setPlayState] = React.useState(true);
    const [showAnimBar, setshowAnimBar] = React.useState(false);
    const [animations, setAnimations] = React.useState([]);
    const [currentAnimation, setCurrentAnimation] = React.useState('0');
    const [isFullscreen, setisFullscreen] = React.useState(false);

    const modelViewer = React.useRef<any>();

    useEffect(() => ModelViewerSetup(), []);


    function togglePause() {
        setPlayState(!playState);
        if (playState) modelViewer.current.pause()
        else modelViewer.current.play()
    }

    function ChangeAnim(anim) {
        const mv = modelViewer.current;
        const i = anim.target.value;
        mv.setAttribute('animation-name', mv.availableAnimations[i]);
        setCurrentAnimation(i);
    }

    function ToggleModelAutoRotate() {
        if (modelViewer.current !== undefined) modelViewer.current.autoRotate = !modelViewer.current.autoRotate;
    }

    const viewer = React.useRef<HTMLDivElement>();
    function Fullscreen() {

        if (!isFullscreen)
            viewer.current.requestFullscreen();
        else
            document.exitFullscreen();
        setisFullscreen(!isFullscreen);
    }

    useEffect(() => {
        //Init model viewer - called each page load
        ChangeAnim({ target: { value: '0' } });
        const mvInit = () => {
            //set orbit
            const mv = modelViewer.current;
            if (!mv) return;

            mv.cameraOrbit = `${props.model.Orbit.theta}rad ${props.model.Orbit.phi}rad ${props.model.Orbit.radius}m`;
            mv.resetTurntableRotation(0);

            //Check for anims
            setshowAnimBar(mv.availableAnimations.length > 0);
            if (mv.availableAnimations.length > 0) {
                setAnimations(mv.availableAnimations.map((s) => s.includes('|') ? s.split('|')[1] : s));
                mv.play();
            }
        }
        if (modelViewer.current) modelViewer.current.addEventListener("load", mvInit);

        return () => {
            if (modelViewer.current) modelViewer.current.removeEventListener("load", mvInit);
        }
    }, [props.model]);

    const bgGradient = CreateGradientString(props.model.Background.one, props.model.Background.two, props.model.Background.type);

    return (
        <Grid item className={props.className}>
            <Paper elevation={props.elevation ?? 0} className={'w-full h-full relative ' + props.paperClassName} style={{ background: bgGradient, borderRadius: '0' }} ref={viewer}>
                <div className={'flex flex-row-reverse absolute top-3 right-3 z-10'}>
                    {
                        props.OpenButton ? (
                            <Tooltip title="Open in New tab">
                                <IconButton aria-label="Open in New tab" onClick={() => window.open('/m/' + props.model.PublicID, '_blank')} >
                                    <OpenInNewIcon style={{ color: 'white' }} />
                                </IconButton>
                            </Tooltip>
                        ) :
                            (
                                <>
                                    <Tooltip title="Toggle Turntable">
                                        <IconButton
                                            aria-label="Auto rotate"
                                            onClick={ToggleModelAutoRotate}>
                                            <AutoRotateIcon style={{ color: 'white' }} />
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip title="FullScreen">
                                        <IconButton aria-label="FullScreen" onClick={Fullscreen} >
                                            <FullscreenIcon style={{ color: 'white' }} />
                                        </IconButton>
                                    </Tooltip>
                                </>
                            )
                    }
                    {/* {AR && <Tooltip title="Activate AR"><IconButton aria-label="Activate AR" onClick={() => mv.current.activateAR()} ><CameraEnhanceIcon style={{ color: 'white' }} /></IconButton></Tooltip>} */}
                </div>

                <model-viewer
                    ref={modelViewer}
                    oncontextmenu="return false;"
                    id="mainModel"
                    environment-image="https://static.poly.pizza/hdr/BG.hdr"
                    class={'w-full h-full'}
                    // class={`noMenu`}
                    src={GetModelSource(props.model.ResourceID)}
                    alt={props.model.Title}
                    auto-rotate
                    camera-controls
                    shadow-intensity="1"
                    interaction-prompt="auto"
                    loading="eager"
                    onerror={(error) => console.error(error)}
                    // autoplay={props.model.Animated}
                    camera-orbit={props.model.Orbit ? `${props.model.Orbit.theta}rad ${props.model.Orbit.phi}rad ${props.model.Orbit.radius}m` : '0deg 75deg 105%'}
                    style={{ "--poster-color": '#303030' }}
                    enable-pan
                    field-of-view="42deg"
                    max-field-of-view="42deg"
                ></model-viewer>

                {/* AnimationPlayer */}
                {showAnimBar &&
                    <div className='relative mx-0 pb-3'>
                        <div className='absolute flex items-center bottom-7 right-0 w-full h-full'>
                            <IconButton
                                aria-label="Play/Pause"
                                className='bg-opacity-0'
                                onClick={togglePause}>
                                {playState ? <PauseIcon /> : <PlayArrow />}
                            </IconButton>
                            <AnimationSlider modelViewer={modelViewer} setPlayState={setPlayState} />
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={currentAnimation}
                                defaultValue={0}
                                className={'w-48 h-full ml-5'}
                                disableUnderline
                                onChange={ChangeAnim}
                            >
                                {animations.map((anim, index) => {
                                    return <MenuItem key={anim} value={index}>{anim}</MenuItem>
                                })}
                            </Select>
                        </div>
                    </div>}
            </Paper>
        </Grid >
    )
}