import React from 'react';
import Slider from '@material-ui/core/Slider';

const MVSlider = (props) => {

    //Animate the slider
    const requestRef = React.useRef(null);
    const [sliderVal, setSliderVal] = React.useState(0);
    React.useEffect(() => {
        requestRef.current = requestAnimationFrame(animateSlider);
        return () => cancelAnimationFrame(requestRef.current);
    }, []);

    const animateSlider = time => {
        // The 'state' will always be the initial value here
        requestRef.current = requestAnimationFrame(animateSlider);
        if (props.modelViewer.current) {
            const t = (props.modelViewer.current.currentTime / props.modelViewer.current.duration) * 100;
            if (t !== undefined && !isNaN(t))
                setSliderVal(t);
        }
    }

    const handleSliderChange = (event, newValue) => {
        setSliderVal(newValue);    
        const mv = props.modelViewer.current;
        if (!mv.paused) {
            mv.pause();
            props.setPlayState(false)
        }
        mv.currentTime = mv.duration * (newValue / 100);
    };

    return (
        <Slider value={sliderVal} onChange={handleSliderChange} key="slideyboye" aria-labelledby="animation-slider" />   
    )
    
}


export default MVSlider;