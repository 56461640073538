import { createTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';

// Create a theme instance.
export default function getTheme(useDarkMode) {
  return createTheme({
    palette: {
      type: 'dark',
      primary: {
        main: '#ffa24d',
      },
      secondary: {
        main: '#1c1b1f',
      },
      error: {
        main: red.A400,
      },
      background: {
        default: '#252429',
        paper: '#36343c'
      },
      gradient: {
        one: '#f36bd2',
        two: '#5650DE',
      },
      grey: {
        "50":  "#fafafa",
        "100": "#f5f5f5",
        "200": "#eeeeee",
        "300": "#e0e0e0",
        "400": "#bdbdbd",
        "500": "#9e9e9e",
        "600": "#757575",
        "700": "#49454f", //yep
        "800": "#33b3e3",
        "900": "#212121",
        "A100": "#d5d5d5",
        "A200": "#aaaaaa",
        "A400": "#33b3e3",
        "A700": "#49454f", //yep
      }
    },
    //default props
    props: {
      MuiCard: {
        elevation: 2,
      },
      MuiAppBar: {
        elevation: 2,
      },
    },
    typography: {
      button: {
        textTransform: "capitalize"
      },
      h6: {
        fontWeight: 400,
      }
    }
  });
}