    import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import MUILink from '@material-ui/core/Link'
import { Link } from 'react-router-dom';
import Arrow from '@material-ui/icons/ArrowForward';

const useStyles = makeStyles((theme) => ({
    GradientText: {
        background: `linear-gradient(42deg, ${theme.palette.gradient.one} -60%, ${theme.palette.gradient.two} 90%)`,
        '-webkitBackgroundClip': "text",
        textFillColor: "transparent",
    }
}));

const CallToAction = (props) => {
    const classes = useStyles();
    return (
        <Container maxWidth="lg" style={{ paddingTop: '50px', paddingBottom: props.noPadding ? '0px' : '20px' }}>
            <MUILink component={Link} to={'/support'} style={{textDecoration: 'none' }}>
                <Typography color="textPrimary" variant="h3" style={{ textAlign: 'center', fontWeight: '300'}}>
                    {/*We need <b className={classes.GradientText}>your help</b> to keep Poly Pizza free*/}
                    Supported by <b className={classes.GradientText}>You</b>
                    {/*Community supported by <b className={classes.GradientText}>you.</b>*/}
                    {(props.noArrow === undefined) && <Arrow style={{marginLeft: '5px', fontSize: '0.6em'}} /> /*show arrow by default*/ }
                </Typography>
            </MUILink>
        </Container>
    );
}

export default CallToAction;