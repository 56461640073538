import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

interface dropProps {
    setFiles: Function,
    accept?: string,
    maxFiles: number
    uploadText: Object,
    zoneStyle?: Object,
}

export default function DropZone(props: dropProps) {
    const onDrop = useCallback(files => {
        if(files.length == 0) return;

        props.setFiles(files);
        setfileName(files[0].name);
    }, [])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: props.accept,
        maxFiles: props.maxFiles,
    })
    const [fileName, setfileName] = React.useState('');

    return (
        <div {...getRootProps()} style={props.zoneStyle}>
            <input {...getInputProps()} />

            <div style={{
                width: "100%",
                height: "100%",
                textAlign: "center",
                fontWeight: 400,
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
            }}>
                {fileName === '' ? props.uploadText : 'Uploading: ' + fileName}
            </div>

        </div>
    )
}