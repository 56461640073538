import React from 'react';
import axios from 'axios';
import CssBaseline from '@material-ui/core/CssBaseline';
import PrimarySearchAppBar from '../components/Appbar'
import Helmet from 'react-helmet';
import { Avatar, Grid, IconButton } from '@material-ui/core';
import Socials from '../util/Socials';
import MUILink from '@material-ui/core/Link'
import { Link } from 'react-router-dom';

interface propTypes {
    Creator: { Socials?: any; Username: any; DPURL: string; };
    className?: string;
    whiteIcons?: boolean;
    hideSocials?: boolean;
}

export default function CreatorInfo(props: propTypes) {
    let socials;
    if (props.Creator.Socials)
        socials = Socials.Unwrap(props.Creator.Socials).slice(0, 3); //only get first 3 socials
    return (
        <>
            <div className={"m-0 p-2 flex items-center justify-start gap-4 " + props.className}>
                <Avatar alt={`Avatar for ${props.Creator.Username}`} src={props.Creator.DPURL} />
                <h3 className='!font-medium !m-0'>
                    <MUILink color="textPrimary" className='!text-white-light !m-0' to={`/u/${props.Creator.Username}`} component={Link}>
                        {props.Creator.Username}
                    </MUILink>
                </h3>
                <div className='flex gap-1'>
                    {!props.hideSocials && socials && socials.map((Site) => {
                        return (
                            <IconButton
                                size="small"
                                aria-label={Site.siteName}
                                target="_blank"
                                rel="noopener ugc"
                                href={Site.link}
                            >
                                <Site.icon className={'text-[#80868b] ' + (props.whiteIcons && 'brightness-200')} />
                            </IconButton>
                        );
                    })}
                </div>
            </div>
        </>
    );
}
