const Gradient = [
    { one: '#FF6CAB', two: '#7366FF' },
    { one: '#b65eba', two: '#2e8de1' },
    { one: '#64E8DE', two: '#8A64EB' },
    { one: '#7BF2e9', two: '#B65EBA' },
    { one: '#FF9482', two: '#7d77FF' },

    { one: '#FFCF1B', two: '#FF881B' },
    { one: '#FFA62E', two: '#EA4D2C' },
    { one: '#00FFED', two: '#00B8BA' },
    { one: '#6EE2F5', two: '#6454F0' },
    { one: '#3499FF', two: '#3A3985' },

    { one: '#FF9897', two: '#F650A0' },
    { one: '#FFCDA5', two: '#EE4D5F' },
    { one: '#FF5B94', two: '#8441A4' },
    { one: '#F869D5', two: '#5650DE' },
    { one: '#F00B51', two: '#750062' },
];

const Solid = [
    {one: '#e53935'},
    {one: '#d81b60'},
    {one: '#8e24aa'},
    {one: '#5e35b1'},
    {one: '#3949ab'},
    {one: '#1e88e5'},
    {one: '#039be5'},
    {one: '#00acc1'},
    {one: '#00897b'},
    {one: '#43a047'},
    {one: '#7cb342'},
    {one: '#c0ca33'},
    {one: '#fdd835'},
    {one: '#ffb300'},
    {one: '#fb8c00'},
    {one: '#f4511e'},
    {one: '#6d4c41'},
    {one: '#757575'},
    {one: '#546e7a'},
    {one: '#000000'},
];

export default {Solid, Gradient}

