import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import {Link} from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import MLink from '@material-ui/core/Link';
import { useForm } from 'react-hook-form';
// import validator from 'validator';
import Axios from 'axios';
import { useSnackbar } from 'notistack';
import LoadingBar from '../components/LoadingBar'
import DisplayAxiosError from '../util/DisplayAxiosError'
import { Helmet } from 'react-helmet';


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const resetpwd = (props) => {
    const classes = useStyles();
    const { register, handleSubmit, watch, errors } = useForm();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [loadingEnabled, setLoadingBar] = React.useState(false);
    const passwordRef = React.useRef();

    const onSubmit = data => {
        const urlParams = new URLSearchParams(window.location.search);
        data.resetToken = urlParams.get('t');
        if(!data.resetToken) {
            enqueueSnackbar("Token error, please check that the URL is correct", { variant: 'error' });
            return;
        }

        delete data.passwordConfirm;

        setLoadingBar(true);
        grecaptcha.ready(function () {
            grecaptcha.execute('6Lc_LAcaAAAAAJ8RT1-vgmA-GsTDH0KJBZ_toZZV', { action: 'forgotpwd' }).then(async function (token) {
                await Axios.post("/api/pwd/reset", { data, token, }).then(function (response) {
                    setLoadingBar(false);
                    const action = key => (
                        <React.Fragment>
                            <Button>
                                <MLink to='/login' component={Link} style={{color:'white'}}>Login</MLink>
                            </Button>
                        </React.Fragment>
                    );
                    enqueueSnackbar("New password set successfully", { variant: 'success', action});
                }).catch(function (e) {
                    setLoadingBar(false);
                    DisplayAxiosError(e, enqueueSnackbar);
                });
            });
        });
    }

    const passwordHelperText = (
        <span>Password must be at least 12 characters, including one special character and one number.</span>
    )

    return (
        <React.Fragment>
            <Helmet>
                {/* <script src="https://www.google.com/recaptcha/api.js?render=6Lc_LAcaAAAAAJ8RT1-vgmA-GsTDH0KJBZ_toZZV"></script> */}
            </Helmet>
            <LoadingBar enabled={loadingEnabled} />
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Reset Password
        </Typography>
                    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            name="password"
                            label="New Password"
                            type="password"
                            id="password"
                            margin="normal"
                            helperText={errors.password?.message}
                            error={errors.password !== undefined}
                            inputRef={(e) => {
                                register(e, {
                                    required: 'Required',
                                    minLength: {
                                        value: 12,
                                        message: 'Password too short'
                                    },
                                    maxLength: {
                                        value: 50,
                                        message: "bruh"
                                    },
                                    pattern: {
                                        value: /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~].*[0-9]|[0-9].*[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/, //lol
                                        message: 'Password must contain one special character and one number'
                                    }
                                })
                                passwordRef.current = e
                            }
                            }
                        />
                        <TextField
                            variant="outlined"
                            fullWidth
                            name="passwordConfirm"
                            label="Confirm new Password"
                            type="password"
                            margin="normal"
                            error={errors.passwordConfirm !== undefined}
                            helperText={errors.passwordConfirm?.message}
                            inputRef={register({
                                validate: value => value === passwordRef.current.value || `Passwords don't match`,
                            })}
                        />

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Reset Password
                        </Button>
                    </form>
                </div>
                <Box mt={8}>
                    <Typography variant="body2" color="textSecondary" align="center">
                        This site is protected from robots by reCAPTCHAv3, meaning the <MLink href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">Google Privacy Policy</MLink> and <MLink href="https://policies.google.com/terms" target="_blank" rel="noreferrer">Terms of Service</MLink> also apply.
                    </Typography>
                </Box>
            </Container>
        </React.Fragment>
    );
}


export default resetpwd;