import { Button, Dialog, DialogActions, DialogTitle, IconButton, Typography } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import type { ModelDetail } from '../types/modelcard';
import CreatorInfo from "./CreatorInfo";
import PizzaViewer from "./Pizza-Viewer";
import { Favorite as HeartIcon, PlaylistAdd, Launch, ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import ModelDetailTitle from "./ModelDetailTitle";
import { useSnackbar } from "notistack";
import ListSelectDialog from "./ListSelectDialog";

interface propTypes {
    modelID: string | null;
    open: boolean;
    setOpen: (arg0: boolean) => void;
    changeModel?: (arg0: number) => void;
    isLoggedIn: boolean;
}

export default function ModelModal(props: propTypes) {

    const [model, setmodel] = useState<ModelDetail | null>(null);
    const [likeState, setLikeState] = useState(false);
    const [listSelectOpen, setlistSelectOpen] = useState(false);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    useEffect(() => {
        if(props.modelID === null) return;
        
        axios.get(`/api/model/${props.modelID}/details`).then(res => {
            setmodel(res.data);
            setLikeState(res.data.liked);
        });

        //Set initial like state
        //@ts-ignore
        if (authed) {
            const setInteractions = (d) => {
                setLikeState(d.data.LikedByUser);
                // setCanEdit(d.data.CanEdit);
            }
            axios.get(`/api/model/${props.modelID}/details/interactions`, { withCredentials: true }).then(setInteractions);
        }

    }, [props.modelID])

    if (model === null) return null;

    async function ToggleLikeState() {
        if (!props.isLoggedIn) {
            enqueueSnackbar("You need to be logged in to do that", { variant: 'info', });
            return;
        }

        setLikeState(!likeState);
        axios.post(`${process.env.SITE_ROOT}/api/model/${props.modelID}/like`, { liked: !likeState });
    }


    return (
        <>
            <Dialog
                open={props.open}
                onClose={() => props.setOpen(false)}
                maxWidth={'xl'}
            >
                {/* only render model if it's not null */}
                {model && <div className="relative aspect-video h-[70vh]">
                    <CreatorInfo Creator={model.Creator} className={'absolute top-2 left-2 z-10'} whiteIcons />
                    {props.open && <PizzaViewer model={model} className={'w-full h-full'} />}
                </div>}

                <div className="flex justify-between items-center px-5 py-3 w-full bg-gray-darkish">

                    <ModelDetailTitle model={model} />
                    <div className="flex items-center">
                        {props.changeModel &&
                            <IconButton onClick={() => props.changeModel(-1)} size="small" className="scale-75">
                                <ArrowForwardIos className="scale-x-[-1]" />
                            </IconButton>
                        }
                        <IconButton onClick={ToggleLikeState}>
                            <HeartIcon color={likeState ? "primary" : "action"} style={{ transition: '0.4s' }} />
                        </IconButton>
                        <IconButton onClick={() => setlistSelectOpen(true)}>
                            <PlaylistAdd color="action" />
                        </IconButton>
                        <IconButton onClick={() => window.open(`/m/${props.modelID}`)}>
                            <Launch color="action" />
                        </IconButton>
                        {props.changeModel &&
                            <IconButton onClick={() => props.changeModel(1)} size="small" className="scale-75">
                                <ArrowForwardIos />
                            </IconButton>
                        }
                    </div >
                </div >
            </Dialog >

            <ListSelectDialog
                open={listSelectOpen}
                setOpen={setlistSelectOpen}
                model={model}
            />
        </>
    )
}