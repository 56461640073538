import { Badge, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import ModelCard from "./ModelCard2";
import { modelCardProps } from "../types/modelcard";

type propTypes = {
    open: boolean;
    query: string;
    list: modelCardProps[],
    setList: any;
    setOpen: (arg0: boolean) => void;
}

export default function QuickSearchDialog(props: propTypes) {

    const [loaded, setLoaded] = useState(false);
    const [models, setModels] = useState([]);

    useEffect(() => {
        if (!props.open || loaded) return; //call api on opened
        //Search for models based on props.query
        axios.get('/api/search/' + props.query).then((d) => {
            setLoaded(true);
            console.log(d.data)
            setModels(d.data.results);
        });
    }, [props.open, props.query]);

    function handleClose() {
        props.setOpen(false);
    }

    function ChangeList(model: modelCardProps) {
        if (props.list.findIndex(o => o.publicID === model.publicID) !== -1) {
            props.setList(props.list.filter(o => o.publicID !== model.publicID));
        } else {
            props.setList([...props.list, model]);
        }
    }

    useEffect(() => {
        if (!props.open) setLoaded(false);
    }, [props.open]);


    return (
        <Dialog
            fullWidth={true}
            maxWidth={"lg"}
            open={props.open}
            onClose={handleClose}
        >
            <DialogTitle style={{ pointerEvents: 'none', userSelect: 'none' }}>
                Search for a different {props.query}
            </DialogTitle>
            <DialogContent>
                {!loaded ? <p>loading...</p> : <SearchGrid models={models} ChangeList={ChangeList} {...props} />}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function SearchGrid(props: { models: modelCardProps[], list: modelCardProps[], ChangeList: (arg0: modelCardProps) => void }) {
    const inList = (model: modelCardProps) => {
        return props.list.findIndex(o => o.publicID === model.publicID) !== -1;
    }
    return (
        <Grid container spacing={4}>
            {props.models.map((card, i) => (
                <Grid item key={card ? card.previewUrl : i} xs={12} sm={6} md={4} lg={3}>
                    <Badge badgeContent={'✔'} invisible={!inList(card)} color="primary" anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} className={'listSelectAnim'}>
                        <ModelCard
                            index={i}
                            model={card}
                            noLink
                            onClick={() => props.ChangeList(card)}
                            noAvatar
                        />
                    </Badge>
                </Grid>
            ))}
        </Grid>
    )
}