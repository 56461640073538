import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import MLink from '@material-ui/core/Link';
import { useForm } from 'react-hook-form';
// import validator from 'validator';
import isEmail from 'validator/lib/isEmail';
import Axios from 'axios';
import { useSnackbar } from 'notistack';
import LoadingBar from '../components/LoadingBar'
import DisplayAxiosError from '../util/DisplayAxiosError'
import { Helmet } from 'react-helmet';


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const emails = new Set();

const forgotpwd = (props) => {
    const classes = useStyles();
    const { register, handleSubmit, watch, errors } = useForm();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [loadingEnabled, setLoadingBar] = React.useState(false);

    const onSubmit = data => {
        if (emails.has(data.email)) {
            enqueueSnackbar("An email has already been sent. Please check your spam folder.", { variant: 'success' });
            return;
        }
        else {
            emails.add(data.email);
        }

        setLoadingBar(true);
        grecaptcha.ready(function () {
            grecaptcha.execute('6Lc_LAcaAAAAAJ8RT1-vgmA-GsTDH0KJBZ_toZZV', { action: 'forgotpwd' }).then(async function (token) {
                await Axios.post("/api/pwd/forgot", { data, token, }).then(function (response) {
                    setLoadingBar(false);
                    enqueueSnackbar("If an account with that email exists, reset inctructions have been sent to it.", { variant: 'success' });
                }).catch(function (e) {
                    setLoadingBar(false);
                    DisplayAxiosError(e, enqueueSnackbar);
                });
            });
        });
    }

    return (
        <React.Fragment>
            {/* <Helmet>
                <script src="https://www.google.com/recaptcha/api.js?render=6Lc_LAcaAAAAAJ8RT1-vgmA-GsTDH0KJBZ_toZZV"></script>
            </Helmet> */}
            <LoadingBar enabled={loadingEnabled} />
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon color="primary" />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Lost Password
                    </Typography>
                    <Typography component="h1" variant="body1" align="center" style={{margin: '15px 0px'}}>
                        Please enter the email you signed up with
                    </Typography>
                    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            error={errors.email !== undefined}
                            helperText={errors.email?.message}
                            inputRef={register({
                                required: 'Required',
                                validate: {
                                    valid: value => isEmail(value) || "hey that's not an email",
                                }
                            })}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Reset Password
                        </Button>
                    </form>
                </div>
                <Box mt={2}>
                    <Typography variant="body2" color="textSecondary" align="center">
                        This site is protected from robots by reCAPTCHAv3, meaning the <MLink href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">Google Privacy Policy</MLink> and <MLink href="https://policies.google.com/terms" target="_blank" rel="noreferrer">Terms of Service</MLink> also apply.
                    </Typography>
                </Box>
            </Container>
        </React.Fragment>
    );
}


export default forgotpwd;