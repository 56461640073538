import React from 'react';
import { alpha, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import { Link, useLocation } from "react-router-dom";
import MUILink from '@material-ui/core/Link';
import MainDrawer from './Drawer';
import UploadModal from './UploadModal.tsx';
import LinearProgress from '@material-ui/core/LinearProgress';
import Logo from './logo';
import PersonIcon from '@material-ui/icons/Person';
import ListIcon from '@material-ui/icons/List';
import SettingsIcon from '@material-ui/icons/Settings';
import PublishIcon from '@material-ui/icons/Publish';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SvgIcon from '@material-ui/core/SvgIcon';
import FavoriteIcon from '@material-ui/icons/Favorite';
import AssistantIcon from '@material-ui/icons/Assistant';
import { Chip } from '@material-ui/core';

const menuId = 'primary-search-account-menu';

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    headerMarginComp: {
        marginBottom: '64px',
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        display: 'none',
        marginLeft: "5px",
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    mobileHide: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        }
    },
    search: {
        //position: 'relative',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',

        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        //marginRight: theme.spacing(2),
        //marginLeft: 0,
        width: '75%',
        [theme.breakpoints.up('sm')]: {
            //marginLeft: theme.spacing(3),
            width: '600px',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
        width: '100%',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    searchExpand: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
}));

const PrimarySearchAppBar = (props) => {
    const [uploadOpen, setUploadOpen] = React.useState(false); //upload modal
    const [user, setUser] = React.useState(null);
    React.useEffect(() => {
        if (!props.loggedIn) return;
        setUser(JSON.parse(localStorage.getItem('user')));
    }, []);

    const location = useLocation();
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    //Drawer state shit
    const [drawerState, setDrawerState] = React.useState(false);
    function ToggleDrawerState(state) {
        setDrawerState(!drawerState);
    }

    //Logged in buttons 
    const topLeftIcons = GetNavComponents(props.loggedIn, classes, handleProfileMenuOpen, location, user, setUploadOpen);

    //Search nav
    const HandleSearchInput = (e) => {
        if (e.key === 'Enter') {
            let query = window.location.href.split('?')[1] ?? '';
            let search = e.target.value;
            search = search.replace(/\\/g, '');
            e.target.value = search;

            if (query.length > 0) query = '?' + query;

            if (search.length > 0)
                props.history.push('/search/' + encodeURIComponent(search) + query);
            else
                props.history.push('/explore'); //redirect to explore if search is empty
        }
    }

    const menuItems = [
        {
            name: 'Profile',
            icon: PersonIcon,
            link: `/u/${user ? user.publicID : ''}`,
        },
        {
            name: 'My Lists',
            icon: ListIcon,
            link: `/u/${user ? user.publicID : ''}/Lists`,
        },
        {
            name: 'AI Asset Pack',
            icon: AssistantIcon,
            link: `/ai`,
        },
        {
            name: 'Settings',
            icon: SettingsIcon,
            link: '/settings/profile',
        },
        // {
        //     name: 'Upload',
        //     icon: PublishIcon,
        //     onClick: () => { setUploadOpen(true); handleMenuClose() },
        // },
        {
            name: 'Logout',
            icon: ExitToAppIcon,
            link: '/logout',
        }
    ];

    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            id={menuId}
            keepMounted
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            {
                menuItems.map((item, index) => {
                    return <MenuItem
                        onClick={item.onClick ?? handleMenuClose}
                        to={item.link}
                        component={Link}
                        className={'!py-3 !px-7 flex gap-3 !text-md font-light'}
                    >
                        <item.icon className={'!mr-3 text-gray-button !ring-3'} />
                        <span>{item.name}</span>
                        {/* {item.link == "/ai" && <Chip label="New!" className='!bg-red-main'></Chip>} */}
                    </MenuItem>
                }
                )
            }
        </Menu>
    )

    // const renderMenu = (
    //     <Menu
    //         anchorEl={anchorEl}
    //         id={menuId}
    //         keepMounted
    //         getContentAnchorEl={null}
    //         anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    //         transformOrigin={{ vertical: 'top', horizontal: 'left' }}
    //         open={isMenuOpen}
    //         onClose={handleMenuClose}
    //     >
    //         <MenuItem
    //             onClick={handleMenuClose}
    //             to={`/u/${user ? user.publicID : ''}`}
    //             component={Link}
    //             className={itemClass}
    //         >
    //             <PersonIcon class />
    //             Profile
    //         </MenuItem>

    //         <MenuItem
    //             onClick={handleMenuClose}
    //             to={`/u/${user ? user.publicID : ''}/Lists`}
    //             className={itemClass}
    //             component={Link}
    //         >
    //             <ListIcon />
    //             Lists
    //         </MenuItem>

    //         <MenuItem
    //             onClick={handleMenuClose}
    //             to='/settings/profile'
    //             className={itemClass}
    //             component={Link}
    //         >
    //             <SettingsIcon />
    //             Settings
    //         </MenuItem>

    //         <MenuItem
    //             onClick={() => { setUploadOpen(true); handleMenuClose() }}
    //             className={itemClass}
    //         >
    //             <PublishIcon />
    //             Upload
    //         </MenuItem>

    //         <MenuItem
    //             onClick={handleMenuClose}
    //             to='/logout'
    //             component={Link}
    //             className={itemClass}
    //         >
    //             <ExitToAppIcon />
    //             Logout
    //         </MenuItem>
    //     </Menu>
    // );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem>
                <IconButton aria-label="show 11 new notifications" color="inherit">
                    <Badge badgeContent={11} color="secondary">
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
                <p>Notifications</p>
            </MenuItem>
            <MenuItem>
                <IconButton
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <AccountCircle />
                </IconButton>
                <p>Profile</p>
            </MenuItem>
        </Menu>
    );

    return (
        <React.Fragment>
            <AppBar position="sticky" color="secondary">
                <Toolbar>
                    {/* 
                    <IconButton
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => setDrawerState(!drawerState)}
                    >
                        <MenuIcon />
                    </IconButton> */}
                    <MainDrawer isOpen={drawerState} setState={setDrawerState} />
                    <Link to="/" style={{ lineHeight: 0 }}><Logo className={classes.mobileHide} style={{ fontSize: '2em' }}></Logo></Link>
                    <MUILink className={classes.title} style={{ color: '#fff' }} noWrap to='/' component={Link}>
                        <Typography variant="h6" className='!font-mono !tracking-tighter !text-[#ffffff]'>
                            <>poly<span className='ml-[-3px]'>.pizza</span></>
                        </Typography>
                    </MUILink>
                    {props.history.location.pathname !== '/' &&
                        <div className={`${classes.search} `}>
                            <div className={classes.searchIcon}>
                                <SearchIcon />
                            </div>
                            <InputBase
                                defaultValue={props.query || ''}
                                placeholder="Search"
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                inputProps={{ 'aria-label': 'search' }}
                                onKeyPress={HandleSearchInput}
                            />
                        </div>}
                    <div className={classes.grow} />
                    <div className={classes.sectionDesktop}>
                        {topLeftIcons}  {/*Top left icons that are changed on login state*/}
                    </div>
                    {/*
                    <div className={classes.sectionMobile}>
                        <IconButton
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            <MoreIcon />
                        </IconButton>
                    </div>
                    */}
                </Toolbar>
                {props.isLoading && <LinearProgress style={{ marginTop: '-4px' }} />}
            </AppBar>
            {/*renderMobileMenu*/}
            {renderMenu}
            <UploadModal open={uploadOpen} setOpen={setUploadOpen} />
        </React.Fragment>
    );
}


function GetNavComponents(loggedIn, classes, handleProfileMenuOpen, location, user, setUploadOpen) {
    if (user || loggedIn) {
        return (
            <div className='flex gap-1'>

                <IconButton aria-label="support" color="inherit" className='' size='small' component={Link} to={`/support`}>
                    <SvgIcon color="primary" viewBox="0 0 48 48"><FavoriteIcon /></SvgIcon>
                </IconButton>

                <IconButton aria-label="upload" color="inherit" className='' onClick={() => setUploadOpen(true)}>
                    <SvgIcon fontSize="large" className='text-gray-text-sec' viewBox="0 0 48 48"><path d="M11 40q-1.2 0-2.1-.9Q8 38.2 8 37v-7.15h3V37h26v-7.15h3V37q0 1.2-.9 2.1-.9.9-2.1.9Zm11.5-7.65V13.8l-6 6-2.15-2.15L24 8l9.65 9.65-2.15 2.15-6-6v18.55Z" /></SvgIcon>
                </IconButton>

                <IconButton
                    edge="end"
                    aria-label="account of current user"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    onClick={handleProfileMenuOpen}
                    color="inherit"
                >
                    <Avatar
                        alt={user ? user.publicID : ''}
                        src={user && user.DPURL ? `${user.DPURL}` : ''}>
                        {user && !user.DPURL ? user.publicID[0] : ''}
                    </Avatar>
                </IconButton>
            </div>);
    }
    if (loggedIn == false) {
        return (
            <div className='flex gap-2'>

                <IconButton aria-label="support" color="inherit" className='' size='small' component={Link} to={`/support`}>
                    <SvgIcon color="primary" viewBox="0 0 48 48"><FavoriteIcon /></SvgIcon>
                </IconButton>

                <IconButton aria-label="upload" color="inherit" className='' size='small' component={Link} to={`/signup`}>
                    <SvgIcon fontSize="large" className='text-gray-text-sec' viewBox="0 0 48 48"><path d="M11 40q-1.2 0-2.1-.9Q8 38.2 8 37v-7.15h3V37h26v-7.15h3V37q0 1.2-.9 2.1-.9.9-2.1.9Zm11.5-7.65V13.8l-6 6-2.15-2.15L24 8l9.65 9.65-2.15 2.15-6-6v18.55Z" /></SvgIcon>
                </IconButton>

                <Button size="large"
                    className={'!font-light'}
                    variant="outlined"
                    // color="default"
                    component={Link}
                    to={`/login?from=${encodeURIComponent(location.pathname)}`}
                >Login</Button>

                <Button size="large"
                    className={'!font-normal'}
                    variant="outlined"
                    color="primary"
                    component={Link}
                    to="/signup"
                >Sign up</Button>
            </div>
        );
    }
    // else{
    //     return false;
    // }
}

export default PrimarySearchAppBar;