export default function DisplayAxiosError (error, enqueueSnackbar) {
    if (error.response && error.response.data.error) {
        enqueueSnackbar(error.response.data.error, { variant: 'error', });
    }
    else if(error.response && error.response.status) {
        enqueueSnackbar(`Something went wrong: ${error.message}`, { variant: 'error', });
    }
    else
    {
        enqueueSnackbar(`Something went wrong`, { variant: 'error', });
    }
}