import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const DeleteDialog = (props) => {

    const Close = () => {
        props.setState({open: false, action: null});
    };

    const Continue = () => {
        props.state.action();
        props.setState({open: false, action: null});
    };


    return (
        <Dialog
            open={props.state.open}
            onClose={Close}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{props.content}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={Close} color="textPrimary">
                    Nah
          </Button>
                <Button onClick={Continue} color="textPrimary" autoFocus>
                    Yeah
          </Button>
            </DialogActions>
        </Dialog>);
}

export default DeleteDialog;