import React from 'react';
import { hydrate } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ensureReady, After } from '@jaredpalmer/after';
import { ThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import routes from './routes';
import getTheme from './theme';
import './css/global.css';
import './css/md.css';
if(process.env.NODE_ENV !== 'production') import('./css/dev.css');

import '@google/model-viewer/dist/model-viewer';
import 'focus-visible';
import { Scrollbar } from 'react-scrollbars-custom';
import posthog from 'posthog-js'

posthog.init('phc_XGkpLgakJmJ8LtwNVIzs9jO1XJXeKhMpxDDAntTiKW7', { api_host: 'https://app.posthog.com' })


// darkmode carbon ads
if (darkMode) {
  import('./css/carbondark.css')
}
else {
  import('./css/carbonlight.css')
}


function Main({ data }) {
  process.env.SITE_ROOT = location.protocol + '//' + location.host;

  React.useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  const theme = getTheme(darkMode);

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Scrollbar noScrollX createContext={true} style={{ width: '100vw', height: '100vh' }}>
          <SnackbarProvider maxSnack={3}>
            <After data={data} routes={routes} authed={authed} darkMode={darkMode} />
          </SnackbarProvider>
        </Scrollbar>
      </ThemeProvider>
    </BrowserRouter>
  );
}

ensureReady(routes).then(data =>
  hydrate(<Main data={data} />, document.getElementById('root'))
);

if (module.hot) {
  module.hot.accept();
}

//disable right click for model viewer
// [...document.querySelectorAll(".noMenu")].forEach(el =>
//   el.addEventListener('contextmenu', e => e.preventDefault())
// );

//Model viewer stuff
//panning();