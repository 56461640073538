import { Typography } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import type Post from "../types/Post";
import twas from 'twas';

export default function BlogCard(props) {
    const post : Post = props.post;
    return (
        <Link to={post.Url} className="text-white-light">
            <div className='flex flex-col gap-2 relative rounded-md MuiPaper-elevation2'>
                <img className='w-full rounded-md' src={post.Thumbnail ? `https://static.poly.pizza/${post.Thumbnail}` : '/img/placeholder.png'} />
                <div className="absolute top-0 w-full h-full px-7 py-6 bg-gradient-to-t from-[#000000b1] to-[#00000000] flex items-end justify-start rounded-md">
                    <Typography variant="h4" className='!text-2xl 2xl:!text-4xl !w-3/4 !font-light !m-0'>{post.Title}</Typography>
                </div>
            </div>
        </Link>
    )
}