import React from 'react';
import axios from 'axios';
import qs from 'qs';
import PrimarySearchAppBar from '../components/Appbar'
import Helmet from 'react-helmet';
import Grid from '@material-ui/core/Grid';
import ModelCard from '../components/ModelCard';
import { Button, CardContent, Collapse, Typography, Card, Container, CssBaseline, IconButton, Divider, FormControl, InputLabel, MenuItem, Paper, } from '@material-ui/core';
import MUILink from '@material-ui/core/Link';
import GetURL from '../util/GetFuzzyLocation';
import AffiliateInfoPopup from '../components/AffiliateInfoPopup';
import HelpIcon from '@material-ui/icons/Help';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import QuickSurvey from '../util/quickSurvey';
import CarbonAds from '../components/carbonAds';
import { ScrollbarContext } from 'react-scrollbars-custom';
import { Catagories, Licences, URLCatagories, SubCats, Horny } from '../util/HardCodedLists';
import Select from '@material-ui/core/Select';
import FilterListIcon from '@material-ui/icons/FilterList';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import PuppyBlock from '../components/PuppyBlock';
import SalesPopup from '../components/salesPopup';

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

let adCount = 0;

const useStyles = makeStyles((theme) => ({
    icon: {
        fontSize: '0.5em',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 240,
    },
    SortSelect: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    paperPad: {
        padding: theme.spacing(2),
    },
    infoCard: {
        maxWidth: 'none',
        marginBottom: '20px',
        [theme.breakpoints.up('sm')]: {
            maxWidth: '25vw',
            marginBottom: '50px',
        },
    },
    filterDisplay: {
        display: 'flex',
        flexDirection: 'Column',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row'
        },
    },
    filterBar: {
        position: 'inherit',
        [theme.breakpoints.up('sm')]: {
            position: 'sticky',
            top: '70px',
            zIndex: 999
        },
    }
}));


//for some reason use state wasn't working for this idk
let concatResults = [];
const setResults = (val) => concatResults = val;

//Chunk results each time they're updated, through pagination or page nav
function MakeChunks() {
    let resultChunks = [];
    var i, j, temparray, chunk = 12 + 8;
    for (i = 0, j = concatResults.length; i < j; i += chunk) {
        temparray = concatResults.slice(i, i + chunk);
        if (i === 0) temparray = concatResults.slice(i, i + chunk);
        resultChunks.push(temparray);
    }

    return resultChunks;
}

let page = 0;
const SearchCatagories = ['All', ...Catagories,];
const SearchLicences = ['All', ...Licences];

const Search = (props) => {
    const [popupIsOpen, setPopupOpen] = React.useState(false);
    const [salesIsOpen, setSalesOpen] = React.useState(false);
    //const [surveyComplete, setSurveyComplete] = React.useState(false);
    //const [rating, setRating] = React.useState(3);

    //const [concatResults, setResults] = React.useState([]); //all raw results concated with pagination
    const [chunks, setChunks] = React.useState(props.firstChunk); //the chunked output
    const classes = useStyles();
    const scrollbar = React.useContext(ScrollbarContext);

    const [cat, setCat] = React.useState(props.filters.cat ?? -1);
    const [licence, setLicence] = React.useState(props.filters.lic ?? -1);
    const [sort, setSort] = React.useState(props.filters.sort ?? 0);
    const [animated, setAnimated] = React.useState(props.filters.anim === 1);

    const [filtersOpen, setFiltersOpen] = React.useState(props.exploreMode);
    const [paginVis, setPaginVis] = React.useState(true); //pagination spinner visible

    //Updated on page nav or filter change
    React.useEffect(() => {
        scrollbar.parentScrollbar.scrollToTop();
        page = 0;
        setResults(props.result);
        setChunks(MakeChunks());
        setPaginVis(true);

    }, [props.query, props.result]);

    const [adKey, setadKey] = React.useState(789);
    React.useEffect(() => {
        setInterval(() => {
            if (adCount < 5) {
                setadKey(Math.random() * 10000);
                adCount++;
            }
        }, 1000 * 35);
    }, []);

    React.useEffect(() => adCount = 0, [props.query])

    //Get next page for both explore + search
    function getNextPage() {
        //Don't paginate if there's less than a page
        if (props.result.length <= 31) {
            setPaginVis(false);
            return;
        }

        //get next page
        const q = qs.parse(props.exploreMode ? props.queryString : window.location.search.substring(1));
        q["Page"] = page + 1;
        page += 1;
        const api = props.exploreMode ? '/api/explore' : '/api/search/' + props.query;
        // console.log(`${api}?${qs.stringify(q)}`);
        axios.get(`${api}?${qs.stringify(q)}`).then((res) => {
            if (res.data.results.length > 0) {
                const n = concatResults.concat(res.data.results);
                setResults(n);
                setChunks(MakeChunks());
            }
            else {
                setPaginVis(false); //done paginating
            }
        })
    }

    //observer for pagination
    const paginationLoader = React.useRef();
    React.useEffect(() => {
        var observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                getNextPage();
            }
        },
            {
                root: null,
                rootMargin: "0px",
                threshold: 0
            }
        );
        if (paginationLoader.current) {
            observer.observe(paginationLoader.current);
        }

        return () => {
            if (paginationLoader.current) {
                observer.unobserve(paginationLoader.current);
            }

        }
    }, [props.query, props.result]);

    function UpdateAnimated(event) {
        const anim = event.target.checked ? 1 : -1;
        setAnimated(anim === 1);
        updateURL("anim", anim);
    }

    function UpdateCatagory(cat) {
        setCat(cat);
        updateURL("cat", cat);
    }

    function UpdateLicence(lic) {
        setLicence(lic);
        updateURL("lic", lic);
    }

    function UpdateSort(sort) {
        setSort(sort);
        updateURL("sort", sort);
    }

    function updateURL(param, value) {
        if ('URLSearchParams' in window) {
            var searchParams = new URLSearchParams(window.location.search)

            //if search mode or explore mode and not cat (so cat isn't set in explore mode since it's set in the URL path instead)
            if (!props.exploreMode || props.exploreMode && param !== 'cat')
                searchParams.set(param, value);

            if (value === -1 || param === 'sort' && value == 0) searchParams.delete(param);

            var newRelativePathQuery;
            if (!props.exploreMode) {
                newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
            }
            else { //set cat in url explore mode
                const catindex = param === "cat" ? value : cat;
                if (catindex != -1)
                    newRelativePathQuery = `/explore/${URLCatagories[catindex]}?${searchParams.toString()}`;
                else
                    newRelativePathQuery = `/explore?${searchParams.toString()}`;

                //console.log(newRelativePathQuery);
            }

            props.history.push(newRelativePathQuery);
        }
    }

    return (
        <React.Fragment>
            <CssBaseline />
            <Helmet>
                {
                    props.exploreMode ?
                        <title>{`Explore - Poly Pizza`}</title> :
                        <title>{`${capitalizeFirstLetter(props.query)} - Download free 3D models | Poly Pizza`}</title>

                }

                <meta name="description" content={`Find and download free ${props.query} 3D models. No login required.`} />
            </Helmet>
            <PrimarySearchAppBar loggedIn={props.isLoggedIn} history={props.history} query={!props.exploreMode ? props.query : ''} isLoading={props.isLoading} />

            {/* Filters */}
            <Collapse in={filtersOpen} className={classes.filterBar}>
                <Container maxWidth="xl" style={{ paddingTop: '1vh' }}>
                    <Paper className={classes.paperPad}>
                        <div className={classes.filterDisplay}>
                            <FormControl className={`${classes.formControl}`}>
                                <InputLabel>Category</InputLabel>
                                <Select
                                    placeholder="Category"
                                    name="Category"
                                    id="catInput"
                                    label="Category"
                                    value={cat}
                                    onChange={(e) => UpdateCatagory(e.target.value)}
                                >
                                    {SearchCatagories.map((item, index) => {
                                        return <MenuItem key={index} value={index - 1}>{item}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>

                            <FormControl className={`${classes.formControl}`}>
                                <InputLabel>Licence</InputLabel>
                                <Select
                                    placeholder="Licence"
                                    name="Licence"
                                    label="Licence"
                                    value={licence}
                                    onChange={(e) => UpdateLicence(e.target.value)}
                                >
                                    {SearchLicences.map((item, index) => {
                                        return <MenuItem key={index} value={index - 1}>{item}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>

                            <FormControlLabel control={<Checkbox name="animated" color="primary" checked={animated} onClick={UpdateAnimated} />} label="Animated" />

                            <FormControl className={`${classes.SortSelect}`} style={{ marginLeft: 'auto' }}>
                                <InputLabel>Sort By</InputLabel>
                                <Select
                                    placeholder="Sort"
                                    name="Sort"
                                    label="Sort"
                                    value={sort}
                                    onChange={(e) => UpdateSort(e.target.value)}
                                >
                                    <MenuItem value={0}>Top</MenuItem>
                                    <MenuItem value={1}>New</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </Paper>
                </Container>
            </Collapse>

            <Container maxWidth="xl" style={{ paddingTop: props.exploreMode ? '2vh' : '5vh' }}>

                <CarbonAds key={props.query + props.resultsLength + adKey} style={{ margin: '10px 0px', }} />

                {/* Result header (search only) */}
                {!props.exploreMode &&
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        {props.result.length > 0 && props.query !== '' &&
                            <Typography variant="h5" component="h1" className='pb-6 flex items-end'>
                                {props.resultsLength} {props.resultsLength == 1 ? 'result' : 'results'} for low poly {capitalizeFirstLetter(props.query)}
                            </Typography>
                        }
                        {props.query === '' && <Typography variant="h5" style={{ paddingBottom: '24px', display: 'flex', alignItems: 'flex-end' }}>Explore Models</Typography>}
                        <Button
                            variant="outlined"
                            color="primary"
                            //className={classes.button}
                            startIcon={<FilterListIcon />}
                            onClick={() => setFiltersOpen(!filtersOpen)}
                        >
                            Filters
                        </Button>
                    </div>
                }

                {/* Seo bullshit */}
                {
                    props.exploreMode &&
                    <Card className={classes.infoCard}>
                        <CardContent>
                            <Typography variant="h5" component="h1">Free low poly {Catagories[cat]} 3D models</Typography>
                            <Typography variant="body1" style={{ marginTop: '5px' }}>
                                Explore thousands of free, high quality {Catagories[cat]} assets
                                {cat > -1 && ` like ${SubCats[cat]} and more`}. Ready to use in any Unity, Unreal, Godot, Blender
                                or VR/AR project. Models are available in a variety of formats like OBJ, FBX and GLTF. Also check out our paid picks for cheap low poly models
                            </Typography>
                        </CardContent>
                    </Card>
                }


                {/* Top Unity Sponsored */}
                {props.assets.length > 0 && <React.Fragment>
                    <Grid container spacing={6}>
                        {props.assets.slice(0, 4).map((card) => (
                            <Grid item key={card.previewUrl} xs={12} sm={6} md={4} lg={3}>
                                <ModelCard
                                    id={card.id}
                                    title={card.title}
                                    price={card.price}
                                    alt={card.alt}
                                    creator={card.creator}
                                    initials={card.initials}
                                    previewUrl={card.previewUrl}
                                    url={card.url}
                                    affiliate={card}
                                />
                            </Grid>
                        ))}
                    </Grid>

                    <div className='flex justify-between mt-[10px] mb-[-15px]'>
                        <Typography variant="body2" color="textSecondary">Paid picks
                            <IconButton size='small' onClick={() => setPopupOpen(true)}> <HelpIcon style={{ color: '#b9b9b9', marginLeft: '5px', fontSize: '0.9em' }} /> </IconButton>
                        </Typography>
                        {props.onSale.length > 0 &&
                            <MUILink
                                onClick={() => setSalesOpen(true)}
                                className='cursor-pointer !text-white-light !bg-red-main rounded-md p-1 px-3'>
                                {props.onSale.length} On Sale
                            </MUILink>}
                    </div>
                    <Divider id="divTop" style={{ margin: '24px 0 24px 0' }} />
                </React.Fragment>}


                {chunks.map((chunk, index) => {
                    const thisSlice = props.assets.slice((index * 4) + 4, (index * 4) + 8);
                    return <React.Fragment key={chunk.slice(0, Math.min(chunk.length, 5)).map((c) => c.publicID).join('')}>
                        <Grid container spacing={6}>
                            {chunk.map((card, i) =>
                                <Grid item key={card.previewUrl} xs={12} sm={6} md={4} lg={3}>
                                    <ModelCard
                                        id={i}
                                        title={card.title}
                                        alt={card.alt}
                                        creator={card.creator}
                                        initials={card.initials}
                                        previewUrl={card.previewUrl}
                                        url={card.url}
                                        onClick={() => {
                                            if (!props.exploreMode) {
                                                axios.post('/api/searchClick', { search: props.query, model: card.publicID });
                                            }
                                        }}
                                    />
                                </Grid>
                            )}
                        </Grid>
                        {/* Unity asset ad break */}
                        {thisSlice.length > 0 && <Divider id="divTop" style={{ margin: '24px 0 24px 0' }} />}
                        <Grid container spacing={6}>
                            {thisSlice.map((card) => (
                                <Grid item key={card.previewUrl} xs={12} sm={6} md={4} lg={3}>
                                    <ModelCard
                                        id={card.id}
                                        title={card.title}
                                        price={card.price}
                                        alt={card.alt}
                                        creator={card.creator}
                                        initials={card.initials}
                                        previewUrl={card.previewUrl}
                                        url={card.url}
                                        affiliate={card}
                                    />
                                </Grid>
                            ))}
                        </Grid>

                        {thisSlice.length > 0 &&
                            <Typography variant="body2" color="textSecondary" style={{ marginTop: '10px', marginBottom: '-15px' }}>Paid picks</Typography>}

                        {thisSlice.length > 0 && index < chunks.length - 1 ?
                            <Divider id="divTop" style={{ margin: '24px 0 24px 0' }} /> : <div style={{ margin: '24px 0 24px 0' }}></div>}

                        {thisSlice.length === 0 && <PuppyBlock seed={index + props.query} />}
                    </React.Fragment>
                }
                )}

                {/*Spinner for pagination + end padding*/}
                <div style={{ margin: '60px', justifyContent: 'center', display: paginVis ? 'flex' : 'none' }}>
                    {props.result.length > 0 && <CircularProgress ref={paginationLoader} />}
                </div>

                { /*Show a lil shrug guy when the user has no models */
                    props.result.length == 0 &&
                    (<Grid item xs={12} style={{ textAlign: 'center' }}>
                        <Typography variant="h1" className='text-7xl whitespace-nowrap mb-8'>¯\_(ツ)_/¯</Typography>
                        <Typography variant="h4">No results</Typography>
                    </Grid>)
                }

                {/* horny easter egg */}
                {
                    Horny.includes(props.query) && <div className='w-full flex justify-center mt-5 h-32'>
                        <img src="/img/horny.png" />
                    </div>
                }


            </Container>
            <AffiliateInfoPopup
                handleDialogClose={() => setPopupOpen(false)}
                open={popupIsOpen}
            />
            <SalesPopup
                setOpen={() => setSalesOpen(false)}
                open={salesIsOpen}
            />
        </React.Fragment >
    );
}

Search.getInitialProps = async ({ req, res, match, history, location, ...ctx }) => {
    //don't do duplicate links
    let url = req !== undefined ? req.originalUrl : window.location.href;
    if (url.endsWith('/')) return { redirectTo: url.substring(0, url.length - 1) }

    //Redirect empty search to explore
    if (match.path === '/search' && match.isExact) return { redirectTo: '/explore' }

    const darkMode = ctx.darkMode;
    const isLoggedIn = req !== undefined ? ctx.authed : authed;  //if SSR, use authed from context, otherwise use local var;

    let queryString = url.split('?')[1] || '';
    let filters = qs.parse(queryString) ?? {};

    const exploreMode = match.path.includes('explore'); //for the explore route
    //explore mode uses a full path name instead of QS for searching catagories
    //empty enter into search just redirects to explore route

    let searchQuery = null;
    try {
        searchQuery = GetURL(req, location);
        searchQuery = searchQuery.replace(/\\/g, '');
    } catch (e) { }

    //This was just null for some reason when server rendered?? Only happens on live server???
    let onSale;
    try {
        const saleres = await axios.get(`${process.env.SITE_ROOT}/api/assets/sale`).catch((e) => console.error(e.message));
        onSale = saleres.data;
    } catch (e) {
        onSale = [];
    }

    //Normal search
    if (!exploreMode) {
        //Redirect to explore on low poly exact match
        const match = searchQuery.match(/low.*?poly/i);
        if (match && searchQuery === match[0]) return { redirectTo: `/explore` };

        //serach query string  
        const URL = `${process.env.SITE_ROOT}/api/search/${searchQuery}?${queryString}`;
        const response = await axios.get(trimQS(URL)).catch((e) => console.error(e.message));
        setResults(response.data.results); //Set initial results
        const firstChunk = MakeChunks();

        return {
            isLoggedIn, result: response.data.results, assets: response.data.unity, resultCount: response.data.count,
            query: decodeURIComponent(searchQuery), darkMode, filters, exploreMode, resultsLength: response.data.count, firstChunk, onSale
        }
    }
    else { //Explore mode, no search term

        if (searchQuery) {
            const catIndex = URLCatagories.findIndex((s) => s === searchQuery);
            filters.cat = catIndex; //add to filters
            const existing = qs.parse(queryString); //translate to API query
            queryString = qs.stringify({ ...existing, cat: catIndex });
            // console.log(queryString);
        }

        const URL = `${process.env.SITE_ROOT}/api/explore?${queryString}`;
        const response = await axios.get(trimQS(URL)).catch((e) => console.error(e.message));
        const FullCatString = Catagories[filters.cat] ?? '';

        setResults(response.data.results); //Set initial results
        const firstChunk = MakeChunks();

        return {
            result: response.data.results, assets: [], query: FullCatString, isLoggedIn, darkMode, filters, exploreMode,
            resultCount: response.data.results.count, queryString, firstChunk, onSale
        }
    }
}

//yeet ? from end of URL so CF caches properly
function trimQS(url) {
    return url.charAt(url.length - 1) === '?' ? url.slice(0, -1) : url;
}

export default Search;