import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Badge, Grid, IconButton, Typography } from '@material-ui/core';
import axios from 'axios';
import QuickCreateList from './QuickCreateList';
import ModelCard from '../components/ModelCard';
import { Scrollbar } from 'react-scrollbars-custom';

export default function SalesPopup(props: propTypes) {

    const [loaded, setloaded] = useState(false);
    const [assets, setAssets] = useState([]);

    const handleClose = () => {
        props.setOpen(false);
    };

    function refreshLists() {
        axios.get('/api/assets/sale').then((d) => {
            setloaded(true);
            setAssets(d.data);
        });
    }

    useEffect(() => {
        if (!props.open || loaded) return; //call api on opened
        refreshLists();
    }, [props.open]);

    const AssetView = () => (
        <Grid container spacing={4} style={{ marginBottom: '20px' }}>
            {assets.map((card, i) => {
                return <Grid item key={card.previewUrl} xs={12} sm={6} md={4} lg={4}>
                    <ModelCard
                        id={card.id}
                        title={card.title}
                        price={card.price}
                        alt={card.alt}
                        creator={card.creator}
                        initials={card.initials}
                        previewUrl={card.previewUrl}
                        url={card.url}
                        affiliate={card}
                    />
                </Grid>
            })}

            { /*Show a lil shrug guy when the user has no models */
                assets.length == 0 &&
                (<Grid item xs={12} style={{ textAlign: 'center' }}>
                    <Typography variant="h1">¯\_(ツ)_/¯</Typography>
                    <Typography variant="h4">
                        Nothing's on sale at the moment! Sorry!
                    </Typography>
                </Grid>)
            }
        </Grid>
    );

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"lg"}
            open={props.open}
            onClose={handleClose}
        >
            <DialogTitle style={{ pointerEvents: 'none', userSelect: 'none' }}>Currently On Sale</DialogTitle>
            <DialogContent>
                {!loaded ? <p>loading...</p> : <AssetView />}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

interface propTypes {
    setOpen: (arg0: boolean) => void;
    open: boolean;
}