import React, { useEffect, useState } from 'react';
import { Button, Grid, Typography, Container, CssBaseline, InputBase, Chip } from '@material-ui/core';
import { alpha, makeStyles } from '@material-ui/core/styles';
import MUILink from '@material-ui/core/Link';
import ModelCard from '../components/ModelCard2'
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import PrimarySearchAppBar from '../components/Appbar'
import Helmet from 'react-helmet';
import DonateBar from '../components/DonateBar';
import CallToAction from '../components/CallToHelp';
import FavoriteIcon from '@material-ui/icons/Favorite';
import Footer from '../components/Footer';
import CarbonAds from '../components/carbonAds';
import { ScrollbarContext } from 'react-scrollbars-custom';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Paper from '@material-ui/core/Paper';
import SRandom from 'mersenne-twister';
import ListCard from '../components/ListCard';
import BlogCard from '../components/BlogCard';
import { suggestedSearches } from '../util/HardCodedLists';
import GetModelSource from '../util/GetModelSrc';
import Countdown from 'react-countdown';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

//Seed by using date
let srand = new SRandom(parseInt(new Date().toISOString().split('T')[0].replaceAll('-', '')));

function getRandomInt(max) {
  return Math.floor(srand.random() * max);
}

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(srand.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}

const sponsors = [
  {
    url: "https://zerotomastery.io/?utm_source=polypizza&utm_medium=homepage",
    img: "ZTM-Logo.svg",
  },
  {
    url: 'https://retrostylegames.com/',
    img: 'RetroStyleGames.png',
    alt: '3d game art outsourcing'
  },
  {
    url: 'https://threejs-journey.com/',
    img: 'threeJourney.svg',
  },
  {
    url: 'https://linkin.bio/mythologi-es',
    img: 'Elysium2.svg',
  },
  {
    url: 'https://ugamestudio.com',
    img: 'ugame.png',
  },
  {
    url: 'https://pixcap.com',
    img: 'pixcap.png',
  },
]

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  // heroContent: {
  //   background: `linear-gradient(42deg, ${theme.palette.gradient.one} -30%, ${theme.palette.gradient.two} 90%)`,
  //   backgroundBlendMode: 'unset',
  //   padding: '25px',

  //   [theme.breakpoints.up('md')]: {
  //     padding: '0px',
  //     backgroundColor: '#fff',
  //     background: `linear-gradient(42deg, ${theme.palette.gradient.one} -30%, ${theme.palette.gradient.two} 90%)`, //http://api.thumbr.it/whitenoise-361x370.png?background=ffffffff&noise=626262&density=2&opacity=30
  //     backgroundBlendMode: 'screen, difference',
  //   },
  // },
  heroBG: {
    background: `linear-gradient(42deg, ${theme.palette.gradient.one} -30%, ${theme.palette.gradient.two} 90%) !important`,
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  // cardGrid: {
  //   paddingTop: theme.spacing(8),
  //   paddingBottom: theme.spacing(8),
  // },
  // cardGridMaxWidthLG: {
  //   maxWidth: '100%',
  //   [theme.breakpoints.up('lg')]: {
  //     maxWidth: '80%',
  //   },
  // },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  // attribution: {
  //   width: '100%',
  //   height: '100%',
  //   pointerEvents: 'none',
  //   display: 'flex',
  //   alignItems: 'center',
  //   flexDirection: 'column',
  //   justifyContent: 'flex-end',
  // },
  inputRoot: {
    color: 'inherit',
    height: '100%',
    width: '100%',
    fontSize: '1.5rem',
  },
  inputInput: {
    //padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    transition: theme.transitions.create('width'),
    marginLeft: '20px',
    color: '#000',
  },
  search: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.9),
    height: '60px',
    marginTop: '30px'
    //marginRight: theme.spacing(2),
    //marginLeft: 0,  
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    pointerEvents: 'none',
  },
  // jumbotron: {
  //   [theme.breakpoints.up('md')]: {
  //     height: '50vh'
  //   },
  // }
}));

const Home = (props) => {
  //Set featured as empty array of 12
  const [Featured, setFeatured] = useState(new Array(12).fill(null));
  const [bundles, setBundles] = useState(new Array(4).fill(null));
  const [newest, setNewest] = useState([]);
  const [posts, setPosts] = useState([]);
  const [Hero, setHero] = useState();

  const [blogPopup, setblogPopup] = useState(false);

  useEffect(() => {
    axios.get('/api/featured').then(res => {
      setFeatured(res.data.featured);
      setNewest(res.data.newest);
      setBundles(res.data.bundles);
      setPosts(res.data.posts);
      setTimeout(() => { //something is sussy here
        setHero(res.data.hero);
      }, 100);
    })
  }, []);

  //Show the blog popup if posted in the last 72 hours
  useEffect(() => {
    if (posts.length > 0) {
      let lastPost = posts[0];
      let lastPostDate = new Date(lastPost.Posted);
      let now = new Date();
      let diff = now - lastPostDate;
      let diffHours = diff / 1000 / 60 / 60;
      if (diffHours < 72) {
        setblogPopup(true);
      }
    }
  }, [posts]);


  useEffect(() => { window.__SERVER_APP_STATE__.afterData.ssg = false; }, []); //allow nav when page is static

  function HandleSearchInput(e) {
    if (e.key === 'Enter') {
      let query = window.location.href.split('?')[1] ?? '';
      if (query.length > 0) query = '?' + query;

      e.target.value.length > 0 ?
        props.history.push('/search/' + encodeURIComponent(e.target.value) + query) :
        props.history.push('/explore');
    }
  }

  function SearchBar(props) {
    return (
      <div className="rounded-md bg-white-light bg-opacity-95 h-14 mt-3 2xl:mt-7">
        <InputBase
          placeholder="Search for anything"
          classes={{
            root: "h-full w-full !text-xl",
            input: props.classes.inputInput,
          }}
          inputProps={{ 'aria-label': 'search' }}
          onKeyPress={HandleSearchInput}
        />
      </div>
    )
  }

  function Popular(props) {
    return (
      <div className='my-4 flex flex-wrap gap-2'>
        {props.popularSearches?.map((search) => {
          return (
            <Link
              to={search.url}
              className="text-white-light rounded-full no-underline py-1 px-3 ring-1 ring-white-light capitalize hover:bg-white-light hover:text-black-main transition-all"
            >
              {search.name}
            </Link>
          )
        })}
      </div>
    );
  }

  function HeroModelViewer() {
    if (!Hero) return null;
    return (
      <model-viewer
        src={GetModelSource(Hero.ResourceID)}
        // environment-image="/HDR/BG.hdr"
        // autoplay
        auto-rotate
        disable-zoom
        camera-controls
        loading="eager"
        interaction-prompt="none"
        style={{ width: '100%', height: '100%', "--poster-color": 'transparent', flexGrow: 0 }}
        camera-orbit={Hero.Orbit ? `${Hero.Orbit.theta}rad ${Hero.Orbit.phi}rad ${Hero.Orbit.radius}m` : '0deg 75deg 105%'}
      >
        <div className={'w-full  h-full pointer-events-none flex flex-col items-center justify-end'}>
          {Hero.Title && <MUILink variant="body2" className='!text-white-light pointer-events-auto pb-1 2xl:pb-4' to={`/m/${Hero.PublicID}`} component={Link}>{Hero.Title} by {Hero["Creator.Username"]}</MUILink>}
        </div>
      </model-viewer>
    )
  }

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  //so other pages like logout etc can redirect to home with a message 
  if (props.location.state && props.location.state.message) {
    enqueueSnackbar(props.location.state.message, {
      variant: 'info',
    });
    props.location.state.message = false;
  }

  //Update stats after page load since it's at the bottom
  const [stats, setstats] = React.useState(null);
  const [adKey, setadKey] = React.useState(123);
  const scrollbar = React.useContext(ScrollbarContext);
  const [loggedIn, setloggedIn] = useState(props.loggedIn);
  React.useEffect(() => {
    axios.get(`/api/support/getstats`).then((d) => setstats(d.data));
    scrollbar.parentScrollbar.scrollToTop();

    setTimeout(() => {
      setadKey(Math.random() * 10000);
    }, 1000 * 50);

    //Get login status if response doesnt have an error
    axios.get('/api/me/authed').then((d) => {
      if (d.data == true) //Returns true if logged in
      {
        setloggedIn(true);
        authed = true;
        window.__SERVER_APP_STATE__.initialData.isLoggedIn = true;
      }
      else {
        setloggedIn(false);
      }
    }).catch((e) => {
      console.error(e);
    });
  }, []);

  //Countdown date
  const compEndDate = new Date(Date.UTC(2024, 2, 3, 6, 0, 0));

  const [compValid, setcompValid] = useState(false);
  useEffect(() => {
    let now = new Date();
    if (now < compEndDate) {
      setcompValid(true);
    }
  }, []);

  const countDownnames = ["days", "hours", "minutes", "seconds"];
  const CountdownRenderer = ({ days, hours, minutes, seconds, completed }) => {
    return (
      <div class="flex gap-3">
        {
          [days, hours, minutes, seconds].map((time, i) => {
            return (
              <div className='flex flex-col py-2'>
                <span className='flex items-center justify-center p-3 w-12 h-12 rounded-lg font-mono !text-white-light text-xl bg-gray-main bg-opacity-40'>{time}</span>
                <p className='!text-white-light` !mt-2 !mb-0'>{countDownnames[i]}</p>
              </div>
            );
          })
        }
      </div>
    )
  };

  const classes = useStyles();
  return (
    <React.Fragment>
      <CssBaseline />
      <Helmet>
        <title>{`Poly Pizza: Free 3D models for everyone`}</title>
        <meta name="description" content={`Download thousands of low poly 3D models for free. No login required. Ready for AR/VR, Unity, Unreal or Godot.`} />

        {/* FB */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://poly.pizza/" />
        <meta property="og:title" content="Poly Pizza: Free 3D models for everyone" />
        <meta property="og:image" content="https://poly.pizza/img/socialPreview.jpg" />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:creator" content="duckpizza_" />
        <meta property="twitter:url" content="https://poly.pizza/" />
        <meta property="twitter:title" content="Poly Pizza: Free 3D models for everyone" />
        <meta property="twitter:description" content="Download thousands of low poly 3D models for free. No login required. Ready for AR/VR, Unity, Unreal or Godot." />
        <meta property="twitter:image:alt" content="A grassy field full of low poly 3d objects on pedestals" />
        <meta property="twitter:image" content="https://poly.pizza/img/socialPreview.jpg" />

        {/* Preload the /featured endpoint */}
        <link rel="preload" href="/api/featured" as="fetch" crossOrigin="anonymous" />

      </Helmet>
      <PrimarySearchAppBar loggedIn={loggedIn} key={loggedIn} history={props.history} isLoading={props.isLoading} />
      <main>
        {/* Hero unit */}
        <div className={`${classes.heroBG} h-[75vh] md:h-[60vh] 2xl:h-[50vh]`}>
          <Container maxWidth="lg" className='!h-full'>
            {/* Grid */}
            <div className='flex h-full justify-center relative'>
              {/* search + shit */}
              <div className='h-full flex flex-col justify-center flex-grow'>
                <Typography variant="h2" component={"h1"} className='!text-white !mb-3 2xl:!mb-[0.35em] !text-5xl 2xl:!text-[3.75rem]'>
                  Every low poly model you could ever need
                </Typography>
                <Typography variant="h5" className='!text-white !text-xl 2xl:!text-2xl' paragraph>
                  Build something beautiful with {props.count.toLocaleString()}+ free models
                </Typography>

                <SearchBar classes={classes} />
                <Popular popularSearches={props.popularSearches} />
                <MUILink variant="body1" color="textPrimary" className='!mt-0' component={Link} to="/explore">
                  or just explore <ArrowForwardIosIcon className='!text-[0.7em]' />
                </MUILink>

                {/* PGPT link floating on the bottom */}
                <div className='absolute bottom-0 pb-2 w-full hidden md:flex items-end gap-2 '>
                  <a href="https://pgpt.dev" className='bg-gray-dark w-fit !no-underline flex flex-col gap-2 items-center p-4 px-10 pt-0 rounded hover:scale-[1.025] hover:translate-y-[-2px] transition-all' target='_blank'>
                    <p className='text-xs bg-blue-400 w-fit p-1 px-3 rounded-xl rounded-t-none text-center text-white-light !my-0'>From the creator of poly pizza</p>
                    {/* <Chip className='hover:cursor-pointer !bg-red-main' style={{ 'animation': 'hover 5s infinite' }} label={"New"} /> */}
                    <div className='flex items-center justify-center gap-3'>
                      {/* <img src="/img/pgpt.jpg" className='w-20' /> */}
                      {/* <ExploreIcon className='!text-4xl'/> */}
                      <div class="flex flex-col gap-[2px] no-underline text-white-light">
                        <p className='m-0'>Pretty good playtesting</p>
                        <p className='m-0 text-gray-text-sec'>Playtesting for indies made easy</p>
                      </div>
                      <OpenInNewIcon className='!text-2xl !text-white' color='action' />
                    </div>
                  </a>
                </div>
              </div>

              {/* Model */}
              <div className='h-full w-[50%] 2xl:w-[60%] hidden lg:block'>
                <HeroModelViewer />
              </div>
            </div>

          </Container>
        </div>
        <a href="#posts" className='!no-underline !text-white-light' >
          {compValid && <div className='w-full text-center h-18 flex flex-co gap-6 items-center mt-6 justify-center bg-blue-400'>
            <h2 className='!text-white-light !font-light text-2xl'>Win $300 in the Poly Pizza Render Challenge</h2>
            <Countdown date={compEndDate} renderer={CountdownRenderer} />
          </div>}
        </a>
        {/* End hero unit */}
        <Container classes={{ root: classes.cardGrid, maxWidthLg: classes.cardGridMaxWidthLG }} className="relative !pt-7 !2xl:pt-9 !max-w-full 2xl:!max-w-[80%]" maxWidth="lg">
          {/* {blogPopup && <a href="#posts"><Chip className='absolute top-[-30px] animate-bounce hover:cursor-pointer !bg-[#191622]' label={'✨New Blog Post'} /></a>} */}
          <div className='flex items-baseline mb-6 gap-3'>
            <Typography variant="h1" component="h2" className='!text-4xl !mt-2'>Free asset packs</Typography>
            <MUILink variant="body1" color="textPrimary" className='!mt-0' component={Link} to="/bundles">View all <ArrowForwardIosIcon style={{ fontSize: '0.7em' }} /></MUILink>
          </div>
          <Grid container spacing={4} id="">
            {bundles.map((list, i) => (
              <Grid item key={list ? list.PublicID : i} xs={12} sm={6} md={4} lg={3}>
                <ListCard
                  index={i}
                  list={list}
                  noAvatar
                />
              </Grid>
            ))}
          </Grid>

          <CarbonAds key={adKey} style={{ marginBottom: '20px' }} />
          <Typography variant="h1" component={"h2"} className='!my-6 !text-4xl !mt-16'>Free 3D models</Typography>
          <Grid container spacing={4} id="featuredModels">
            {Featured.map((card, i) => (
              <Grid item key={card ? card.previewUrl : i} xs={12} sm={6} md={4} lg={3}>
                <ModelCard
                  index={i}
                  model={card}
                  noAvatar
                />
              </Grid>
            ))}
          </Grid>

          <div className='flex items-baseline mb-6 mt-16 gap-3'>
            <Typography variant="h1" component="h2" className='!text-4xl !m-0'>Latest models</Typography>
            <MUILink variant="body1" color="textPrimary" className='!mt-0' component={Link} to="/explore?sort=1">View all<ArrowForwardIosIcon style={{ fontSize: '0.7em' }} /></MUILink>
          </div>
          <Grid container spacing={4} id="newestModels">
            {newest?.map((card, i) => (
              <Grid item key={card ? card.previewUrl : i} xs={12} sm={6} md={4} lg={3}>
                <ModelCard
                  index={i}
                  model={card}
                  noAvatar
                />
              </Grid>
            ))}
          </Grid>

          {/* Blog posts */}
          {posts?.length > 0 && <div className='flex items-center mb-6 mt-16 gap-2'>
            <Typography variant="h1" component="h2" className='!text-4xl !m-0' id="posts">Blog posts</Typography>
          </div>}
          <div className='grid gap-6 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 my-5'>
            {posts?.map((post, i) => (
              <BlogCard post={post} />
            ))}
          </div>
        </Container>
      </main >

      {
        stats && (
          <Container maxWidth="lg" className='mb-4'>
            <CallToAction noArrow />
            <h2 className="font-normal text-center">Poly Pizza wouldn't be possible without all of our amazing supporters: </h2>
            <Paper className="flex gap-3 flex-wrap p-9 lg:mx-16 mb-14" id="thankYouWall">
              {
                stats.donations.map((d) => {
                  let classes = `my-0 ${d.type == 'Big' && 'font-semibold'}`;

                  if (!d.hasAccount) return <p key={d.name} style={{ color: d.type == 'Big' ? '#fff' : '#fff' }} className={classes}>{d.name}</p>
                  return <MUILink component={Link} style={{ color: d.type == 'Big' ? '#fff' : '#fff' }} to={`/u/${d.name}`} key={d.name} className={classes}>{d.name}</MUILink>
                })
              }
            </Paper>

            <DonateBar stats={stats} />
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
              <Button variant="outlined"
                color="primary"
                size="large"
                style={{ textTransform: 'none' }}
                endIcon={<FavoriteIcon />}
                to="/support"
                component={Link}
                align="center"
              >Become a supporter</Button>
            </div>
            <Typography className='text-center !mt-4'></Typography>

            <div className='mt-32'>
              <Typography variant="h5" className='w-full text-center !font-light'>Also Supported by: </Typography>
              <div className='grid grid-cols-2 md:grid-cols-3 gap-8 p-8'>
                {
                  sponsors.map((sponsor, index) => (
                    <a key={index} href={sponsor.url} target="_blank" rel="noopener noreferrer">
                      <img
                        className='w-full max-h-36 h-full object-contain'
                        src={`/img/${sponsor.img}`}
                        alt={sponsor.alt ?? `Logo for ${sponsor.url}`}
                      />
                    </a>
                  ))
                }
              </div>
            </div>


          </Container>
        )
      }
      <Footer />
    </React.Fragment >
  );
}

// This is statically generated!
Home.getInitialProps = async ({ req, res, match, history, location, ...ctx }) => {
  const response = (await axios.get(`${process.env.SITE_ROOT}/api/featured`)).data;

  //@ts-ignore
  const isLoggedIn = req !== undefined ? ctx.authed : authed;  //if SSR, use authed from context, otherwise use local var;
  let darkMode = ctx.darkMode;

  //Searches that always show up
  const constSearches = [
    { name: "Character", url: "/explore/People-and-Characters" },
    { name: "Gun", url: "/search/Gun" },
    { name: "Car", url: "/search/Car" },
    { name: "Tree", url: "/search/Tree" },
    { name: "Building", url: "/explore/Buildings" },
  ];

  //Shuffle popular searches and limit
  let popular = suggestedSearches;
  shuffleArray(popular);
  popular = popular.slice(0, 8 - constSearches.length);

  //Shuffle const searches

  //Objectify the popular searches
  popular = popular.map((s) => {
    return { name: s, url: `/search/${s}` }
  });

  return {
    //featured: response.featured,
    //hero: response.hero,
    popularSearches: [...constSearches, ...popular],
    count: response.count,
    darkMode, isLoggedIn
  };
}

export default Home;