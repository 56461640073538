//Integrations dialog box for detail view

import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, FormControl, InputLabel, Select, MenuItem, FormHelperText, Typography } from '@material-ui/core';
import { ModelDetail } from '../types/modelcard';
import axios from 'axios';

enum Integration {
    Unity,
    OpenBrush,
}

const Integrations = [
    'Unity (coming soon!)',
    'Open Brush',
]

export default function SendToDialog(props: { open: boolean; setOpen: (arg0: boolean) => void; model: ModelDetail }) {

    function HandleIntegration(app: Integration) {
        switch (app) {
            case Integration.OpenBrush:
                axios.get(`http://localhost:40074/api/v1?import.webmodel=https://static.poly.pizza/${props.model.ResourceID}.glb`);
        }
    }

    return (
        <Dialog
            open={props.open}
            onClose={() => props.setOpen(false)}
            maxWidth="xs"
        >
            <DialogContent className="flex flex-col gap-5 p-8 !py-8">
                <Typography className='w-full text-center' variant='h5' >Send To App</Typography>
                <Typography className='w-full text-center !mb-3' variant='body2' color="textSecondary" >Open this model in your favourite app!</Typography>
                {
                    Integrations.map((app, i) => (
                        <Button variant="outlined" className='!px-10 !py-3' disabled={i == 0} onClick={() => HandleIntegration(i)}>{app}</Button>
                    ))
                }
            </DialogContent>

        </Dialog>
    )
}
