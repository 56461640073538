import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Link } from "react-router-dom";
import MUILink from '@material-ui/core/Link';

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: '30px',
        borderRadius: 99,
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
        borderRadius: 99,
        //backgroundColor: '#1a90ff',
        background: `linear-gradient(42deg, ${theme.palette.gradient.one} -30%, ${theme.palette.gradient.two} 90%)`,
    },
}))(LinearProgress);

export default function DonateBar(props) {
    const percent = props.stats.raised / props.stats.goal.amount;
    const roundedPercentage = percent < 1 ? Math.round(percent * 100) : 100;
    const topSupporter = props.stats.top.name.startsWith('u/') ?
        <MUILink underline='always' color="textPrimary" href={`/${props.stats.top.name}`} target="_blank">{props.stats.top.name}</MUILink> :
        props.stats.top.name;

        const goalText = percent > 1 ?
         `Goal complete! ${props.stats.goal.feature} coming soon...` :
          `$${Math.abs((percent - 1) * props.stats.goal.amount).toFixed(2)} to reach goal`;

    return (
        <Grid container spacing={2} style={{ width: '100%', justifyContent: 'center' }} direction="row" alignItems="center">
            <Grid container direction="row" alignItems="flex-end" item xs={12} style={{ justifyContent: "space-between" }}>
                <Grid item>
                    <Typography variant="body1">Monthly Donations:</Typography>
                    <Typography variant="h5">${props.stats.raised}</Typography>
                </Grid>
                <Grid item >
                    <Typography style={{ textAlign: "right" }} variant="body1">Goal: {props.stats.goal.feature}</Typography>
                    <Typography style={{ textAlign: "right" }} variant="h5">${props.stats.goal.amount}</Typography>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <BorderLinearProgress variant="determinate" value={roundedPercentage} />
            </Grid>
            <Grid item>
                <p style={{ textAlign: 'center' }}> {goalText} • {props.stats.subCount} monthly subs • {props.stats.donorCount} donors • Top Sub: {topSupporter} - ${props.stats.top.amount}</p>
            </Grid>
        </Grid>
    )

}