import React, { useState } from 'react';
import axios from 'axios';
import CssBaseline from '@material-ui/core/CssBaseline';
import PrimarySearchAppBar from '../components/Appbar'
import Helmet from 'react-helmet';
import EditAd from '../components/editAd';

import { Button, Container, makeStyles, Paper, Theme } from '@material-ui/core';
import SimpleConfirm from '../components/SimpleConfirm';
import DropZone from '../components/Dropzone';
import { AdStats } from '../types/adStats';
import {has} from '../util/BootDash';

const defaultAd = {
    Text: [''],
    Catagory: [],
    Slug: '',
    Link: '',
    BG: '',
}

const useStyles = makeStyles((theme: Theme) => ({
    form: {
        width: "100%",
        border: `5px dotted ${theme.palette.primary.main}`,
    },
}));

let action = null;

const adMan = (props: propTypes) => {

    const classes = useStyles();

    const [ads, setads] = useState(props.ads);
    const [confirm, setconfirm] = useState(false);

    function deleteAd(slug: string) {
        axios.delete(`/api/dook_only/ads/${slug}`);
    }

    function confirmDelete(slug: string) {
        action = () => deleteAd(slug);
        setconfirm(true);
    }

    async function deleteBG(filename){
        await axios.delete('/api/puppies/BG/' + filename.split('/')[1]);
        window.location.reload();
    }

    const handleFilePick = async (file: File[]) => {
        console.log(file);

        for (let i = 0; i < file.length; i++) {
            const f = file[i];
            let fd = new FormData();
            fd.append("model", f, f.name);
            await axios.post('/api/puppies/BG/upload', fd, {
                headers: { "Content-Type": "multipart/form-data" },
            });
        }

        window.location.reload();
    };


    return (
        <React.Fragment>
            <CssBaseline />

            <Helmet><title>{`yoinky my sploinky - Poly Pizza`}</title></Helmet>
            <PrimarySearchAppBar loggedIn={props.isLoggedIn} history={props.history} isLoading={props.isLoading} />

            <Container style={{ marginTop: '5vh' }} maxWidth="xl">
                {ads.map((a, i) => {
                    const statParent = props.stats[a.Slug];
                    return <EditAd ad={a} confirmDelete={confirmDelete} stats={has(statParent, 'Stats') ? statParent.Stats : null} />
                })}

                <Button onClick={() => setads([...ads, defaultAd])}>New slot</Button>
            </Container>
            <SimpleConfirm title='u sure about that 1 m9' content="aaaa" open={confirm} action={action} setOpen={setconfirm} />

            <Container >
                <Paper>
                    <form id="uploadForm" encType="multipart/form-data">
                        <div className={classes.form}>
                            <DropZone
                                zoneStyle={{ height: '20vh' }}
                                setFiles={handleFilePick}
                                maxFiles={10}
                                uploadText={'Upload BG images'}
                            />
                        </div>
                    </form>
                </Paper>

                <Paper>
                    <div style={{display: 'flex', flexWrap: 'wrap'}}>
                    {
                        props.BGs.map((bg) => {
                            const url = 'https://static.poly.pizza/' + bg;
                            return (<div style={{width: '300px', padding: '30px'}}>
                                <img src={url} style={{width: '100%'}}/>
                                <p style={{wordWrap: 'break-word'}}>{url}</p>
                                <button onClick={() => deleteBG(bg)}>delete</button>
                            </div>)
                        })
                    }
                    </div>
                </Paper>

            </Container>
        </React.Fragment>
    );
}

adMan.getInitialProps = async ({ req, res, match, history, location, ...ctx }) => {
    const isLoggedIn = req !== undefined ? ctx.authed : authed;  //if SSR, use authed from context, otherwise use local var;

    let request;
    let stats;
    const url = `${process.env.SITE_ROOT}/api/dook_only/ads/stats`;
    if (req !== undefined && req.headers.cookie) {
        request = axios.get(url, { headers: { cookie: req.headers.cookie } });
    }
    else {
        request = axios.get(url, { withCredentials: true });
    }
    
    try {
        stats = (await request).data;
    }
    catch (error) {
        console.log(error.response.status);
        return { isLoggedIn, statusCode: 404 };
    }

    const ads = (await axios.get(`${process.env.SITE_ROOT}/api/puppies/all?t=${(new Date()).getTime()}`)).data;
    const BGs = (await axios.get(`${process.env.SITE_ROOT}/api/puppies/BG/all`)).data;

    return { isLoggedIn, ads, darkMode: ctx.darkMode, BGs, stats }
}

interface propTypes {
    isLoggedIn: any;
    history: any;
    isLoading: any;
    ads: any;
    BGs: string[];
    stats: AdStats;
}

export default adMan;