import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import axios from "axios";
import DisplayAxiosError from "../util/DisplayAxiosError";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Typography, Link, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { Helmet } from "react-helmet";
import LinearProgress from '@material-ui/core/LinearProgress';
import DropZone from "./Dropzone";
import { Licences } from "../util/HardCodedLists";


const useStyles = makeStyles((theme: Theme) => ({
  form: {
    width: "100%",
    border: `5px dotted ${theme.palette.primary.main}`,
  },

  input: {
    margin: "0",
    padding: "100px",
    outline: "none",
    opacity: "0",
  },
  formControls: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px 0 20px 0',
  }
}));

export default function FormDialog(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [filename, setFileName] = React.useState(null);
  const { register, handleSubmit, errors } = useForm();
  const [uploading, setuploading] = React.useState(false);
  const [files, setFiles] = React.useState();
  const [licence, setlicence] = React.useState(0);

  const handleClose = () => {
    props.setOpen(false);
  };

  const handleFilePick = (file: File[]) => {
    console.log(file);

    setFiles(file[0]);
    setFileName(file[0].name);
  };

  const uploadText = (
    <p>
      Upload a zip file with one or more .obj or .fbx files in it <br />
    Click to choose a file or drag it here
    </p>
  )

  const submit = async (data) => {
    if (uploading) return; //prevent spamming
    setuploading(true);

    grecaptcha.ready(function () {
      grecaptcha
        .execute("6Lc_LAcaAAAAAJ8RT1-vgmA-GsTDH0KJBZ_toZZV", {
          action: "upload",
        })
        .then(async function (token) {
          let fd = new FormData();

          //loop fd
          console.log(data);
          fd.append("convert", data.convert);
          fd.append("token", token);
          fd.append("model", files, filename);
          fd.append("licence", licence.toString());

          //For uploading to other's accounts from admin
          const forceAccount = localStorage.getItem('forceUploadAccountID');
          if(forceAccount !== null) {
            fd.append("account", forceAccount);
            //localStorage.removeItem('forceUploadAccountID');
          }

          setTimeout(() => {
            enqueueSnackbar("This might take a sec...", { variant: 'info' })
          }, 4000);

          await axios
            .post(`${process.env.SITE_ROOT}/api/upload/zip`, fd, {
              headers: { "Content-Type": "multipart/form-data" },
            })
            .then(async function (response) {
              //console.log(response.data);
              window.location = response.data.redirect;
              setuploading(false);
            })
            .catch((error) => {
              DisplayAxiosError(error, enqueueSnackbar);
              setuploading(false);
            });
        });
    });
  };

  return (
    <React.Fragment>
      {/* <Helmet>
        <script src="https://www.google.com/recaptcha/api.js?render=6Lc_LAcaAAAAAJ8RT1-vgmA-GsTDH0KJBZ_toZZV"></script>
      </Helmet> */}
      <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        {uploading && <div style={{ width: '100%' }}><LinearProgress color="primary" /></div>}
        <DialogContent>

          <form id="uploadForm" encType="multipart/form-data">
            <div className={classes.form}>
              <DropZone
                zoneStyle={{ height: '20vh' }}
                setFiles={handleFilePick}
                accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
                maxFiles={1}
                uploadText={uploadText}
              />
            </div>
            <div className={classes.formControls}>
              <FormControlLabel
                name="convert"
                inputRef={register}
                control={<Switch color="primary" />}
                label="Import from blocks"
              />
              <FormControl style={{ minWidth: 120, }}>
                <InputLabel id="demo-simple-select-label">Licence</InputLabel>
                <Select
                  value={licence}
                  onChange={(e) => setlicence(e.target.value)}
                >
                  {Licences.map((item, index) => {
                    return <MenuItem key={index} value={index}>{item}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </div>
          </form>
          <Typography variant="body2">
            By uploading this model you acknowledge that:
          <ul>
              <li>You made 100% of what you're uploading,<br/> and your account will be banned if this isn't the case</li>
              <li>You've read the <Link href="/docs/uploading" target="_blank" color="primary">uploading guidelines</Link></li>
              <li>
                You agree to have this content published under a {" "}
                <Link
                  color="primary"
                  href={`https://creativecommons.org/${licence == 0 ? 'licenses/by/3.0/' : 'publicdomain/zero/1.0/'}`}
                  target="blank"
                  rel="noopener"
                > {Licences[licence]}
                </Link>{" "}
              licence
            </li>
              <li>
                You agree to respect other people's intellectual property
              </li>
              <li>
                Your model is subject to admin approval and won't be public until approved
              </li>
              <li>
                Your model may not be published if it doesn't meet our content or quality guidelines
              </li>
            </ul>
          </Typography>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="textPrimary">
            Cancel
        </Button>
          <Button onClick={handleSubmit(submit)} color="primary" variant="outlined">
            Upload
        </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
