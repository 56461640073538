export default function(one, two, type)
{
    if (two) {
        if (type === 'Linear') {
            return `linear-gradient(${one} 0%, ${two} 100%)`;
        }
        else if (type === 'Radial') {
            return `radial-gradient(circle, ${one} 20%, ${two} 120%)`;
        }
    }
    return one;
}