import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import CssBaseline from '@material-ui/core/CssBaseline';
import PrimarySearchAppBar from '../components/Appbar'
import Helmet from 'react-helmet';
import { Button, Container, Fab, Grid, IconButton, Input, InputBase, Popover, TextField, Tooltip, Typography } from '@material-ui/core';
import ModelCard from '../components/ModelCard2';
import LinearProgress from '@material-ui/core/LinearProgress';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import DeleteIcon from '@material-ui/icons/Delete';
import RedoIcon from '@material-ui/icons/Redo';
import CloseIcon from '@material-ui/icons/Close';
import ModelModal from '../components/ModelModal';
import { useSnackbar } from 'notistack';
import { modelCardProps } from '../types/modelcard';
import QuickSearchDialog from '../components/QuickSearchDialog';

const suggestions = [
    "Animals native to Australia",
    "Wild west",
    "Animals native to the United States",
    "City in the middle of a desert",
    "Medieval fantasy with modern guns",
    "Sweaty gamer dorm room",
    "Office workers in a zombie apocalypse",
    "A School for gamers",
    "Castle interior",
    "Suburban neighborhood",
];

const ListGen = (props) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [modelList, setModellist] = useState([]);

    // Searchbox input
    const [searchQuery, setsearchQuery] = useState("")

    const [loadingProgress, setloadingProgress] = useState<number | null>(null);

    const [stage, setstage] = useState(1);
    const [listReady, setlistReady] = useState(false)

    const [listID, setlistID] = useState(null);

    //Stage 3 quick search add
    const [searchOpen, setsearchOpen] = useState(false);
    const [quickSearchQuery, setquickSearchQuery] = useState("")

    async function searchChanged(e) {
        setsearchQuery(e.target.value);
    }

    function checkForEnter(e) {
        if (e.keyCode === 13 && searchQuery.length > 2) {

            setstage(2);

            //Increment loading progress bar over 30 seconds
            let progress = 0;
            const interval = setInterval(() => {
                progress += 1;
                setloadingProgress(progress);
                if (progress === 100) {
                    clearInterval(interval);
                }
            }, 250);

            axios.post('/api/lists/generate', { prompt: searchQuery }).then((res) => {
                setModellist(res.data.models)

                clearInterval(interval);
                setloadingProgress(null);
                setlistReady(true);
                setlistID(res.data.listID)

                window?.plausible("AI List", { props: { query: searchQuery, matched: res.data.score } });

            }).catch((err) => {
                console.log(err);
                clearInterval(interval);
                setloadingProgress(null);
                setstage(1);
                enqueueSnackbar(err.response.data.error, { variant: 'error', });
            })
        }
    }

    function fabClicked(e) {
        if (stage == 2) setstage(3);
        else if (stage == 3) {
            props.history.push(`/l/${listID}`)
        }
    }

    function reset() {
        setstage(1);
        setlistReady(false);
        setModellist([]);
        setsearchQuery("");
        setlistID(null);
    }

    function deleteModel(publicID) {
        setModellist(modelList.filter((model: modelCardProps) => model.publicID != publicID));

        //delete search term at same index
        const index = modelList.findIndex((model: modelCardProps) => model.publicID == publicID);

        enqueueSnackbar("Model deleted", {
            variant: 'success',
            action: (key) => (
                <Button variant='text' onClick={() => {
                    setModellist([...modelList]);
                    closeSnackbar(key);
                }}>Undo</Button>
            ),
            autoHideDuration: 5000,
        });
    }

    //Post update on list updated
    useEffect(() => {
        if (listID) {
            const modelIDs = modelList.map((model: modelCardProps) => model.publicID);
            axios.post('/api/list/' + listID, { Models: modelIDs })
        }
    }, [modelList]);

    return (
        <div className={`${stage < 3 && "h-[100vh] overflow-hidden"} ${stage != 2 && "listgenbg"} relative`}>
            <CssBaseline />

            <Helmet><title>{`Get Your own AI personalized asset pack - Poly Pizza`}</title></Helmet>
            <PrimarySearchAppBar loggedIn={props.isLoggedIn} history={props.history} isLoading={props.isLoading} />
            {loadingProgress && <LinearProgress variant="determinate" value={loadingProgress} />}

            <Container maxWidth={stage < 3 ? "lg" : "xl"} className='h-full min-h-screen'>
                {stage == 1 && <StageOne searchChanged={searchChanged} checkForEnter={checkForEnter} />}
                {stage == 2 && <StageTwo />}
                {stage == 3 && (
                    <StageThree
                        models={modelList}
                        prompt={searchQuery}
                        isLoggedIn={props.isLoggedIn}
                        deleteModel={deleteModel}
                        openQuickSearch={(query) => { setsearchOpen(true); setquickSearchQuery(query) }}
                    />)}
            </Container>

            {listReady && (
                <Fab variant="extended" color="primary" size='large' className={`!fixed bottom-7 right-7 ${stage == 2 && "animate-bounce"}`} onClick={fabClicked}>
                    {stage == 2 ? "Your list is ready!" : "Save list + download"}
                    <NavigateNextIcon />
                </Fab>)}

            {stage == 3 && (
                <Fab variant="extended" color="default" size='large' className={`!fixed bottom-7 left-7`} onClick={reset}>
                    <NavigateNextIcon className='scale-x-[-1]' />
                    Make another
                </Fab>)}

            <QuickSearchDialog
                open={searchOpen}
                query={quickSearchQuery}
                setOpen={setsearchOpen}
                list={modelList}
                setList={setModellist}
            />
        </div>
    );
}

function StageOne({ searchChanged, checkForEnter }) {

    return (
      <div className="flex flex-col gap-12 justify-center items-center h-full">
        <Typography
          variant="h2"
          component={"h1"}
          className="mt-4 mb-4 text-center"
        >
          Get your own AI <br /> personalized asset pack
        </Typography>
        <Typography variant="h5" className="mt-4 mb-4 text-center !font-light">
          Tell ChatGPT your theme or place, and it'll create a list of 3D models
          that fit right into your project
        </Typography>
        <input
          placeholder={
            suggestions[Math.floor(Math.random() * suggestions.length)]
          }
          className="!bg-white-light !text-black text-inherit text-lg rounded-lg px-4 py-2 w-1/2 border-none ring-0 focus:ring-0 focus:outline-none h-16"
          onChange={searchChanged}
          onKeyDown={checkForEnter}
        />
      </div>
    );
}

function StageTwo() {
    return (
        <div className='flex flex-col h-full gap-12 justify-center'>
            <Typography variant="h3" component={"h1"} className="my-12 text-center !font-thin">This might take a sec... <br />Enjoy this bad game while you wait!</Typography>
            <iframe src="https://chikanz.github.io/Meem-it/" width="100%" height="100%" frameBorder="0" className='h-[50vh] rounded-md !pointer-events-auto' />
        </div>
    )
}

function StageThree({ models, prompt, isLoggedIn, deleteModel, openQuickSearch }) {
    const [quickViewModel, setquickViewModel] = useState<string | null>(null);

    function closeTip(){
        localStorage.setItem("doneAITutorial", true);
        closeSnackbar("aisnack");
      }

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const snaction = (key) => (
      <IconButton
        size="small"
        className=""
        onClick={closeTip}
      >
        <CloseIcon className="!text-white !h-5 !w-5" />
      </IconButton>
    );

    useEffect(() => {
        if (typeof window !== "undefined" && !localStorage.getItem('doneAITutorial')) {
            setTimeout(() => {
                enqueueSnackbar(
                    <div className="flex items-center">
                      <p className='!my-0'>Sometimes the AI gets it wrong. Click </p>
                      <RedoIcon className="mx-1 my-0" />
                      <p className='!my-0'>to choose a different model</p>
                    </div>
                    ,
                    {
                      persist: true,
                      key: "aisnack",
                      action: snaction,
                      variant: "info",
                      position: "center",
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                      },
                    }
                  );
            }, 1200);
        }
    }, []);

    return (
      <>
        <Typography variant="h2" className="!my-12 text-center capitalize">
          {prompt}
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          className="text-right mb-2 fadeInSlideDown"
        >
          Click on a model to see a quick preview
        </Typography>
        <Grid container spacing={4} id="featuredModels">
          {models.map((card: modelCardProps, i) => (
            <Grid
              item
              key={card.publicID}
              xs={12}
              sm={6}
              md={4}
              lg={3}
              className="fadeInSlideDown"
              style={{ animationDelay: `${i * 0.075}s` }}
            >
              <ModelCard
                index={i}
                model={card}
                className=""
                noLink
                onClick={() => setquickViewModel(card.publicID)}
              />
              {/* Buttons */}
              <div className="w-full flex justify-between mt-2">
                {/* Trash icon for delete */}
                <Tooltip title={"Delete from list"}>
                  <IconButton
                    size="small"
                    className="!p-0 !m-0"
                    onClick={() => {
                      deleteModel(card.publicID);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>

                <Tooltip title={"Pick a different model"}>
                  <IconButton
                    size="small"
                    className="!p-0 !m-0"
                    onClick={() => {
                      openQuickSearch(card.SearchTerm ?? card.title);
                      closeTip();
                    }}
                  >
                    <RedoIcon />
                  </IconButton>
                </Tooltip>
              </div>
              <p className="hidden">{card.SearchTerm}</p>
            </Grid>
          ))}
        </Grid>

        <ModelModal
          modelID={quickViewModel}
          open={quickViewModel != null}
          setOpen={() => setquickViewModel(null)}
          isLoggedIn={isLoggedIn}
          changeModel={(delta) => {
            const currentIndex = models.findIndex(
              (x) => x.publicID === quickViewModel
            );
            const newIndex = currentIndex + delta;
            const newModel = models[(newIndex + models.length) % models.length];
            setquickViewModel(newModel.publicID);
          }}
        />
      </>
    );
}

ListGen.getInitialProps = async ({ req, res, match, history, location, ...ctx }) => {

    //@ts-ignore
    const isLoggedIn = req !== undefined ? ctx.authed : authed;  //if SSR, use authed from context, otherwise use local var;
    return { isLoggedIn }
}

export default ListGen;