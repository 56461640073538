import React from 'react';
import axios from 'axios';
import CssBaseline from '@material-ui/core/CssBaseline';
import PrimarySearchAppBar from '../components/Appbar'
import Helmet from 'react-helmet';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Tab, Tabs } from '@material-ui/core';
import { Link, Route, Switch } from 'react-router-dom';

import BuildIcon from '@material-ui/icons/Build';
import CodeIcon from '@material-ui/icons/Code';

import ProfileSettings from '../components/ProfileSettings';
import APISettings from '../components/APISettings';

const useStyles = makeStyles((theme) => ({
    tab: {
        flexDirection: 'row',
        fontSize: '1rem',
        gap: '10px',
        justifyContent: 'space-between',
        width: '100%',
        // marginRight: '20px',
        // height: '30px',
    },
    tabRoot: {
        maxWidth: '9999999999px',
       
    },

    tabsBorder: {
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '5px',
    }

}));

const Settings = (props) => {
    const classes = useStyles();

    const tabs = [
        {
            title: 'Profile',
            path: 'profile',
            component: ProfileSettings,
            icon: BuildIcon,
        },
        {
            title: 'API',
            path: 'api',
            component: APISettings,
            // component: () => <h1>Coming soon!</h1>,
            icon: CodeIcon,
        },
    ];

    return (
        <React.Fragment>
            <CssBaseline />
            <Helmet>
                <title>{`Settings - Poly Pizza`}</title>
                {/* <script src="https://www.google.com/recaptcha/api.js?render=6Lc_LAcaAAAAAJ8RT1-vgmA-GsTDH0KJBZ_toZZV"></script> */}
            </Helmet>
            <PrimarySearchAppBar loggedIn={props.isLoggedIn} history={props.history} isLoading={props.isLoading} />

            <Container style={{ padding: '50px' }} maxWidth="xl">
                <Grid container spacing={4}>
                    <Grid item xs={12} lg={2} >
                        <Tabs
                            orientation="vertical"
                            // variant="scrollable"
                            indicatorColor='primary'
                            value={props.location.pathname}
                            // onChange={handleChange}
                            
                        >
                            {
                                tabs.map(t => {
                                    const url = `/settings/${t.path}`;
                                    return (
                                        <Tab label={t.title} wrapped classes={{ wrapper: classes.tab, root: classes.tabRoot }}
                                            key={t.title}
                                            component={Link}
                                            to={url}
                                            value={url}
                                            icon={<t.icon />}
                                        />
                                    )
                                })
                            }
                        </Tabs>
                    </Grid>
                    <Grid item xs={12} lg={10}>
                        <Paper className={'p-8 h-full w-full'}>
                            <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, padding: '5rem', paddingTop: '0' }}>
                                <Switch>
                                    {
                                        tabs.map((t) => {
                                            return (<Route exact path={`/settings/${t.path}`} key={t.title}>
                                                <Typography variant="h4">{t.title}</Typography>
                                                <t.component />
                                            </Route>)
                                        })
                                    }
                                </Switch>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>

        </React.Fragment>
    );
}

Settings.getInitialProps = async ({ req, res, match, history, location, ...ctx }) => {
    const isLoggedIn = req !== undefined ? ctx.authed : authed;  //if SSR, use authed from context, otherwise use local var;
    return { isLoggedIn, darkMode: ctx.darkMode }
}

export default Settings;