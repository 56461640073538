import React from 'react';
import Socials from '../util/Socials';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MUILink from '@material-ui/core/Link';
import { Link } from 'react-router-dom';

const socials = Socials.Unwrap({
    Twitter: 'duckpizza_',
    Youtube: 'c/dookdook',
    Discord: 'mEwMwSU',
    Email: 'hi@duck.pizza',
})

const useStyles = makeStyles((theme) => ({
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(4),
        marginTop: '3vh'
    },
    buttonIcon: {
        color: '#80868b',
    },
}))

const footer = (props) => {
    const classes = useStyles();
    return (
        <footer className={classes.footer + ' flex flex-col gap-3'}>
            <Typography variant="h6" align="center">
                Made with 🍕 by dook
            </Typography>
            <div className='flex w-full justify-center'>
                {socials.map((site) => {
                    return (
                        <IconButton size="small"
                            key={site.siteName}
                            style={{ marginLeft: '5px' }}
                            aria-label={site.siteName}
                            target="_blank"
                            rel="noopener"
                            href={site.link}
                        >
                            <site.icon className={classes.buttonIcon} />
                        </IconButton>
                    );
                })}
            </div>
            <div className="flex justify-center gap-3">
                <MUILink component={Link} className={classes.buttonIcon} to="/docs/tos"> Terms </MUILink>
                <MUILink component={Link} className={classes.buttonIcon} to="/docs/privacy"> Privacy </MUILink>
                <MUILink component={Link} className={classes.buttonIcon} to="/docs/security"> Security </MUILink>
                <MUILink component={Link} className={classes.buttonIcon} to="/docs/press"> Press </MUILink>
                <MUILink component={Link} className={classes.buttonIcon} to="/docs/api/v1.1"> API </MUILink>
            </div>
        </footer>
    );
}


export default footer;