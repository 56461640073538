import React from 'react';
import qs from 'querystring';

const RequireAuth = (C, enabled) => {
    class AuthComponent extends React.Component {
        static async getInitialProps({ req, res, match, history, location, ...ctx }) {
            let LoggedIn = ctx.authed;
            if (typeof authed != "undefined") LoggedIn = authed;

            if (LoggedIn != enabled) {
                if (enabled) //if login is required
                {
                    const fromURL = req.url;
                    return { redirectTo: `/login?from=${fromURL}` };
                }
                else //logged in users can't access
                {
                    if (req !== undefined && req.url.includes("?from")) {
                        const parsed = qs.parse(req.url);
                        return { redirectTo: parsed['/login?from'] };
                    }
                    return { redirectTo: '/' };
                }
            }

            // Get component’s props
            let componentProps = {}
            if (C.getInitialProps) {
                componentProps = await C.getInitialProps({ req, res, match, history, location, ...ctx });
            }

            return {
                ...componentProps
            };
        }

        render() {
            return <C {...this.props} />;
        }
    }

    return AuthComponent;
};

export default RequireAuth;