import SpaceMouseInit from './spaceMouseInit';

//Handles space mouse rotate + removing of glossy materials
export default function ModelViewerSetup() {
    const modelViewers = document.querySelectorAll('model-viewer');
    if (modelViewers.length == 0) return;

    SpaceMouseInit();

    for (let i = 0; i < modelViewers.length; i++) {
        const modelViewer = modelViewers[i];
        //Turn off interaction prompt if done before
        if (localStorage.getItem('interactedWithModel') !== null) {
            modelViewer.interactionPrompt = 'none';
        }

        //Disable metallic
        modelViewer.addEventListener("load", async (ev) => {
            let materials = modelViewer.model.materials;
            for (let i = 0; i < materials.length; i++) {
                const mat = materials[i];
                await mat.ensureLoaded();
                if (!mat.pbrMetallicRoughness) continue; //idk sometimes this just isn't here
                mat.pbrMetallicRoughness.setMetallicFactor(0);
                mat.pbrMetallicRoughness.setRoughnessFactor(1);
                mat.setDoubleSided(true);
            }
        });
    }
}