import React, { useEffect, useState, useRef, AnchorHTMLAttributes } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Typography } from '@material-ui/core';
import { ad } from '../types/puppy';
import BannerAd from './Adsense/BannerAd';

const useStyles = makeStyles(theme => ({
    pup: {

        height: '35vh',
        width: '100%',
        borderRadius: '20px',
        filter: 'brightness(50%)',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',

        [theme.breakpoints.up('sm')]: {
            backgroundSize: '100%',
            backgroundRepeat: 'no-repeat',
            height: '15vh',
            objectFit: 'cover',
            transition: 'all 0.35s ease-out',

            '&:hover': {
                // transform: 'scale(1.005)',
                backgroundSize: '102.5%',
                filter: 'brightness(65%)',
            },
        },
    },

    Copy: {
        position: 'absolute',
        zIndex: 99,
        color: '#fff',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        // alignItems: 'center',
        justifyContent: 'center',
        margin: '0 3rem',
        pointerEvents: 'none',
        fontFamily: 'Inter, sans-serif',
        textShadow: '0px 0px 2px rgba(0,0,0,0.81)',
    },

    // Text: {
    //     fontSize: '2.75rem',
    //     margin: 0,
    // },

    // Subtitle: {
    //     fontSize: '1.2rem',
    //     margin: 0,
    // },

    Link: {
        position: "relative",
        flexGrow: 1,
        [theme.breakpoints.up('sm')]: {
            margin: '0 1.25rem'
        },
    },

    noMobile: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'inherit',
        },
    }
}));

interface proptypes {
    variant: number; //index of what copy to show
    ad: ad;
    noMobile?: boolean; //kill this ad on mobile
}

const Puppy = (props: proptypes) => {
    const classes = useStyles();
    const [copy, setcopy] = useState<string[]>();
    const ad = useRef<HTMLAnchorElement | undefined>();

    const variantIndex = props.variant;

    //Log click
    const logClick = () => {
        axios.post(`/api/puppies/${props.ad.Slug}/Click`, { Variant: variantIndex });
    }

    useEffect(() => {
        //Setup variant
        setcopy(props.ad.Text[variantIndex].split('__'));

        //Log impression
        var observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                axios.post(`/api/puppies/${props.ad.Slug}/View`, { Variant: variantIndex });
            }
        },
            { root: null, rootMargin: "0px", threshold: 0 }
        );
        if (ad.current) observer.observe(ad.current);

        return () => {
            if (ad.current) observer.unobserve(ad.current);
        }

    }, [ad.current]);

    // Show adsense ads instead if not blocking
    // if (typeof window !== "undefined" && !document.adBlockEnabled) {
    //     return (
    //         <div className='flex items-center justify-center h-[10vh] w-full'>
    //             <BannerAd />
    //         </div>
    //     )
    // }

    // if (!copy) return null;

    const AdText = (
        copy ? <>
            <h2 className=" text-4xl lg:text-[2.75rem] m-0">{copy[0]}</h2>
            {copy[1] && <h4 className="text-[1.2rem] m-0">{copy[1]}</h4>}
        </> : null
    )

    return (
        <a onClick={logClick} onAuxClick={logClick} href={props.ad.Link}
            target="_blank" rel="noopener" className={props.noMobile ? `${classes.Link} ${classes.noMobile}` : classes.Link} ref={ad}>
            <div className="absolute z-50 text-white-light h-full flex flex-col justify-center mx-4 md:mx-12 pointer-events-none font-Inter text-shadows">
                {AdText}
            </div>
            <Paper className={'!transition-all min-h-[35vh] md:min-h-[15vh] w-full !rounded-2xl brightness-50 bg-cover bg-center hover:brightness-[60%]'} elevation={3} style={{ backgroundImage: `url(${props.ad.BG})` }}>
            </Paper>
        </a>
    );
}

export default Puppy;