import { Avatar, Button, CircularProgress, FormControlLabel, FormGroup, makeStyles, Switch, TextField } from "@material-ui/core";
import axios from "axios";
import { useSnackbar } from "notistack";
import React, { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import DisplayAxiosError from "../util/DisplayAxiosError";

import Socials from '../util/Socials';
import SimplePopup from "./SimplePopup";
const socialPlaceholders = [
    "@dril",
    "facebook.com/onlyEnterThisBit",
    "instagram.com/onlyEnterThisBit",
    "patreon.com/onlyEnterThisBit",
    "ko-fi.com/onlyEnterThisBit",
    "discord.gg/onlyEnterThisBit",
    "artstation.com/onlyEnterThisBit",
    "https://example.pizza",
    "c/dook OR channel/UCalWe...",
    "hi@duck.pizza",
];

const alphaNumeric = /^[\w\-\s\.]+$/; //plus . character just in case
const handleSocialInput = (event) => {
    if (!event.key.match(alphaNumeric)) {
        event.preventDefault();
    }
}

const sade = <img src={`/img/sad/${Math.floor(Math.random() * 4) + 1}.jpg`} style={{ width: '100%' }}></img>

const useStyles = makeStyles((theme) => ({
    rowEntry: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        paddingBottom: '30px',
        paddingTop: '30px',
    },

    columnEntry: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        paddingBottom: '30px',
        gap: '7px'
    },

    ProfileInput: {
        width: '100%',
        [theme.breakpoints.up('lg')]: {
            width: '35%',
        },
        margin: '5px 0',
    },
    ProfileHeading: {
        margin: '10px 0',
        fontSize: '1.25rem',
        fontWeight: 400,
    },
    tab: {
        flexDirection: 'row',
        fontSize: '1rem',
        gap: '10px'
    },

}));


export default function () {
    const classes = useStyles();

    const { register: registerDP, handleSubmit: handleDPSubmit } = useForm(); //form instance for dp upload
    const { register, handleSubmit, errors } = useForm(); //rest of the form

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [user, setLoadedData] = React.useState<any>(false);
    const [dpSource, setDpSource] = React.useState('');
    const [submitting, setSubmitting] = React.useState(false);
    const [popupIsOpen, setPopupOpen] = React.useState(false); //for 'why' text in password helper

    const [hasSub, setSubbed] = React.useState(false);

    const [emailLists, setemailList] = useState(
        [
            { name: 'Newsletter', subscribed: false, id: 1 },
            { name: 'Competitions', subscribed: false, id: 3 },
        ]);

    function handleUpdateEmails(index, subscribed) {
        let temp = [...emailLists];
        temp[index].subscribed = subscribed;
        setemailList(temp);
    }

    const cancelSub = async () => {
        await axios.post(`${process.env.SITE_ROOT}/api/payment/unsub/me`)
            .then(() => {
                enqueueSnackbar("You subscription was cancelled", { variant: 'success' });
                setPopupOpen(false);
                setSubbed(false);
            })
            .catch((error) => {
                {
                    DisplayAxiosError(error, enqueueSnackbar);
                    setPopupOpen(false);
                }
            });
    }

    const refreshData = () => {
        setSubmitting(true);

        axios.get(`${process.env.SITE_ROOT}/api/me/settings`, { withCredentials: true })
            .then(resp => {
                setSubmitting(false);
                setLoadedData(resp.data);
                setDpSource(resp.data.DPURL + `?${Date.now()}`);
                setSubbed(resp.data.subbed);
                localStorage.setItem('user', JSON.stringify({ publicID: resp.data.username, DPURL: resp.data.DPURL })); //so when we get a DPURL for the first time it's updated in ls (gets run every time tho)

                //Process email lists
                for (let i = 0; i < resp.data.newsletterLists.length; i++) {
                    const list = resp.data.newsletterLists[i];
                    //find id in emailLists
                    const listIndex = emailLists.findIndex(x => x.id === list.id);
                    if (listIndex > -1) {
                        handleUpdateEmails(listIndex, true);
                    }
                }

            }).catch((error) => {
                DisplayAxiosError(error, enqueueSnackbar)
                setSubmitting(false);
            });
    }
    React.useEffect(refreshData, []);

    //dp submit
    const dpSubmit = async (data) => {
        if (submitting) return; //prevent spamming

        setSubmitting(true);
        let fd = new FormData();
        fd.append('dp', data.dp[0], data.dp[0].name);


        await axios.post(`${process.env.SITE_ROOT}/api/user/dp/upload`, fd, { headers: { 'Content-Type': 'multipart/form-data' } }).then(async function (response) {
            refreshData();
            enqueueSnackbar("Your new display picture was uploaded!", { variant: 'success' });
            setSubmitting(false);
        }).catch((error) => {
            DisplayAxiosError(error, enqueueSnackbar);
            setSubmitting(false);
        });
    }

    //rest of the shit submit
    const Submit = async (data) => {
        if (submitting) return; //prevent spamming
        setSubmitting(true);

        delete data.PasswordConfirm;

        // data.getUpdateEmails = getUpdateEmails;
        //Turn email lists into array of ids
        data.newsletterLists = emailLists.filter(x => x.subscribed).map(x => x.id);

        //remove empty strings from being submitted
        Object.keys(data).forEach(key => {
            if (data[key] === '' || data[key] == null) {
                delete data[key];
            }
        });

        grecaptcha.ready(function () {
            grecaptcha.execute('6Lc_LAcaAAAAAJ8RT1-vgmA-GsTDH0KJBZ_toZZV', { action: 'changeSettings' }).then(async function (token) {
                await axios.put(`/api/me/settings`, { data, token, }).then(function (response) {
                    enqueueSnackbar("Your settings have been updated", { variant: 'success' });
                    setSubmitting(false);
                }).catch((error) => {
                    DisplayAxiosError(error, enqueueSnackbar);
                    setSubmitting(false);
                });
            })
        })
    }

    if (!user) return <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}><CircularProgress /></div>

    return (
        <>
            <div className={classes.rowEntry}>
                <Avatar style={{ width: '100px', height: '100px' }} src={dpSource} />
                <form action="/api/user/dp/upload" method="post" encType="multipart/form-data" id="dpForm">
                    <input
                        name="dp"
                        accept="image/jpeg,image/jpg"
                        style={{ display: 'none' }}
                        id="upload-button-file"
                        type="file"
                        onChange={handleDPSubmit(dpSubmit)}
                        ref={registerDP}
                    />
                    <label htmlFor="upload-button-file">
                        <Button variant="outlined" color="primary" component="span" style={{ marginLeft: '20px' }}>Upload</Button>
                    </label>
                </form>
            </div>

            <div className={classes.columnEntry}>
                <h4 className={classes.ProfileHeading}>About</h4>
                <TextField variant="outlined" value={user.email} disabled helperText="" label="Email" className={classes.ProfileInput} />
                <TextField variant="outlined" inputRef={register} defaultValue={user.about} className={classes.ProfileInput} name="About" label="Bio" placeholder="what's poppin?" multiline maxRows={4} />
                <TextField
                    inputRef={register({ maxLength: { value: 25, message: 'i dont think thats a real place bro' } })}
                    error={errors.Location !== undefined}
                    helperText={errors.Location?.message}
                    defaultValue={user.location}
                    name="Location"
                    label="Location"
                    variant="outlined"
                    placeholder="Melbourne, Straya"
                    className={classes.ProfileInput} />
            </div>

            <div className={classes.columnEntry}>
                <h4 className={classes.ProfileHeading}>Socials</h4>
                {
                    Object.keys(Socials.IconMap).map((s, i) =>
                        <TextField
                            variant="outlined"
                            onKeyPress={handleSocialInput}
                            defaultValue={user.socials[s]}
                            name={s}
                            label={s}
                            inputRef={register}
                            placeholder={socialPlaceholders[i]}
                            className={classes.ProfileInput}
                        />
                    )
                }
            </div>

            {/* Email prefs */}
            <div className={classes.columnEntry}>
                <h4 className={classes.ProfileHeading}>Email Prefs</h4>
                {emailLists.map((list, i) => (
                    <FormControlLabel
                        control={
                            <Switch
                                checked={list.subscribed}
                                onChange={(e) => handleUpdateEmails(i, e.target.checked)}
                                name={list.name}
                                color="primary"
                            />
                        }
                        label={list.name}
                    />
                ))}
            </div>

            <div className={classes.columnEntry}>
                <h4 className={classes.ProfileHeading}>Change Password</h4>
                <TextField inputRef={register} variant="outlined" name="NewPassword" label="New password" placeholder="" className={classes.ProfileInput} />
                <TextField inputRef={register} variant="outlined" name="NewPasswordConfirm" label="Confirm new password" placeholder="" className={classes.ProfileInput} />
            </div>

            {/* <div style={{ paddingBottom: '20px', paddingLeft: '5px' }}>
                <FormControlLabel
                    control={<Switch size="small" color="primary" checked={getUpdateEmails} onChange={() => setGetUpdateEmails(!getUpdateEmails)} />}
                    label="Get Update Emails"
                />
            </div> */}

            {/* <div style={{ paddingBottom: '20px', paddingLeft: '5px' }}>
                <FormGroup>
                    <FormControlLabel
                        control={<Switch size="small" color="primary" checked={darkMode} onChange={() => {
                            setDarkMode(!darkMode);
                            window.localStorage.setItem('darkMode', (!darkMode).toString());
                            document.cookie = `darkMode=${!darkMode}; expires=Sun, 1 Jan 2043 00:00:00 UTC; path=/`
                            location.reload();
                        }} />}
                        label="Dark mode"
                    />
                </FormGroup>
            </div> */}

            <div style={{ display: 'flex', marginBottom: '20px' }}>
                <Button variant="contained" color="primary" onClick={handleSubmit(Submit)}>Save</Button>
                {submitting && <div style={{ marginLeft: '20px' }}><CircularProgress style={{ height: '30px', width: '30px' }} /></div>}
            </div>

            {hasSub && <Button variant="outlined" onClick={() => setPopupOpen(true)}>Cancel Monthly Donation</Button>}

            <SimplePopup
                handleClose={() => setPopupOpen(false)}
                open={popupIsOpen}
                closeText="nah"
                title="Really cancel Sub? 👉👈"
                body={sade}
                action={cancelSub}
                actionText={"Yep"}
            />
        </>
    )
}

function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}
