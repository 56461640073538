import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import axios from 'axios'
import { Redirect } from "react-router-dom";

const Logout = (props) => {

    const [loggedOut, setLogoutState] = React.useState(false);

    //Use use effect to make sure logout code is only called once
    //https://reactjs.org/docs/hooks-overview.html#effect-hook
    React.useEffect(() => {
        if (typeof window !== "undefined") { //client only hack
            ActuallyLogout(setLogoutState);
        }
    }, []);

    if (loggedOut)
        return <Redirect to={{ pathname: '/', state: { message: 'You were logged out' } }} />
    else
        return (
            <React.Fragment>
                <CssBaseline />
                <div style={{
                    width: '100vw',
                    height: '100vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <CircularProgress color="textPrimary" />
                    <Typography variant="h2" component="h1" color="textPrimary">Logging out...</Typography>

                </div>
            </React.Fragment>
        );
}

async function ActuallyLogout(setLogoutState) {
    localStorage.removeItem('user');
    await axios.post(`${process.env.SITE_ROOT}/api/logout`, {}, { withCredentials: true });
    setLogoutState(true);
    document.cookie = `darkmode=${darkMode}; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/`
    window.location = '/'; //router method just not called sometimes??
}


export default Logout;
