import { makeStyles } from '@material-ui/core/styles';
import CreateGradientString from '../../util/CreateGradientString';

export default makeStyles(theme => ({
    model: props => ({
        //backgroundColor: props.backgroundColor,
        background: CreateGradientString(props.model.Background.one, props.model.Background.two, props.model.Background.type),
        overflowX: "hidden",
        "--poster-color": theme.palette.secondary.main,
        width: '100%',
        height: '100%',
        'border-bottom-style': 'none',
        borderRadius: '0 0 4px 0',
    }),

    modelGridItem:
    {
        height: '80vh',
    },

    paper:
    {
        height: '100%',
        width: '100%',
    },

    sideGrid:
    {
        margin: "0px", //overrides default because the grid spacing prop creates negative margin for some fucking reason
        zIndex: "10",
        borderRadius: "4px",
    },

    hideOnMobile: {
        display: 'none',
        [theme.breakpoints.up('lg')]: {
            display: 'block',
        },
    },

    title: {
        fontSize: '2.25rem',
    },

    descriptionContainer: {
        padding: '8vw',

        margin: '0px',
        marginLeft: '0px',
        //paddingRight: '0px',
        paddingTop: '0px',
        paddingBottom: '0px',
    },

    noMargin: {
        margin: 0,
    },

    button: {
        textTransform: 'capitalize',
        color: '#000',
    },

    buttonIcon: {
        color: '#80868b',
    },

    titleGrid: {
        padding: '10px',
        paddingLeft: '0px',
        paddingRight: '0px',
    },

    inlineIcon: {
        color: '#626262',
        height: '0.9375rem',
        marginBottom: '-2.5px',
    },

    stats: {
        margin: '0px',
        marginBottom: '3px',
        fontWeight: '500',
        fontSize: '0.9375rem', //1.2
        color: '#626262',
    },

    normalFontWeight:
    {
        fontWeight: '400',
    },

    divider:
    {
        marginLeft: '10px',
        margin: '0px',
    },

    commentAvi:
    {
        marginBottom: 'auto',
        marginTop: '20px',
    },

    modelViewerIcons:
    {
        display: 'flex',
        flexDirection: 'row-reverse',
        position: 'relative',
        top: '20px',
        right: '7px',
        zIndex: 1,
        height: 0,
    },
    VisDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
        height: '3vh'
    }
}));
