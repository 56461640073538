import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Catagories } from '../util/HardCodedLists';
import { useSnackbar } from 'notistack';
import ChipBowl from '../components/ChipBowl'
import { makeStyles } from '@material-ui/core/styles';
import { Button, Checkbox, FormControlLabel, IconButton, Paper, TextField } from '@material-ui/core';
import type adSlot from '../models/adSlot';
import axios from 'axios';
import { AdStat } from '../types/adStats';

const useStyles = makeStyles(theme => ({
    input: {
        marginTop: '20px',
    },
}));

const AdEdit = (props: { ad: typeof adSlot, confirmDelete: (arg0: string) => void, stats: [AdStat] }) => {
    const classes = useStyles();

    const { register, handleSubmit, errors } = useForm();

    const [ad, setad] = useState(props.ad);

    function AddCopySlot() {
        let a = { ...ad };
        a.Text.push([]);
        setad(a);
    }

    const onSubmit = async data => {

        let copy = [];
        const slots = Object.keys(data).filter(key => key.includes('Copy Slot'));
        for (let i = 0; i < slots.length; i++) {
            copy.push(data[slots[i]]);
            delete data['Copy Slot ' + i];
        }

        const d = { ...data, Text: copy }
        console.log(d);
        await axios.post(`/api/dook_only/ads/${data.Slug}`, d);
    }

    return (
        <Paper style={{ display: "flex", justifyContent: 'space-between', padding: '30px', marginTop: '20px' }}>

            <Checkbox checked={ad.Live} onChange={() => { let a = { ...ad }; a.Live = !a.Live; setad(a); console.log(a); }} name={'Live'}
                inputRef={register} />


            <TextField
                name="Slug"
                label="Slug"
                variant="outlined"
                defaultValue={ad.Slug}
                inputRef={register({ required: 'Required' })}
            />
            <div style={{ display: "flex", flexDirection: 'column', width: '500px' }}>
                {ad.Text.map((t, i) => {
                    return (
                        <div style={{ marginBottom: '10px', width: '100%', display: 'flex' }}>
                            <TextField
                                key={t + ' ' + i}
                                label={'Copy Slot ' + i}
                                name={'Copy Slot ' + i}
                                variant="outlined"
                                defaultValue={t}
                                fullWidth
                                multiline
                                inputRef={register()}
                            />

                            {props.stats && props.stats[i] && <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <p style={{ textAlign: 'left', margin: 0 }}>I: {props.stats[i].View}</p>
                                <p style={{ textAlign: 'left', margin: 0 }}>U: {props.stats[i].Unique}</p>
                                <p style={{ textAlign: 'left', margin: 0 }}>C:{props.stats[i].Click} </p>
                                <p style={{ textAlign: 'left', margin: 0 }}>CTR: {((props.stats[i].Click / props.stats[i].Unique) * 100).toFixed(2)}%</p>
                            </div>}
                        </div>
                    )
                })}
                <Button onClick={AddCopySlot}>Add</Button>
            </div>

            <TextField
                label="Link"
                name="Link"
                variant="outlined"
                defaultValue={ad.Link}
                inputRef={register({ required: 'Required' })}
            />

            <TextField
                label="BG link"
                name="BG"
                variant="outlined"
                defaultValue={ad.BG}
                inputRef={register({ required: 'Required' })}
            />

            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Button onClick={handleSubmit(onSubmit)}>Update</Button>
                <Button onClick={() => props.confirmDelete(ad.Slug.toString())}>Delete</Button>
            </div>

        </Paper>
    )
}

export default AdEdit;