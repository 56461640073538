import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import CreateGradientString from '../util/CreateGradientString';

function GetBGColor(props) {
    return CreateGradientString(props.color1, props.color2, props.gradient);
}

function SetBgColor(props){
    props.storeBG(props.color1, props.color2, props.gradient);
    props.setBG(GetBGColor(props));
}

const useStyles = makeStyles((theme) => ({
    square: props => ({
        width: '100%',
        height: '100%',
        background: GetBGColor(props),
        border: '0px solid #000'
    }),
    image: {
        height: '3vw',
        width: '3vw',
    },
    focusVisible: {},
}));

export default function ButtonBases(props) {
    const classes = useStyles(props);

    return (
        <ButtonBase
            focusRipple
            className={classes.image}
            focusVisibleClassName={classes.focusVisible}
            onClick={() => SetBgColor(props)}
        >
            <span className={classes.square}></span>
        </ButtonBase>
    );
}