import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import MUILink from '@material-ui/core/Link';
import { useForm } from 'react-hook-form';
// import validator from 'validator';
import isEmail from 'validator/lib/isEmail';
import Axios from 'axios';
import { useSnackbar } from 'notistack';
import LoadingBar from '../components/LoadingBar'
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom'


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const SignIn = (props) => {
    const classes = useStyles();
    const { register, handleSubmit, watch, errors } = useForm();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [loadingEnabled, setLoadingBar] = React.useState(false);

    const onSubmit = data => {
        setLoadingBar(true);

        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('t');
        if (token) data.verify = token;

        grecaptcha.ready(function () {
            grecaptcha.execute('6Lc_LAcaAAAAAJ8RT1-vgmA-GsTDH0KJBZ_toZZV', { action: 'login' }).then(async function (token) {
                await Axios.post("/api/login", { data, token, }, { withCredentials: true, credentials: 'include' }).then(function (response) {
                    const from = urlParams.get('from');

                    localStorage.setItem('user', JSON.stringify(response.data));
                    if (from !== null) {
                        window.location = decodeURI(from);
                        //props.history.push(decodeURI(from)); //bug: doesn't update login state when using react router to navigate
                    }
                    else {
                        window.location = '/';
                        //props.history.push(response.data.shittyRedir);
                    }
                }).catch(function (e) {
                    console.log(e);
                    setLoadingBar(false);
                    enqueueSnackbar(e.response.data.error, { //todo replace with catch axios error + pass anchor origin
                        variant: 'error',
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        },
                        autoHideDuration: 7000,
                    });
                });
            });
        });
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Poly Pizza - Login`}</title>
                {/* <script src="https://www.google.com/recaptcha/api.js?render=6Lc_LAcaAAAAAJ8RT1-vgmA-GsTDH0KJBZ_toZZV"></script> */}
            </Helmet>
            <LoadingBar enabled={loadingEnabled} color="primary" />
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            error={errors.email !== undefined}
                            helperText={errors.email?.message}
                            inputRef={register({
                                required: 'Required',
                                validate: {
                                    valid: value => isEmail(value) || "hey that's not an email",
                                }
                            })}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            error={errors.password !== undefined}
                            helperText={errors.password?.message}
                            inputRef={register({ required: 'Required' })}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Sign In
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <MUILink to="/forgot" component={Link} variant="body2" color="textSecondary">Forgot password?</MUILink>
                            </Grid>
                            <Grid item>
                                <MUILink to="/signup" component={Link} variant="body2" color="textSecondary">
                                    {"Don't have an account? Sign Up"}
                                </MUILink>
                            </Grid>
                        </Grid>
                    </form>
                </div>
                <Box mt={8}>
                    <Typography variant="body2" color="textSecondary" align="center">
                        This site is protected from robots by reCAPTCHAv3, meaning the <MUILink color="textSecondary" href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">Google Privacy Policy</MUILink> and <MUILink color="textSecondary" href="https://policies.google.com/terms" target="_blank" rel="noreferrer">Terms of Service</MUILink> also apply.
                    </Typography>
                </Box>
            </Container>
        </React.Fragment>
    );
}

SignIn.getInitialProps = async ({ req, res, match, history, location, ...ctx }) => {
    const isLoggedIn = req !== undefined ? ctx.authed : authed;  //if SSR, use authed from context, otherwise use local var;
    let darkMode = ctx.darkMode;

    return { darkMode, isLoggedIn };
}


export default SignIn;