import React from 'react';
import detailStyles from '../pages/styles/detailStyle';
import axios from 'axios';
import CssBaseline from '@material-ui/core/CssBaseline';
import ModelViewerSetup from '../util/modelViewerInit';

const ScreenShot = (props) => {
    var classes = detailStyles(props);
    
    React.useEffect(() => ModelViewerSetup(false), []);

    return (
        <React.Fragment>
            <CssBaseline />
            <model-viewer
                id="mainModel"
                environment-image="/static/HDR/BG.hdr"
                class={`${classes.model} noMenu`}
                src={`https://static.poly.pizza/${props.model.ResourceID}.glb.br`}
                // src={`${process.env.SITE_ROOT}/api/static/${props.model.ResourceID}.glb.br`} //i think this is slightly faster than static since it avoids cloudflare
                alt={props.model.Title}
                shadow-intensity="0"
                interaction-prompt="false"
                loading="eager"
                {...props.viewerAtts}
                disable-zoom
                style={{ width: '100vw', height: '100vh' }}
                camera-orbit={props.model.Orbit ? `${props.model.Orbit.theta}rad ${props.model.Orbit.phi}rad ${props.model.Orbit.radius}m` : '0deg 75deg 105%'}
                onClick={() => {
                    // What the fuck does this do????
                    if(!props.viewerAtts) return false;
                    //http://www.awongcm.io/blog/2018/11/25/using-iframes-api-to-toggle-client-side-routing-of-react-router-for-legacy-web-apps/
                    const URL = '/m/' + props.model.PublicID;
                    var topParentWindow = window.frameElement.ownerDocument.defaultView;
                    topParentWindow.history.pushState({}, '', URL);
                    topParentWindow.history.go();
                    topParentWindow.scrollTo(0, 0);
                }}
            >
                <div slot="progress-bar"></div>
            </model-viewer>
        </React.Fragment>
    );
}

ScreenShot.getInitialProps = async ({ req, res, match, history, location, ...ctx }) => {
    const response = await axios.get(`${process.env.SITE_ROOT}/api/model/${match.params.id}/details`);
    var model = response.data;

    //For 3D card preview
    if (req !== null && req.query.card) {
        return {
            model, viewerAtts: {
                'auto-rotate': 'true',
                'auto-rotate-delay': '0',
            }
        }
    }

    return { model }
}

export default ScreenShot;
