import React from 'react';
import axios from 'axios';
import CssBaseline from '@material-ui/core/CssBaseline';
import Helmet from 'react-helmet';
import PrimarySearchAppBar from '../components/Appbar'
import Grid from '@material-ui/core/Grid';
import ModelCard from '../components/ModelCard';
import ListCard from '../components/ListCard';
import Container from '@material-ui/core/Container';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { Badge, Chip, Typography } from '@material-ui/core';
import ButtonBase from '@material-ui/core/ButtonBase';
import twas from 'twas';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Socials from '../util/Socials';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import QuickCreateList from '../components/QuickCreateList';
import CarbonAds from '../components/carbonAds';
import abbreviate from 'number-abbreviate';
import GetAppIcon from '@material-ui/icons/GetApp';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { Switch, Route, Link } from "react-router-dom";
import { ModelBundle } from '../types/List';
import { ListCard as ListCardData, ListCardModel } from '../types/List';


const useStyles = makeStyles((theme) => ({
    VisDiv: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.palette.secondary.main,
            color: 'white',
            height: '3vh',
        },
    },
    HeaderBG: {
        background: `linear-gradient(143deg, ${theme.palette.gradient.one} -50%, ${theme.palette.gradient.two} 70%)`,
    }
}));

interface propTypes {
    User: {
        Socials: any; 
        username: string;
        lists: ListCardData[];
        DPURL: string | undefined; models: string | any[]; likes: string | any[];
        migrated: boolean;
        Location: string;
        About: string;
        joinDate: string;
        Stats: { [x: string]: any; };
    };
    isLoggedIn: any;
    history: any;
    isLoading: any;
    location: { pathname: any; };
}


const Profile = (props: propTypes) => {
    const classes = useStyles();
    if (!props.User) return <p>this user is fucked lmaooooo</p>;

    let socials = Socials.Unwrap(props.User.Socials);

    let [resultLimit, setResultLimit] = React.useState(20);

    const [tabPage, setTab] = React.useState(0);

    //shitty paginate models
    const commentLoaderRef = React.useRef<HTMLDivElement>();

    //observe comment spinner to lazy load comments
    React.useEffect(() => {
        var observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                setResultLimit(resultLimit + 20);
            }
        },
            {
                root: null,
                rootMargin: "0px",
                threshold: 0
            }
        );
        if (commentLoaderRef.current) {
            observer.observe(commentLoaderRef.current);
        }

        return () => {
            if (commentLoaderRef.current) {
                observer.unobserve(commentLoaderRef.current);
            }

        }
    }, [commentLoaderRef.current, resultLimit]);

    const [isMe, setisMe] = React.useState(false);
    React.useEffect(() => {
        const user = localStorage.getItem('user');
        if (!user) return;
        const currentUser = JSON.parse(user);
        setisMe(currentUser && currentUser.publicID == props.User.username);
    }, [])

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    function ListTab() {
        return (
            <>
                {isMe &&
                    <QuickCreateList
                        label={"Create a list"}
                        OnCreated={() => window.location.reload()}
                        style={{ marginTop: '20px', justifyContent: 'left' }}
                    />
                }
                <Grid container spacing={6} style={{ marginTop: '10px', marginBottom: '8vh' }}>
                    {props.User.lists.map((list, index) => (
                        <Grid item key={list.PublicID} xs={12} sm={6} md={4} lg={3}>
                            <ListCard list={list} noAvatar />
                        </Grid>
                    ))}

                    { /*Show a lil shrug guy when the user has no models */
                        props.User.lists == 0 &&
                        (<Grid item xs={12} style={{ textAlign: 'center' }}>
                            <Typography variant="h1">¯\_(ツ)_/¯</Typography>
                            <Typography variant="h4">There's nothing here!</Typography>
                        </Grid>)
                    }
                </Grid>
            </>
        );
    }

    function ModelTab(props) {
        return (
            <Grid container spacing={6} style={{ marginTop: '10px', marginBottom: '8vh' }}>
                {props.models.slice(0, resultLimit).map((card, index) => (
                    <Grid item key={card.publicID} xs={12} sm={6} md={4} lg={3}>
                        <ModelCard
                            key={card.publicID}
                            id={index}
                            title={card.title}
                            alt={card.alt}
                            creator={card.creator}
                            initials={card.initials}
                            previewUrl={card.previewUrl}
                            url={card.url}
                            noAvatar
                        />
                    </Grid>
                ))}

                {/* Shitty paginate spinnger */}
                {resultLimit < props.models.length &&
                    <div ref={commentLoaderRef} className={'flex items-center justify-center w-full'} >
                        <CircularProgress />
                    </div>}

                { /*Show a lil shrug guy when the user has no models */
                    props.models.length == 0 &&
                    (<Grid item xs={12} style={{ textAlign: 'center' }}>
                        <Typography variant="h1">¯\_(ツ)_/¯</Typography>
                        <Typography variant="h4">There's nothing here!</Typography>
                    </Grid>)
                }
            </Grid>

        )
    }

    const path = `/u/${props.User.username}`;
    const tabs = [
        {
            path: '',
            title: 'Models',
            length: props.User.models.length,
            component: () => <ModelTab models={props.User.models} />,
        },
        {
            path: 'Likes',
            title: 'Likes',
            length: props.User.likes.length,
            component: () => <ModelTab models={props.User.likes} />,
        },
        {
            path: 'Lists',
            title: 'Lists',
            length: props.User.lists.length,
            component: () => <ListTab />,
        },
    ];

    return (
        <React.Fragment>
            <CssBaseline />
            <Helmet>
                <title>{`${props.User.username} - Poly Pizza`}</title>
                {props.User.models.length == 0 && <meta name="googlebot" content="noindex"></meta>} {/*//https://developers.google.com/search/docs/advanced/guidelines/prevent-comment-spam?visit_id=637445391803991845-1697095692&rd=1#prevent-untrusted-content-from-showing-in-search */}
                <meta name="description" content={`View ${props.User.models.length} free low poly models by ${props.User.username}. Download FBX, OBJ + GLTF files with no login`} />
                <meta property="og:image" content={props.User.DPURL}></meta>
                <meta property="twitter:image" content={props.User.DPURL}></meta>

            </Helmet>
            <PrimarySearchAppBar loggedIn={props.isLoggedIn} history={props.history} isLoading={props.isLoading} />
            {props.User.migrated === true && <div className={classes.VisDiv} >This profile was migrated from google poly or other web sources and does not have an active user associated with it. If this is you and you'd like to claim the account please contact<a href="mailto:hi@duck.pizza" style={{ color: 'white', marginLeft: '4px' }}> hi@duck.pizza</a></div>}

            {/*User header*/}
            <Paper style={{ width: '100%', height: '12vh', borderRadius: '0px' }} className={classes.HeaderBG} elevation={1}>
                <Grid container item xs={12} lg={10} alignItems="center" style={{ height: '100%', paddingLeft: '24px' }}>
                    <div className={`h-full flex justify-start items-center profileMargin`}>
                        <Avatar alt={props.User.username} className={`!w-24 !h-24 !mr-5`} src={props.User.DPURL} />
                        <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <Typography variant="h4" component="h1" className='m-0 mb-1 font-medium'>{props.User.username}</Typography>
                            {/* <h2 style={{ margin: '0', marginBottom: '4px', fontWeight: 500 }}>{props.User.username}</h2> */}
                            {props.User.Location && <h3 style={{ margin: '0', marginBottom: '4px', fontWeight: 400 }}><LocationOnIcon style={{ height: '0.7em', margin: '-2.5 -5px' }} />{props.User.Location}</h3>}
                            {/* {props.User.models.length > 0 && <h3 style={{ margin: '0', marginBottom: '4px', fontWeight: 400 }}>{props.User.models.length} models</h3>} */}
                        </div>
                    </div>
                </Grid>
            </Paper>

            <Paper style={{ width: '100%', borderRadius: '0px' }} elevation={1}>
                <Grid container direction="row">
                    {/*Model/likes/collections etc panel */}
                    <Grid container direction="column" item xs={12} lg={10}>
                        <div className='profileMargin'>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider', paddingLeft: '24px' }}>
                                <Tabs value={props.location.pathname} indicatorColor="primary" textColor="primary">
                                    {
                                        tabs.map((t, i) => {
                                            const url = i != 0 ? `${path}/${t.path}` : path; //hack for first page with no route
                                            return (
                                                <Tab label={<span><Chip label={t.length} className="mr-[6px]" size="small" />{t.title}</span>}
                                                    key={t.title}
                                                    component={Link}
                                                    to={url}
                                                    value={url}
                                                />
                                            )
                                        })
                                    }
                                </Tabs>
                            </Box>
                        </div>
                    </Grid>
                </Grid>

            </Paper>

            <Grid container className='!flex-row-reverse'>
                {/*Info panel */}
                <Grid container item direction="column" alignItems="stretch" spacing={4} lg={2} className="lg:!mr-1">
                    <Grid item>
                        <Paper className="p-5 mt-8">
                            {props.User.About &&
                                <React.Fragment>
                                    <h4 className={'profilePanelHeader'}>About</h4>
                                    <p className={'m-0 mb-5'}>{props.User.About}</p>
                                </React.Fragment>}
                            <h4 className={'profilePanelHeader'}>Member for</h4>
                            <p className={'m-0 capitalize'}>{twas(new Date(props.User.joinDate)).replace('ago', '')}</p>
                        </Paper>
                    </Grid>

                    {/* Socials */}
                    {socials.length > 0 &&
                        (<Grid item>
                            <Paper className="p-5">
                                <h4 className={'profilePanelHeader'}>Socials</h4>
                                <Grid container item direction="column" alignItems="stretch" justify="center" spacing={2} style={{ margin: 0 }}>
                                    {socials.map((site) => {
                                        return (
                                            <Grid item key={site.siteName} style={{ paddingLeft: 0 }}>
                                                <ButtonBase className={'flex !p-1 !items-center !justify-start !m-0 w-full !rounded-full '} target="_blank" rel="ugc" href={site.link}>
                                                    <Avatar >{<site.icon />}</Avatar>
                                                    <div style={{ marginLeft: '10px', }}>
                                                        <h4 style={{ fontWeight: 500, margin: 0, marginBottom: '5px' }}>{site.handle}</h4>
                                                        <Typography variant="body2" color="textSecondary" style={{ fontWeight: 400 }}>{site.siteName}</Typography>
                                                    </div>
                                                </ButtonBase>
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </Paper>
                        </Grid>)}

                    {/* Show user stats */}
                    {props.User.models.length > 0 &&
                        (<Grid item>
                            <Paper style={{ padding: '20px', }}>
                                <h4 className={'profilePanelHeader'}>Model Stats</h4>
                                <div className="flex-col">
                                    {Object.keys(props.User.Stats).map((statName, i) => {
                                        return <div className="flex p-2 gap-3 items-center text-gray-main">
                                            {statName === 'views' ? <VisibilityIcon className='text-black' /> : <GetAppIcon />}
                                            <Typography variant="body2" className='!my-1 capitalize text-center !text-white-light'>{statName}</Typography>
                                            <Chip label={abbreviate(props.User.Stats[statName])} style={{ marginRight: '6px' }} size="small" />
                                            {/* <Typography variant="body2" className='!my-1 text-center'>{abbreviate(props.User.Stats[statName])}</Typography> */}
                                        </div>
                                    })}
                                </div>
                            </Paper>

                        </Grid>)
                    }
                </Grid>

                {/*Model/likes/collections etc panel */}
                <CarbonAds />
                <Grid container direction="column" item xs={12} lg={10}>
                    <div className="profileMargin">
                        <Switch>
                            {
                                tabs.map((t) => <Route exact path={`${path}/${t.path}`} key={t.title} render={t.component} />)
                            }
                        </Switch>
                    </div>
                </Grid>
            </Grid>
        </React.Fragment >
    );
}

let userCache = {}

Profile.getInitialProps = async ({ req, res, match, history, location, ...ctx }) => {

    //remove trailing dash (stupid seo thing)
    if (match.url.endsWith('/')) return { redirectTo: match.url.substring(0, match.url.length - 1) }

    let User;
    let cachedUser = userCache[match.params.id]; //cache all user reqs in a dict for much nicer tab switching with routing - server render will always miss cache
    if (!cachedUser || req !== undefined) {
        //we need to send through cookies to show models that are unlisted to that user
        //normal client
        let hadError = false;
        let axiosRequest = axios.get(`${process.env.SITE_ROOT}/api/user/${match.params.id}`, { withCredentials: true }).catch((error) => {
            if (error.response.status === 404) hadError = true;
        });

        //send through coookies for SSR
        if (req !== undefined && req.headers.cookie)
            axiosRequest = axios.get(`${process.env.SITE_ROOT}/api/user/${match.params.id}`, { headers: { cookie: req.headers.cookie } }).catch((error) => {
                if (error.response.status === 404) hadError = true;
            });

        //make the actual response
        let userResponse = await axiosRequest;

        if (hadError) return { statusCode: 404 };

        User = userResponse.data;
        userCache[match.params.id] = User;

        //Check if we messed up the case sensitive + redirect to the right one
        if (User.username !== decodeURIComponent(match.params.id)) return { redirectTo: '/u/' + encodeURIComponent(User.username) };
    }
    else {
        User = cachedUser;
    }

    const isLoggedIn = req !== undefined ? ctx.authed : authed;  //if SSR, use authed from context, otherwise use local var;

    return { isLoggedIn, User, darkMode: ctx.darkMode }
}

export default Profile;