import React from 'react';
import SimplePopup from '../components/SimplePopup.tsx';
import Typography from '@material-ui/core/Typography';


const AffiliateInfoPopup = (props) => {
    const popupBody = (
        <div>
            <Typography variant="body1" color="textPrimary" style={{fontWeight: '600'}}>
                Paid picks are hand picked, affordable, high quality assets and resources from around the web that we think will be useful to you. If you purchase any of the assets
                suggested we get a small kick back which helps keep website running. Anyway here's some stupid boiler plate that we have to include!
            </Typography>
            <br/>
            <Typography variant="body1" color="textPrimary">
                Poly Pizza is a participant in the Unity Affiliate Program, an affiliate advertising program that allows participants to earn commissions by advertising
                and linking to Unity properties, which means Poly Pizza may receive a commission if you click a link and purchase something that Poly Pizza have recommended.
                While clicking these links won’t cost you any money, they will help Poly Pizza fund development projects while recommending great assets!
                </Typography>
                <br/>
            <Typography variant="body1" color="textPrimary">
                Poly Pizza is not a corporate affiliate or subsidiary of Unity and this site is not endorsed by Unity. “Unity” is a trademark or registered
                trademark of Unity Technologies or its affiliates in the U.S. (Unity®) and other countries.
            </Typography>
        </div>
    )
    return (
        <SimplePopup
            handleClose={props.handleDialogClose}
            open={props.open}
            closeText="cool"
            title="Paid picks info"
            body={popupBody}
        />
    );
}

export default AffiliateInfoPopup;