import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Puppy from '../components/Puppy';
import { IconButton, Typography } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import { Link } from 'react-router-dom';
import { getRandomInt } from '../util/dooktools';
import useFetch from '../util/usefetch';
import { ad } from '../types/puppy';

interface pickedAd {ad: ad; variant: number; adIndex : number};

const PuppyBlock = (props) => {
    const [puppies, setPuppies] = React.useState<pickedAd[] | null>(null);
    const { data, error } = useFetch<ad[]>('/api/puppies/all');

    useEffect(() => {
        if(!data) return;
        if (data.length < 2) return;
        
        //scuffed code to pick ad from list of ads and then pick copy variant within bounds
        let picked : pickedAd[] = [];
        for (let i = 0; i < 2; i++) {
            let adIndex = getRandomInt(data.length);

            if (i == 1 && adIndex == picked[0].adIndex) adIndex++; //hack to ++ on collision
            if (adIndex > data.length - 1) adIndex = 0; //keep in bounds

            const ad = data[adIndex];
            picked.push({ ad, variant: getRandomInt(ad.Text.length), adIndex });
        }

        setPuppies(picked);

    }, [props.seed, data])

    if (!puppies) return null;

    return (
        <>
            <div className={`${document.adBlockEnabled ? 'pt-[2vh]' : 'py-5'}`} style={{display: 'flex', width: '100%' }}>
                <Puppy ad={puppies[0].ad} variant={puppies[0].variant} />
                {props.two && <Puppy ad={puppies[1].ad} variant={puppies[1].variant} noMobile />}
            </div>
            {document && document.adBlockEnabled && <Typography variant="body2" color="textSecondary" style={{ margin: '1.3rem', marginTop: '10px', textAlign: 'right' }}>
                Paid Picks
                <IconButton size='small' component={Link} to={'/docs/paidPicks'} target="blank"> <HelpIcon style={{ color: '#b9b9b9', marginLeft: '5px', fontSize: '0.9em' }} /> </IconButton>
            </Typography>}
        </>
    )
}


export default PuppyBlock;