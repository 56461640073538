import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import MenuItem from '@material-ui/core/MenuItem';
import Drawer from '@material-ui/core/Drawer';


export default function MainDrawer(props) {
    return (
    <Drawer
        docked={false}
        width={250}
        open={props.isOpen}
        onClose={() => props.setState(false)}>

        <MenuItem id="showFooId">Mail or some shit idk</MenuItem>
        <MenuItem id="showBarId">Mail or some shit idk</MenuItem>
        <Divider />
    
        
    </Drawer>
    );
}
