import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Helmet from 'react-helmet';
import { Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, makeStyles, Paper, TextField, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useForm } from 'react-hook-form';
import SimplePopup from './SimplePopup';
import DisplayAxiosError from '../util/DisplayAxiosError';
import { useSnackbar } from 'notistack';
import MUILink from '@material-ui/core/Link';
import { Link } from "react-router-dom";

const notUse = [
    'Exploit Poly Pizza or its users',
    'Do anything illegal or unethical',
    'Resell content',
    'Replicate the service and offer its content to third parties',
    'Use the API commercially if I or the company I represent earns more than $50,000 a year'
];

const dodo = [
    'Provide appropriate attribution for models used',
    'Clearly mention that models are provided by Poly Pizza and if possible include a link',
    'Consider that the API provides no guarantees of uptime or stability',
];

let APIKEY = '';

const useStyles = makeStyles((theme) => ({
    paper: {
        background: theme.palette.background.default,
    },
    darkText: {
        color: theme.palette.text.disabled,
    }
}));

const APISettings = (props) => {
    const classes = useStyles();

    const [tos, setTos] = useState(false);
    const [makeAppOpen, setmakeAppOpen] = useState(false);
    const [keyOpen, setkeyOpen] = useState(false);
    const [regenKeyWarning, setregenKeyWarning] = useState(false);

    const [loading, setloading] = useState(true);
    const [apps, setapps] = useState([]);
    const [checkState, setcheckState] = useState({})

    const { register, handleSubmit, watch, errors } = useForm();

    function refreshApps() {
        setloading(true);
        axios.get('/api/me/apps').then((apps) => {
            setapps(apps.data);
            setloading(false);
        });
    }
    useEffect(refreshApps, []);


    function RegenKey(AppID) {
        if (window.confirm('This will delete the old API key, are you sure?')) {
            axios.post('/api/me/regenkey', { AppID }).then((res) => {
                APIKEY = res.data;
                setkeyOpen(true);
            }).catch((error) => DisplayAxiosError(error, enqueueSnackbar));
        }

    }

    if (loading) return <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}><CircularProgress /></div>

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setcheckState({
            ...checkState,
            [event.target.name]: event.target.checked,
        });
    };

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const onSubmit = async data => {
        axios.post('/api/me/apps/make', data).then((res) => {
            APIKEY = res.data;
            refreshApps();
            setkeyOpen(true);
            setmakeAppOpen(false);
        }).catch((error) => DisplayAxiosError(error, enqueueSnackbar));
    }

    function makeApp() {
        const isPaid = apps.map(app => app.Plan).includes('Paid');
        if (apps.length === 0 || isPaid) {
            setTos(true);
        } else {
            enqueueSnackbar('You must have a paid plan to make more than one app!', { variant: 'error' });
        }
    }

    return (
        <React.Fragment>
            <Helmet><title>{`API settings - Poly Pizza`}</title></Helmet>
            <Typography variant="body1" className="!my-2">Check out the <MUILink component={Link} to="/docs/api">docs</MUILink> to get started! The API is currently in beta, so please report any issues :)</Typography>
            {apps.length > 0 && <Typography variant="h6" className="!my-3">Your apps:</Typography>}
            {
                apps.map((app, i) => {
                    return (
                        <Paper elevation={2} key={app.AppID} className={classes.paper + ' my-3 p-5'}>
                            <div className="flex flex-row justify-between">
                                <div className="flex flex-col gap-1">
                                    <Typography variant="body2" className={classes.darkText}>ID:{app.AppID}</Typography>
                                    <Typography variant="h4">{app.AppName}</Typography>
                                    <Typography variant="body1">{app.AppDescription}</Typography>
                                    <div className="flex gap-4 justify-start mt-5 ">
                                        <Button variant="outlined" onClick={() => RegenKey(app.AppID)}>Regenerate Key</Button>
                                    </div>
                                </div>
                                <div className="flex flex-col justify-center">
                                    <Typography variant="h6" gutterBottom>{((app.Calls / app.CallLimit) * 100).toFixed(2)}% Used</Typography>
                                    <Typography variant="body1">{app.Plan} Plan</Typography>
                                    <Typography variant="body2" className={classes.darkText}>{app.CallLimit}/month limit</Typography>
                                    <Typography variant="body2" className={classes.darkText}>Limit resets {new Date(app.ResetDate).toDateString()}</Typography>
                                </div>
                            </div>

                        </Paper>
                    )
                })
            }

            <div className="py-3">
                <Button startIcon={<AddIcon />} onClick={makeApp} variant="outlined">Create new App</Button>
            </div>

            <Dialog
                open={makeAppOpen}
                onClose={() => setmakeAppOpen(false)}
                maxWidth="lg"
            >
                <DialogTitle>Create a new app</DialogTitle>
                <DialogContent className="flex flex-col gap-5 w-[40vw] p-8">
                    <TextField
                        name="AppName"
                        variant="outlined"
                        error={errors.AppName !== undefined}
                        helperText={errors.AppName?.message}
                        inputRef={register({ required: 'Required' })}
                        label={"What's your app called?"}
                    />

                    <TextField
                        name="AppDescription"
                        variant="outlined"
                        error={errors.AppDescription !== undefined}
                        helperText={errors.AppDescription?.message}
                        multiline
                        inputRef={register({ required: 'Required', minLength: { value: 20, message: 'Could you provide some more detail?' }, maxLength: { value: 100, message: 'ok thats enough' } })}
                        minRows={3}
                        label={"What does your app do?"}
                    />
                    <Button variant="contained" color="secondary" onClick={handleSubmit(onSubmit)}>Create app</Button>
                </DialogContent>
            </Dialog>

            <Dialog
                open={tos}
                onClose={() => setTos(false)}
            >
                <DialogTitle>API Terms of Service</DialogTitle>
                <DialogContent>
                    <FormGroup>

                        <Typography variant="h6">I agree to:</Typography>
                        {
                            dodo.map((t) => <FormControlLabel key={t} control={<Checkbox checked={checkState[t]} color="primary" onChange={handleChange} name={t} />} label={t} />)
                        }

                        <Typography variant="h6">I agree that I will not use the API to:</Typography>
                        {
                            notUse.map((t) => <FormControlLabel key={t} control={<Checkbox checked={checkState[t]} color="primary" onChange={handleChange} name={t} />} label={t} />)
                        }

                    </FormGroup>

                    {/* <p>As Poly Pizza’s model database is human curated, it is afforded protections under copyright. As such any unacceptable use may be met with legal action and account deletion.</p> */}
                    <p>In the unlikely event that Poly Pizza ceases operating, these terms will not apply during the sunsetting period so that the library can be archived.</p>
                    <Typography variant="body2">(tick all the boxes to continue)</Typography>
                </DialogContent>

                <DialogActions>
                    {
                        Object.keys(checkState).filter(k => checkState[k] === true).length === notUse.length + dodo.length &&
                        <Button onClick={() => { setTos(false); setmakeAppOpen(true) }} variant="contained">ok cool</Button>
                    }
                </DialogActions>
            </Dialog>
            <SimplePopup
                handleClose={() => setkeyOpen(false)}
                open={keyOpen}
                closeText={'Got it'}
                title={'Your API key is:'}
                body={
                    <>
                        <Paper className={classes.paper + ' w-full p-8 my-6 mt-0'}>
                            <code className=" text-white my-5">{APIKEY}</code>
                        </Paper>
                        <Typography variant="body1" align="center">Copy this somewhere, it won't be shown again.</Typography>
                    </>
                }
            ></SimplePopup>

        </React.Fragment>
    );
}

export default APISettings;