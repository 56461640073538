import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import PrimarySearchAppBar from '../components/Appbar'
import Helmet from 'react-helmet';
import Container from '@material-ui/core/Container';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Route } from 'react-router-dom';

//Passthrough component to actually return a 404 response with express and then render the page
const RoutingWrapper = (props) => {
    return (
        <Route
            render={({ staticContext }) => {
                if (staticContext) staticContext.statusCode = 404;
                return <Four04 {...props} />;
            }}
        />
    );
}

//the actual page for the 404
const Four04 = (props) => {
    return (
        <React.Fragment>
            <CssBaseline />

            <Helmet><title>{`Page not found - Poly Pizza`}</title></Helmet>
            <PrimarySearchAppBar loggedIn={props.isLoggedIn} history={props.history} isLoading={props.isLoading} />

            <Container maxWidth="xl" style={{ paddingTop: '7vh' }}>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <Typography variant="h1">¯\_(ツ)_/¯</Typography>
                    <Typography style={{ marginTop: '20px' }} variant="h4">404: Couldn't find that page!</Typography>
                </Grid>
            </Container>

        </React.Fragment >
    );
}

//doesn't matter if we call get inital props on the parent object since all props are just passed through
RoutingWrapper.getInitialProps = async ({ req, res, match, history, location, ...ctx }) => {
    const isLoggedIn = req !== undefined ? ctx.authed : authed;  //if SSR, use authed from context, otherwise use local var;
    return { isLoggedIn }
}

export default RoutingWrapper;