import React from 'react';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MUILink from '@material-ui/core/Link';
import { Link } from 'react-router-dom/';
import IconButton from '@material-ui/core/IconButton';
import UpDoot from '@material-ui/icons/KeyboardArrowUp';
import DownDoot from '@material-ui/icons/KeyboardArrowDown';
import FlagIcon from '@material-ui/icons/Flag';
import DeleteIcon from '@material-ui/icons/Delete';
import twas from 'twas';
import DisplayAxiosError from '../util/DisplayAxiosError'
import AutoLink from './AutoLink';


const useStyles = makeStyles({
    commentBody: {
        fontWeight: 400,
        margin: '.1em 0 .4em',
        whiteSpace: 'pre-wrap',
    },

    nestedContainer: {
        width: 'auto',
    },

    commentAvi: {
        margin: '8px',
        marginBottom: 'auto',
        marginTop: '20px',
    },
    noMargin: {
        margin: 0,
    },

    inlineCommentItem: {
        marginRight: '8px',
    },

    grey: {
        color: '#80868b',
    },

    buttonIcon: {
        fontSize: '1rem',
    },

    transition: {
        transition: '0.2s',
    }
});

export default function Comment(props) {
    var classes = useStyles();
    const dateFormatted = twas(props.date);
    const replyIndentPadding = props.reply ? '60px' : '0px';

    const [visibleState, setVisible] = React.useState(true); //hide comment when deleted 
    const [voteState, setVote] = React.useState(null);
    const [scoreState, setScore] = React.useState(props.score);

    let actionIcon;
    if (props.ownComment) {
        actionIcon = <DeleteIcon className={classes.buttonIcon} />
    }
    else {
        actionIcon = <FlagIcon className={classes.buttonIcon} />
    }

    React.useEffect(() => {
        if (props.isLoggedIn && props.initialVoteState !== null) { //If we have inital vote state
            vote(props.initialVoteState, false);
        }
    }, []);

    function deleteComment() {
        axios.delete(`${props.endpoints.delete}/${props.commentID}`, { withCredentials: true }).then(() => {

            setVisible(false);
            props.enqueueSnackbar("Your comment was deleted, coward", { variant: 'success', });
        }).catch((error) => DisplayAxiosError(error, props.enqueueSnackbar));
    }

    function vote(up, send = true) {
        if (!props.isLoggedIn) {
            props.enqueueSnackbar("You need to be logged in to do that", { variant: 'info', });
            return;
        }

        let newVal = up;
        let localVote = 0;
        var votedOffset = 0;
        if (props.initialVoteState !== null) {
            votedOffset = (props.initialVoteState ? -1 : 1)
        }

        if (up === voteState) {  //Check toggle off
            newVal = null;
            setScore(props.score);
        }

        if (newVal !== null) {
            localVote = (newVal ? 1 : -1)
        }

        setScore(props.score + localVote + votedOffset); //set score number 
        setVote(newVal); //set up and down arrow state

        //easteregg
        if (props.initialVoteState === null && newVal && props.ownComment) props.enqueueSnackbar("Wow that's kinda sad", { variant: 'info', });
        if (props.initialVoteState === null && newVal === false && props.ownComment) props.enqueueSnackbar("Hey don't be so hard on yourself, buddy", { variant: 'info', });

        if (send) {
            axios.post(`/api/comment/${props.commentID}/vote`, { vote: newVal }, { withCredentials: true }).then(() => {
                //guess we don't need to do anything here
            }).catch((error) => DisplayAxiosError(error, props.enqueueSnackbar));
        }
    }

    function ActionClickHandler() {
        if (props.ownComment) {
            props.setDialogState({ open: true, action: deleteComment })
        }
        else {
            window.open(`https://docs.google.com/forms/d/e/1FAIpQLSeV7EFtl4Sj32BwqIN6A4xhY8amFkjeyopfcdegFC3i8oU9Og/viewform?usp=pp_url&entry.1894189372=${props.commentID}`);
        }
    }

    if (visibleState) {
        return (
            <Grid container className={classes.noMargin} direction="row" justify="flex-start" alignItems="center" spacing={2} style={{ flexWrap: 'nowrap', marginLeft: replyIndentPadding }}>

                <Avatar className={classes.commentAvi} src={`https://static.poly.pizza/dp%2F${props.dpSrc}.jpg`}>
                    {(!props.dpSrc && props.username[0] != '[') && props.username[0]}
                </Avatar>

                <Grid item container className={`${classes.noMargin} ${classes.nestedContainer}`} direction="column" justify="flex-start" alignItems="flex-start">

                    {/*Name + date*/}
                    <Grid item container direction="row" alignItems='baseline' className={classes.nestedContainer}>
                        <Typography variant="body1"  className={classes.inlineCommentItem} style={{ fontWeight: 500 }}>
                            <MUILink to={`/u/${props.username}`} component={Link} color="textPrimary"> {props.username} </MUILink>
                        </Typography>
                        <Typography variant="body2" className={`${classes.inlineCommentItem} ${classes.grey}`}>{dateFormatted}</Typography>
                    </Grid>

                    {/* da meat and potatoes */}
                    <Grid item><h3 className={classes.commentBody}><AutoLink text={props.body}/></h3></Grid>

                    {/*Buttons*/}
                    <Grid item container direction="row" alignItems='center' className={`${classes.nestedContainer} ${classes.noMargin}`}>

                        <IconButton size="small" style={{ marginLeft: '-6px' }} aria-label="upvote" onClick={() => vote(true)}>
                            <UpDoot style={{ color: voteState === true ? '#ff4500' : '#80868b' }} className={classes.transition} /></IconButton>

                        <Typography variant="body2">{scoreState}</Typography>

                        <IconButton size="small" className={classes.inlineCommentItem} aria-label="downvote" onClick={() => vote(false)}>
                            <DownDoot style={{ color: voteState === false ? '#7193ff' : '#80868b' }} className={classes.transition} /></IconButton>

                        <MUILink variant="body2" color="textPrimary" className={classes.inlineCommentItem} onClick={() => props.onReply(props.reply ? props.parent : props.commentID, props.username)}>Reply</MUILink>
                        <IconButton size="small" className={`${classes.inlineCommentItem} ${classes.inlineCommentItem}`} onClick={() => ActionClickHandler()}>{actionIcon}</IconButton>
                    </Grid>
                </Grid>
            </Grid>
        );
    } else return null;
}