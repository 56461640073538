import { Tooltip, Typography } from '@material-ui/core';
import React from 'react';
import { Visibility, Favorite, GetApp, ChangeHistory } from '@material-ui/icons';
import abbreviate from 'number-abbreviate';

export default function ModelDetailTitle(props: { model: any; fakeLike?: number; className?: string }) {
    const iconText = 'mb-1 !font-medium !text-[0.9375rem]';
    const icon = '!text-[0.9375rem] mb-[-2.5px] mr-1';

    const stats = [
        {
            name: 'Triangle Count',
            icon: ChangeHistory,
            value: abbreviate(props.model.Tris, 1),
            color: props.model.Tris < 20000 ? 'textSecondary' : 'error',
        },
        {
            name: 'Views',
            icon: Visibility,
            value: props.model.Views,
        },
        {
            name: 'Likes',
            icon: Favorite,
            value: props.model.Likes + (props.fakeLike ?? 0),
        }, {
            name: 'Downloads',
            icon: GetApp,
            value: props.model.Downloads,
        }
    ]
    return (
        <div className="flex justify-start gap-6 items-baseline w-full md:w-1/2 !m-0">
            <Typography className='!text-4xl p-2 pr-0 whitespace-nowrap ' color="textPrimary" variant="h1">{props.model.Title}</Typography>
            {
                stats.map((stat, index) => {
                    return (
                        <Tooltip title={stat.toolTip ?? stat.name} aria-label={stat.name}>
                            <Typography className={iconText + ' whitespace-nowrap'} color={stat.color ?? "textSecondary"} >
                                <stat.icon className={icon} />{stat.value}
                            </Typography>
                        </Tooltip>
                    )
                })
            }
        </div>
    )
}