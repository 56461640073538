import RequireAuth from './components/RequireAuth'
import { asyncComponent } from '@jaredpalmer/after';

import Home from './pages/Home';
import Signup from './pages/Signup';
import Login from './pages/Login';
import Logout from './pages/logout';
import Detail from './pages/Detail';
import Edit from './pages/Edit';
import ScreenShot from './pages/Screenshot';
import Profile from './pages/Profile';
import Settings from './pages/Settings.tsx';
import Forgot from './pages/ForgotPassword';
import Reset from './pages/ResetPassword';
import Search from './pages/Search';
// import List from './pages/ListView';
import ListGen from './pages/Listgen';
import ListCredit from './pages/ListCredit';
import Four04 from './pages/404';
import Docs from './pages/Docs';
import GetRandom from './components/getRandom';
import Adman from './pages/adMan';
// import BundleView from './pages/bundle';
import BundleList from './pages/BundleList';
// import PostEdit from './pages/PostEdit';
// import PostView from './pages/PostView';

export default [
  {
    path: '/',
    exact: true,
    component: Home,
  },
  {
    path: '/login',
    exact: true,
    component: RequireAuth(Login, false),
  },
  {
    path: '/logout',
    exact: true,
    component: Logout,
  },
  {
    path: '/signup',
    exact: true,
    component: RequireAuth(Signup, false),
  },
  {
    path: '/forgot',
    exact: true,
    component: RequireAuth(Forgot, false),
  },
  {
    path: '/reset',
    exact: true,
    component: RequireAuth(Reset, false),
  },
  {
    path: '/m/:id',
    exact: true,
    component: Detail,
  },
  {
    path: '/m/:id/fs',
    exact: true,
    component: ScreenShot,
  },
  {
    path: '/m/:id/three',
    exact: true,
    component: asyncComponent({
      loader: () => { return import('./pages/ThreeServerPreview') },
    })
  },
  {
    path: '/edit/:id',
    exact: true,
    component: RequireAuth(Edit, true),
  },
  {
    path: '/u/:id/',
    // exact: true,
    component: Profile,
  },
  {
    path: '/l/:id/',
    exact: true,
    // component: List,
    component: asyncComponent({
      loader: () => { return import('./pages/ListView') },
    })
  },
  {
    path: '/ai',
    exact: true,
    component: RequireAuth(ListGen, true),
  },
  {
    path: '/bundle/:id/',
    exact: true,
    // component: BundleView,
    component: asyncComponent({
      loader: () => { return import('./pages/bundle') },
    })
  },
  {
    path: '/bundles',
    exact: true,
    component: BundleList,
  },
  {
    path: '/l/:id/credits',
    exact: true,
    component: ListCredit,
  },
  {
    path: '/settings',
    // exact: true,
    component: RequireAuth(Settings, true),
  },
  {
    path: '/search',
    component: Search,
  },
  {
    path: '/explore',
    component: Search,
  },
  {
    path: '/support',
    exact: true,
    component: asyncComponent({
      loader: () => { return import('./pages/Support') }, //contains stripe shit
    })
  },
  {
    path: '/dook/approve',
    exact: true,
    component: asyncComponent({
      loader: () => { return import('./pages/Approve') },
    })
  },
  {
    path: '/posts/:id/edit',
    exact: true,
    // component: RequireAuth(PostEdit, true),
    component: asyncComponent({
      loader: () => { return import('./pages/PostEdit') },
    })
  },
  {
    path: '/posts/:id',
    exact: true,
    // component: PostView,
    component: asyncComponent({
      loader: () => { return import('./pages/PostView') },
    })
  },
  {
    path: '/dook/adman',
    component: Adman,
  },
  {
    path: '/random',
    component: GetRandom,
  },
  {
    path: '/docs/api/:version',
    exact: true,
    // component: Swag,
    component: asyncComponent({
      loader: () => { return import('./components/Swagger') },
      Placeholder: () => <div>...LOADING...</div>,
    }),
  },
  {
    path: '/docs', //for reading markdown files
    component: Docs,
  },
  {
    //404 page - make sure this is always last
    component: Four04
  }
];