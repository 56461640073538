import { Button, Fab, makeStyles, TextField } from '@material-ui/core';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { green } from '@material-ui/core/colors';
import clsx from 'clsx';
import CheckIcon from '@material-ui/icons/Check';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    fabProgress: {
        color: green[500],
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    buttonMargin: {
        marginLeft: '10px', marginTop: '15px', marginBottom: '8px'
    }
}));

function QuickCreateList(props: { OnCreated: () => void; label?: string, style?: object }) {
    const classes = useStyles();
    const [listName, setlistName] = useState('');

    //button loader shit
    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const buttonClassname = clsx({
        [classes.buttonSuccess]: success,
    });

    useEffect(() => {setSuccess(false);}, [listName]); //clear success on new name so more lists can me made if u want ig


    function MakeList() {
        if(loading || success) return;
        setLoading(true);
        axios.post('/api/list/make', {name: listName}).then(() => {
            setLoading(false);
            setSuccess(true);
            props.OnCreated();
        });
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', ...props.style }}>
            <TextField variant="outlined" label={props.label ?? "New List Name"} value={listName} onChange={(e) => setlistName(e.target.value)}></TextField>
            <div className={classes.root}>
                <div className={classes.wrapper}>
                    <Fab
                        type="submit"
                        color="primary"
                        size="small"
                        aria-label="send"
                        className={[buttonClassname, classes.buttonMargin].join(' ')}
                        onClick={MakeList}
                    >
                        {success ? <CheckIcon /> : <ArrowForwardIcon />}
                    </Fab>
                    {loading && <CircularProgress size={53} className={[classes.fabProgress, classes.buttonMargin].join(' ')} />}
                </div>
            </div>
        </div>
    )
}

export default QuickCreateList;