import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import LocalCafeIcon from '@material-ui/icons/LocalCafe';
import BrushIcon from '@material-ui/icons/Brush';
import LanguageIcon from '@material-ui/icons/Language';
import InstagramIcon from '@material-ui/icons/Instagram';
import Icons from '../util/icons';
import YouTubeIcon from '@material-ui/icons/YouTube';
import MailIcon from '@material-ui/icons/Mail';

const IconMap = {
    'Twitter': TwitterIcon,
    'Facebook': FacebookIcon,
    'Instagram' : InstagramIcon,
    'Pateron': Icons.PateronIcon,
    'Kofi': LocalCafeIcon,
    'Discord': Icons.DiscordIcon,
    'ArtStation': BrushIcon,
    'Website': LanguageIcon,
    'Youtube': YouTubeIcon,
    'Email': MailIcon,
}

const URLMap = {
    'Twitter': 'https://twitter.com/',
    'Facebook': 'https://facebook.com/',
    'Instagram': 'https://instagram.com/',
    'Pateron': 'https://patreon.com/',
    'Kofi': 'https://ko-fi.com/',
    'Discord': 'https://discord.gg/',
    'ArtStation': 'https://www.artstation.com/',
    'Website': '',
    'Youtube': 'https://www.youtube.com/',
    'Email' : 'mailto:'
}

//kind of a hack to push socials to array which react kinda works a bit better with imo
function Unwrap(SocialsObj) {
    let socials = [];
    for (const [key, value] of Object.entries(SocialsObj)) {
        socials.push({ siteName: key, icon: IconMap[key], handle: value, link: URLMap[key] + value });
    }
    return socials;
}

export default {IconMap, URLMap, Unwrap};