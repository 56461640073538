import React, { Component } from 'react';
// import './css/carbon.css';

class CarbonAds extends Component {
    constructor(props) {
        super(props)
        this.state = {
            customClass: this.props.customClass,
            carbonUrl: '//cdn.carbonads.com/carbon.js?serve=CESDL53Y&placement=polypizza',
            aSync: true,
            key: 123,
        };
    }

    componentDidMount() {
        //if(document.adBlockEnabled === true) return; //don't bother if blocking

        const carbon_wrapper = document.querySelector(`.${this.state.carbonWrapper ? this.state.carbonWrapper : 'carbon-adds-wrapper'}`);
        const script = document.createElement("script");
        script.src = this.state.carbonUrl;
        script.async = this.state.aSync;
        script.id = "_carbonads_js"
        carbon_wrapper.appendChild(script);
    }

    render() {
        return (
            <div className='carbon-adds-wrapper' style={this.props.style}></div>
        );
    }
}

export default CarbonAds;