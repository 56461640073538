const Catagories = [
    'Food & Drink', //0
    'Clutter', //1
    'Weapons', //2
    'Transport', //3
    'Furniture & Decor', //4
    'Objects', //5
    'Nature', //6
    'Animals', //7
    'Buildings', //architecture //8
    'People & Characters', //9
    'Scenes & Levels', //10
    'Other' //11
];

const URLCatagories = [
    'Food-and-Drink',
    'Clutter',
    'Weapons',
    'Transport',
    'Furniture-and-Decor',
    'Objects',
    'Nature',
    'Animals',
    'Buildings',
    'People-and-Characters',
    'Scenes-and-Levels',
    'Other'
];

//todo
const SubCats = [
    'Fruit, Bottles, Cans, Beer,',
    'Rubbish, Trinkets',
    'Guns, Swords, AK-47s, Rifles, Knives',
    'Cars, Trains, Jets, Airplanes, Helicopters, Rockets, Space ships',
    'Chairs, Tables, Desks, Couches, Beds',
    'Stuff',
    'Trees, Flowers, Plants, Grass',
    'Dogs, Cats, Birds, Wolves',
    'Offices, Houses, Interiors',
    'Animated + Rigged Women, Men',
    'Levels, Environments',
    'everything else i guess lmao'
];


const Licences = [
    'CC-BY 3.0',
    'CC0 1.0',
];

const LicencesExpanded = {
    'CC-BY 3.0': 'Creative Commons Attribution',
    'CC0 1.0': 'Public Domain (CC0)',
};

const LicenceLinks = {
    'CC-BY 3.0': 'https://creativecommons.org/licenses/by/3.0/',
    'CC0 1.0': 'https://creativecommons.org/publicdomain/zero/1.0/',
};

const catFacts = [
    'Cats are the only mammals who can\'t taste sweetness. Poor fucks',
    'Cats can spend up to a third of their time awake licking their asshole',
    'Cats are acutally lactose intolerant',
    'Did you know that catnip is basically cat LSD?',
    'Cats only meow at humans, not other cats',
    'Cats really like human ear wax for some reason. kinda weird ngl',
    'Cats won\'t drink water from the same place they eat',
    'Cats can drink sea water',
    'Did you know that in the United States of America alone, there are a lot of cats',
    'Genetically speaking, cats are pretty much lil tigers',
    'In ancient egypt killing a cat was punishable by death',
    'As I type these out, my housemate\'s dog is giving me puppy dog eyes because she wants to play. A cat would never do this because they do not give a fuck about you',
    'In 1888 more than 300,000 mummified cats were found in an egyptian cemetary and shipped to farmers in England to be used a fertilizer',
    'Cats are really good at staring contests',
    'Due to their physiology cats can survive a fall from outer space. Haha nah just kidding I made that one up',
    'On average, cats are 100% assholes',
    'Cats only sweat through their paws. I wonder if who ever wrote that fact actually misheard someone saying "pores"',
    'A cat\'s nose has a unique pattern of ridges',
    'On average, cats spend two thirds of every day sleeping and use one third of their awake time cleaning themselves. This adds up to them three thirds idiots because they could have been spending that time playing vibeo games',
    'Did you know that cats will feast upon the blood of the innocent if given a chance?'
]

const Horny = [
    'boob',
    'boobs',
    'pussy',
    'ass',
    'dick',
    'cock',
    'nude',
    'anal',
    'sex',
    'futa',
];

const suggestedSearches = [
    // "car",
    // "tree",
    // "gun",
    // "character",
    "house",
    "human",
    "table",
    "cat",
    "man",
    "city",
    "dog",
    "pizza",
    "person",
    "robot",
    "sword",
    // "building",
    "chair",
    // "bed",
    "rock",
    "computer",
    "grass",
    // "door",
    // "desk",
    "plant",
    "space",
    "room",
    "fish",
    "girl",
    "ship",
    "tv",
    "plane",
    "boat",
    "lamp",
    "knife",
    "hand",
    "monster",
    "tank",
    "couch",
    "road",
    "zombie",
    "dragon",
    "box",
    "pokemon",
    "soldier",
    "food",
    "hat",
    "pistol",
    "mountain",
];

export { Catagories, Licences, catFacts, LicencesExpanded, URLCatagories, SubCats, Horny, LicenceLinks, suggestedSearches };