import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

interface ConfirmProps {
    setOpen: Function,
    action: any,
    open: boolean,
    title: String,
    content: String,
    confirmText?: String,
    destructive?: boolean
  }

const SimpleConfirm = (props : ConfirmProps) => {

    const Close = () => {
        props.setOpen(false);
    };

    const Continue = () => {
        props.action();
        props.setOpen(false);
    };


    return (
        <Dialog
            open={props.open}
            onClose={Close}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{props.content}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={Close} color="textPrimary">
                    Nah
          </Button>
                <Button onClick={Continue} className={props.destructive && "!bg-red-main !text-white"} color={"textPrimary"} autoFocus>
                    {props.confirmText !== undefined ? props.confirmText : 'Yeah'}
          </Button>
            </DialogActions>
        </Dialog>);
}

export default SimpleConfirm;