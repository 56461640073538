import React, { useEffect } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import PrimarySearchAppBar from '../components/Appbar'
import Helmet from 'react-helmet';
import Container from '@material-ui/core/Container';
import axios from 'axios';
import Markdown from 'markdown-to-jsx';
import GetURL from '../util/GetFuzzyLocation'
import { ScrollbarContext } from 'react-scrollbars-custom';

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

const RenderMarkdown = (props) => {

    //Scroll to top on load
    const scrollbar = React.useContext(ScrollbarContext);
    //@ts-ignore
    useEffect(() => scrollbar.parentScrollbar.scrollToTop(), []);
    
    return (
        <React.Fragment>
            <CssBaseline />
            <Helmet>
                <title>{`${capitalize(props.title)} - Poly Pizza`}</title> 
                {/* <link rel="stylesheet" href="/css/markdown.css" /> */}
            </Helmet>
            <PrimarySearchAppBar loggedIn={props.isLoggedIn} history={props.history} isLoading={props.isLoading} />

            <Container style={{ paddingTop: '50px' }} className="md">
                <Markdown>{props.md}</Markdown>
            </Container>
        </React.Fragment>
    );
}

RenderMarkdown.getInitialProps = async ({ req, res, match, history, location, ...ctx }) => {

    const doc = GetURL(req, location);

    const newMDURL = `${process.env.SITE_ROOT}/md/${doc}.md`;

    let document = null;
    try{
        document = await axios.get(newMDURL);
    }
    catch(e){
       return {statusCode: 404 }
    }


    //@ts-ignore
    const isLoggedIn = req !== undefined ? ctx.authed : authed;  //if SSR, use authed from context, otherwise use local var;
    return { isLoggedIn, md: document.data, title: doc }
}

export default RenderMarkdown;