import React, { useRef, useState } from 'react';
import axios from 'axios'
import {
    Typography, Paper, Grid, CssBaseline, Divider, Button, IconButton,
    Avatar, Menu, MenuItem, Chip, TextField, Collapse, Container, CircularProgress,
    Tooltip, Select,
} from '@material-ui/core';
import PrimarySearchAppBar from '../components/Appbar'
import ModelCard from '../components/ModelCard'
import FavoriteIcon from '@material-ui/icons/Favorite';
import CreateIcon from '@material-ui/icons/Create';
import FlagIcon from '@material-ui/icons/Flag';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import GetAppIcon from '@material-ui/icons/GetApp';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import InfoIcon from '@material-ui/icons/Info';
import { usePopupState, bindTrigger, bindMenu, } from 'material-ui-popup-state/hooks'
import TagIcon from '@material-ui/icons/LocalOffer';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import AutoRotateIcon from '@material-ui/icons/Rotate90DegreesCcw';
import HelpIcon from '@material-ui/icons/Help';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
//import detailStyles from './styles/detailStyle'
import Helmet from 'react-helmet';
import DeleteDialog from '../components/DeleteDialog';
import { Link } from "react-router-dom";
import MUILink from '@material-ui/core/Link'
import Socials from '../util/Socials';
import AttributionPopup from '../components/Attribuition';
import ReceiptIcon from '@material-ui/icons/Receipt';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import { makeStyles } from '@material-ui/core/styles';
import CreateGradientString from '../util/CreateGradientString';
import AffiliateInfoPopup from '../components/AffiliateInfoPopup';
import CallToAction from '../components/CallToHelp';
import Footer from '../components/Footer';
import DonateBar from '../components/DonateBar';
import ModelViewerSetup from '../util/modelViewerInit'
import { LicencesExpanded, Licences, Catagories, URLCatagories } from '../util/HardCodedLists';
import CarbonAds from '../components/carbonAds';
import SimplePopup from '../components/SimplePopup.tsx';
import { ScrollbarContext } from 'react-scrollbars-custom';
import ListSelectDialog from '../components/ListSelectDialog';
import AutoLink from '../components/AutoLink';
import CameraEnhanceIcon from '@material-ui/icons/CameraEnhance';
import PuppyBlock from '../components/PuppyBlock';
import { getRandomInt } from '../util/dooktools';
import getModelHash from '../util/modelHash';
import CreatorInfo from '../components/CreatorInfo';
import CommentSection from '../components/CommentSection';
import ModelDetailTitle from '../components/ModelDetailTitle';
import PizzaViewer from '../components/Pizza-Viewer';
import '../css/detailStyles.css';
import ModelCard2 from '../components/ModelCard2';
import SquareAd from '../components/Adsense/SquareAd';
import BannerAd from '../components/Adsense/BannerAd';
import SendToDialog from '../components/SendTo';
import LaunchIcon from '@material-ui/icons/Launch';
import SmolListCard from '../components/SmolListCard';

let adCount = 0;
let isSupporter = false;

const Detail = (props) => {

    // const useStyles = makeStyles(theme => ({      

    //     modelTitle: {
    //     flexGrow: 0,
    //         maxWidth: '100%',
    //         flexBasis: '100%',

    //         [theme.breakpoints.up('sm')]: {
    //             maxWidth: 'fit-content',
    //         }
    //     },
    // }));

    //const [classes, setClasses] = React.useState(useStyles(props));
    //if(s === null) s = useStyles(props);
    // const classes = useStyles(props);

    //formatted upload date
    const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
    var uploadDateString = new Date(props.model.UploadDate).toLocaleDateString("en-US", options);
    uploadDateString = uploadDateString.substring(uploadDateString.indexOf(",") + 1);

    const downloadPopupState = usePopupState({ variant: 'popover', popupId: 'downloadMenu' })
    const overflowPopupState = usePopupState({ variant: 'popover', popupId: 'overflowMenu' })

    const [attributionPopupState, setAttributionState] = React.useState(false);

    const [popupIsOpen, setPopupOpen] = React.useState(false); //popupup for paid picks info

    const [adblockNotice, setadblockNotice] = React.useState(false);
    const [downloadBlockNotice, setdownloadBlockNotice] = React.useState(false);

    const [listSelectOpen, setListSelectOpen] = React.useState(false);

    const [AR, setAR] = React.useState(false);

    const { register, handleSubmit, watch, errors, clearErrors } = useForm({ mode: 'onChange' });
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const Download = (ext) => {
        // if (props.model.SupporterOnly && !isSupporter) {
        //     enqueueSnackbar("You need to be a supporter in to do that", { variant: 'info', });
        //     return;
        // }

        //Chuck up popup
        const downloads = localStorage.getItem('downloadCount') || 0;
        if (downloads == 8) {
            document.adBlockEnabled ? setadblockNotice(true) : setdownloadBlockNotice(true);
            localStorage.setItem('downloadCount', parseInt(downloads) + 1);
            return;
        }
        localStorage.setItem('downloadCount', parseInt(downloads) + 1);

        //First send captcha to get model download password from /model/:id/download/:ext/
        grecaptcha.ready(function () {
            grecaptcha.execute('6Lc_LAcaAAAAAJ8RT1-vgmA-GsTDH0KJBZ_toZZV', { action: 'download' }).then(async function (token) {
                await axios.post(`/api/model/${props.model.PublicID}/download/${ext}`, { token }).then(function (response) {
                    if (response.data.error) {
                        enqueueSnackbar(response.data.error, { variant: 'error', });
                    }
                    else {
                        var url = `${process.env.SITE_ROOT}/api/model/${props.model.PublicID}/download/${ext}/${response.data}`;
                        if (typeof window !== "undefined") { //client only hack
                            window.location.assign(url);
                        }
                    }
                }).catch((error) => {
                    if (error.response) {
                        enqueueSnackbar(error.response.data.error, { variant: 'error', });
                    }
                    else {
                        enqueueSnackbar("Server error", { variant: 'error', });
                    }
                })
            })
        });

        downloadPopupState.close();
        setAttributionState(true);
        window?.plausible('Download', { props: { model: props.model.Title, ext, loggedIn: props.isLoggedIn } });
    }

    //Interactions (Like state + canEdit)
    const [likeState, setLikeState] = React.useState(props.interactions.LikedByUser);
    const [fakeLike, setFakeLike] = React.useState(0);
    const [canEdit, setCanEdit] = React.useState(props.interactions.CanEdit);

    async function ToggleLikeState() {
        if (!props.isLoggedIn) {
            enqueueSnackbar("You need to be logged in to do that", {
                variant: 'info',
            });
            return;
        }

        sendLike(!likeState);
        setLikeState(!likeState);

        if (!props.interactions.LikedByUser) {
            setFakeLike(!likeState ? 1 : 0); //hasn't been liked by user 
        } else {
            setFakeLike(!likeState ? 0 : -1); //like count already includes like by user so their unlike/like doesn't add to the count
        };
    }

    async function sendLike(state) {
        await axios.post(`${process.env.SITE_ROOT}/api/model/${props.model.PublicID}/like`, { liked: state });
    }

    async function openListDialog() {
        if (!props.isLoggedIn) {
            enqueueSnackbar("You need to be logged in to do that", { variant: 'info', });
            return;
        }
        setListSelectOpen(true);
        window?.plausible("Open List Dialog", { props: { model: props.model.Title } });
    }

    //observe comment spinner to lazy load comments
    const suggestedTrigger = useRef();
    const [showBottomSuggested, setshowBottomSuggested] = useState();
    React.useEffect(() => {
        var observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setshowBottomSuggested(true);
                }
            }, { root: null, rootMargin: "0px", threshold: 0 });

        if (suggestedTrigger.current) {
            observer.observe(suggestedTrigger.current);
        }

        return () => {
            if (suggestedTrigger.current) {
                observer.unobserve(suggestedTrigger.current);
                setshowBottomSuggested(false);
            }
        }
    }, [props.model.PublicID]);


    //Page load Check for AR + re render ad
    const [adKey, setadKey] = React.useState(234);

    React.useEffect(() => {
        setInterval(() => {
            if (adCount < 5) {
                setadKey(Math.random() * 10000);
                adCount++;
            }
        }, 1000 * 35);

        // if (authed && props.model.SupporterOnly) {
        //     axios.get('/api/me/donated').then((resp) => {
        //         isSupporter = resp.data.donated;
        //     });
        // }
    }, []);

    React.useEffect(() => adCount = 0, [props.model.PublicID]);

    //Get suggested models if they don't exist
    React.useEffect(() => {
        if (props.suggested[0] === null) GetSuggested();
    }, [props.model.PublicID]);

    const [affiliateLinks, setAffiliateLinks] = React.useState([]);
    const [catIndex, setCatIndex] = React.useState(0);
    const [puppies, setPuppies] = React.useState(null);
    const [adIndex, setAdindex] = React.useState(0);
    const [bundle, setBundles] = useState(null);

    //Initial state - refreshes on page change
    const scrollbar = React.useContext(ScrollbarContext);
    React.useEffect(() => {
        //initial interactions state for CSR
        if (authed) {
            const setInteractions = (d) => {
                setLikeState(d.data.LikedByUser);
                setCanEdit(d.data.CanEdit);
            }
            axios.get(`/api/model/${props.model.PublicID}/details/interactions`, { withCredentials: true }).then(setInteractions);
        }


        axios.get(`/api/model/${props.model.PublicID}/gettoken`); //shity hack for page view

        //get new affiliate links
        axios.get(`/api/search/assets/` + encodeURIComponent(props.model.Title))
            .then((response) => {
                setAffiliateLinks(response.data);
            })
            .catch((e) => console.error(e.message));

        scrollbar.parentScrollbar.scrollToTop();

        //Get cat index for explore URL
        setCatIndex(Catagories.findIndex((c) => c === props.model.Category))

        //get ad for attribution popup + preload for scrolling down
        if (puppies === null) {
            axios.get(`/api/puppies/all`).then((res) => setPuppies(res.data))
        }
        else {
            setAdindex(getRandomInt(puppies.length));
        }

        //Reset suggested raw
        setSuggestedRaw(props.suggested);

        //Get model bundles
        axios.get(`/api/model/${props.model.PublicID}/bundles`).then((r) => {
            setBundles(r.data);
        });

        //Clean up on unmount
        return () => {
            setFakeLike(0);
        }

    }, [props.model.PublicID]);

    function GetSuggested() {
        axios.get(`/api/model/${props.model.PublicID}/suggested`).then((r) => {
            setSuggestedRaw(r.data)
        });
    }

    //Split suggested into chunks
    const [suggestedRaw, setSuggestedRaw] = React.useState(props.suggested);
    const [suggestedChunks, setSuggestedChunks] = React.useState([]);
    React.useEffect(() => {
        let chunks = [];
        var i, j, temparray, chunk = 20;
        const bottomSuggested = suggestedRaw.slice(4, suggestedRaw.length)
        for (i = 0, j = bottomSuggested.length; i < j; i += chunk) {
            temparray = bottomSuggested.slice(i, i + chunk);
            if (i === 0) temparray = bottomSuggested.slice(i, i + chunk);
            chunks.push(temparray);
        }
        setSuggestedChunks(chunks);
    }, [suggestedRaw]);

    //Send to modal state
    const [sendToOpen, setSendToOpen] = useState(false);

    return (
        <div>
            <CssBaseline />
            <Helmet>
                <title>{`${props.model.Title} - Free 3D Model By ${props.model.Creator.Username} - Poly Pizza`}</title>
                <meta name="description" content={`Download Low poly ${props.model.Title} models for free. Optimised for Unity, Unreal, Godot and AR/VR. No login required.`} />
                <meta property="og:image" content={`https://static.poly.pizza/${props.model.ResourceID}.jpg`}></meta>
                <meta property="twitter:image" content={`https://static.poly.pizza/${props.model.ResourceID}.jpg`}></meta>

                {/* Twitter 3D embed https://modelviewer.dev/examples/twitter/generator.html */}
                <meta name="twitter:card" content="player" />
                <meta name="twitter:site" content="modelviewer" />
                <meta name="twitter:player:width" content="480" />
                <meta name="twitter:player:height" content="480" />
                <meta name="twitter:player" content={`https://modelviewer.dev/examples/twitter/player.html?src=https://static.poly.pizza/${props.model.ResourceID}.glb&poster=https://static.poly.pizza/${props.model.ResourceID}.jpg&cameraOrbit=${props.model.Orbit.theta}rad%20${props.model.Orbit.phi}rad%20${props.model.Orbit.radius}m`} />
                <meta property="og:title" content={`${props.model.Title} - Free Model By ${props.model.Creator.Username}`} />
                <meta name="og:description" content={`Download Low poly ${props.model.Title} models for free. No login required. Optimised for Unity, Unreal and Godot`} />
            </Helmet>
            <PrimarySearchAppBar loggedIn={props.isLoggedIn} key={props.authed} history={props.history} isLoading={props.isLoading} />

            {props.model.Visibility === 'Pending' && <div className={'VisDiv'} >This model is unlisted pending admin approval. Check back soon! </div>}
            {/* {props.model.SupporterOnly === true && <div className={'VisDiv'} >This model can only be downloaded by supporters. <MUILink to='/support' className="pl-1" component={Link}> Become a supporter for just $3</MUILink></div>} */}
            <Grid container direction="row">
                <Grid container direction="column" item xs={12} lg={10} >
                    {/*Model Viewer*/}
                    <PizzaViewer model={props.model} className='h-[80vh]' elevation={3} />

                    {/*Info panel*/}
                    <Grid item>
                        <div className={'p-[2vw] lg:p-[8vw] lg:py-0'}>
                            {/*Title*/}
                            <Grid container className={'py-3'}>
                                <ModelDetailTitle model={props.model} fakeLike={fakeLike} />

                                {/*Title Buttons*/}
                                <Grid item container xs={12} md={6} direction="row" className={'!justify-start lg:!justify-end'}
                                    alignItems="center" spacing={2}>
                                    <Grid item>
                                        <Button size="large"
                                            className={'uppercase'}
                                            variant="text"
                                            color="textPrimary"
                                            onClick={ToggleLikeState}
                                            startIcon={<FavoriteIcon color={likeState ? "primary" : "disabled"} style={{ transition: '0.4s' }} />}>
                                            Like</Button>
                                    </Grid>
                                    <Grid item>
                                        <Button size="large"
                                            className={'uppercase'}
                                            variant="text"
                                            color="textPrimary"
                                            startIcon={<PlaylistAddIcon color="action" />}
                                            onClick={openListDialog}
                                        >
                                            List</Button>
                                    </Grid>
                                    <Grid item>
                                        <Button size="large"
                                            className={'uppercase'}
                                            variant="text"
                                            color="textPrimary"
                                            {...bindTrigger(downloadPopupState)}
                                            startIcon={<GetAppIcon color="action" />}>
                                            Download</Button>
                                    </Grid>

                                    {/*<Grid item><Button size="large" className={'uppercase'} variant="text" color="primary" className={'uppercase'} startIcon={<ShareIcon color="action" />}>Share</Button></Grid>*/}
                                    <Grid item className={'hideOnMobile'}><IconButton aria-label="more" {...bindTrigger(overflowPopupState)}><MoreVertIcon className={`${'text-gray-button'}`} /></IconButton></Grid>
                                </Grid>
                            </Grid>
                            <Divider className={'!ml-3 !m-0'} spacing={2} variant="middle" />

                            <div className='flex flex-col md:flex-row justify-between items-start'>

                                <div className='flex flex-col'>
                                    {/*Creator info*/}
                                    <CreatorInfo Creator={props.model.Creator} />

                                    {/*Tags + meta*/}
                                    <Grid container className={'!m-0'} direction="column" justify="flex-start" alignItems="flex-start" spacing={2}>
                                        <Grid item><Typography variant="body1" style={{ whiteSpace: 'pre-line' }}><AutoLink text={props.model.Description}></AutoLink></Typography></Grid>
                                        <Grid item>
                                            <div style={{ display: 'flex', alignItems: 'flex-end', flexWrap: 'wrap' }}>
                                                <TagIcon className={'text-gray-button'} style={{ margin: 'auto 0', fontSize: "1.3rem" }} />
                                                <Chip size="small" label="Low Poly" color="secondary" style={{ marginLeft: '7px' }} component={Link} to={`/explore`} clickable />
                                                <Chip size="small" label={props.model.Category} color="secondary" style={{ marginLeft: '7px' }} component={Link} to={`/explore/${URLCatagories[catIndex]}`} clickable />
                                                {props.model.Animated && <Chip size="small" label="Animated" color="secondary" style={{ marginLeft: '7px' }} component={Link} to={`/explore?anim=1`} clickable />}
                                                {props.model.Tags.map((tag) => {
                                                    return <Chip size="small" key={tag} label={`#${tag}`} className={'ml-2 mt-1 !capitalize'} component={Link} to={`/search/${tag}`} clickable />
                                                })}
                                            </div>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body1" color="textSecondary" >{uploadDateString} • {props.model.Type.toUpperCase()}/GLTF format • {LicencesExpanded[props.model.Licence]}
                                                <MUILink
                                                    href={`https://creativecommons.org/${props.model.Licence === Licences[0] ? 'licenses/by/3.0/' : 'publicdomain/zero/1.0/'}`}
                                                    color="textSecondary"
                                                    target="blank"
                                                    style={{ marginLeft: '3px' }}
                                                >

                                                    <InfoIcon style={{ fontSize: '0.8em' }} />
                                                </MUILink>
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                </div>

                                {/* Included in lists card */}
                                {<SmolListCard bundles={bundle} />}
                            </div>

                            <Divider className={'!ml-3 !m-0 !mt-3'} spacing={2} variant="middle" />

                            {/*Comments*/}
                            <Grid container className={'!m-0'} direction="column" justify="flex-start" alignItems="flex-start" spacing={1} ref={suggestedTrigger}>
                                <Grid item><h3 className={'!font-normal'} id="commentCount">{props.model.CommentCount} Comments</h3></Grid>
                            </Grid>
                            <div className='w-full md:w-1/2'>
                                <CommentSection
                                    className='w-full'
                                    isLoggedIn={props.isLoggedIn}
                                    rootID={props.model.PublicID}
                                    visible={true}
                                />
                            </div>
                        </div>
                    </Grid>
                </Grid>

                {/*right Related panel*/}
                <Grid container item direction="row" justify="center" spacing={4} xs={false} lg={2} className={`!m-0 hideOnMobile`} id="RightSuggested">
                    {suggestedRaw.map((card, index) => {
                        {/* const suggestedShow = typeof window !== "undefined" && document.adBlockEnabled ? 4 : 3; */ }
                        const suggestedShow = 4;
                        {/* const suggestedShow = 3; */ }
                        return (index < suggestedShow &&
                            <Grid item key={card?.previewUrl ?? index} xs={false} lg={12} >
                                {card == null ?
                                    <ModelCard id={index} skeleton /> :
                                    <ModelCard
                                        id={card.id}
                                        title={card.title}
                                        alt={card.alt}
                                        creator={card.creator}
                                        initials={card.initials}
                                        previewUrl={card.previewUrl}
                                        url={card.url}
                                    />}
                            </Grid>
                        )
                    })}
                    {/* <SquareAd key={props.model.PublicID + adKey} /> */}
                    {/* <div className='flex items-center justify-center'>
                        <div className='w-[250px] h-[250px] bg-orange-main'></div>
                    </div> */}
                </Grid>
            </Grid>

            <CarbonAds key={props.model.PublicID + adKey} />

            {/* <div className='flex items-center justify-center'>
                <div className='w-[728px] h-[90px] bg-orange-main'></div>
            </div> */}

            {showBottomSuggested &&
                <Container maxWidth="xl">
                    {affiliateLinks.length > 0 &&
                        <React.Fragment>
                            <Typography variant="h1" component="h3" className={'!text-4xl'} style={{ margin: '20px 0' }}> Paid Picks
                                <IconButton size='small' onClick={() => setPopupOpen(true)}> <HelpIcon style={{ color: '#b9b9b9', marginLeft: '5px', fontSize: '1.1em' }} /> </IconButton>
                            </Typography>
                            <Grid container spacing={6} style={{ marginBottom: '2vh' }}>
                                {affiliateLinks.slice(0, 4).map((card) => (
                                    <Grid item key={card.previewUrl} xs={12} sm={6} md={4} lg={3}>
                                        <ModelCard
                                            id={card.id}
                                            title={card.title}
                                            price={card.price}
                                            alt={card.alt}
                                            creator={card.creator}
                                            initials={card.initials}
                                            previewUrl={card.previewUrl}
                                            url={card.url}
                                            affiliate={card}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                            {/* <BannerAd /> */}
                        </React.Fragment>
                    }
                    {affiliateLinks.length == 0 && puppies !== null && <PuppyBlock />}

                    <Typography variant="h1" component="h3" className={'!text-4xl !mb-5'} >Suggested</Typography>
                    {suggestedChunks.map((chunk, index) => {
                        const thisSlice = affiliateLinks.slice((index * 4) + 4, (index * 4) + 8);
                        return <React.Fragment>
                            <Grid container spacing={6} >
                                {chunk.map((card) =>
                                    <Grid item key={card.previewUrl} xs={12} sm={6} md={4} lg={3}>
                                        <ModelCard
                                            id={card.id}
                                            title={card.title}
                                            alt={card.alt}
                                            creator={card.creator}
                                            initials={card.initials}
                                            previewUrl={card.previewUrl}
                                            url={card.url}
                                        />
                                    </Grid>
                                )}
                            </Grid>

                            {/* Unity asset ad break */}
                            {thisSlice.length > 0 && <Divider id="divTop" style={{ margin: '24px 0 24px 0' }} />}

                            <Grid container spacing={6}>
                                {thisSlice.map((card) => (
                                    <Grid item key={card.previewUrl} xs={12} sm={6} md={4} lg={3}>
                                        <ModelCard
                                            id={card.id}
                                            title={card.title}
                                            price={card.price}
                                            alt={card.alt}
                                            creator={card.creator}
                                            initials={card.initials}
                                            previewUrl={card.previewUrl}
                                            url={card.url}
                                            affiliate={card}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                            {thisSlice.length > 0 &&
                                <Typography variant="body2" color="textSecondary" style={{ marginTop: '10px', marginBottom: '-15px' }}>Paid picks
                                    <IconButton size='small' component={Link} to={'/docs/paidPicks'} target="blank"> <HelpIcon style={{ color: '#b9b9b9', marginLeft: '5px', fontSize: '0.9em' }} /> </IconButton>
                                </Typography>}

                            {/* {thisSlice.length > 0 && <BannerAd className="my-5 mt-4" />} */}

                            {thisSlice.length > 0 && index < suggestedChunks.length - 1 ?
                                <Divider id="divTop" style={{ margin: '24px 0 24px 0' }} /> : <div style={{ margin: '24px 0 24px 0' }}></div>}

                            {thisSlice.length === 0 && chunk.length > 4 && <PuppyBlock seed={index + props.PublicID} />}


                        </React.Fragment>
                    }
                    )}

                </Container>}
            <Footer />

            {/*Download menu*/}
            <Menu
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                {...bindMenu(downloadPopupState)}
            >
                <MenuItem
                    onClick={() => Download('zip')}
                    style={{ paddingRight: '50px' }}>
                    <GetAppIcon
                        className={'text-gray-button'}
                        style={{ margin: '7px' }} />
                    Download {props.model.Type.toUpperCase()}
                </MenuItem>
                <MenuItem onClick={() => Download('glb')} style={{ paddingRight: '50px' }}> <GetAppIcon className={'text-gray-button'} style={{ margin: '7px' }} />Download GLB</MenuItem>
            </Menu>

            {/*Overflow menu*/}
            <Menu
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                {...bindMenu(overflowPopupState)}>

                {canEdit && (<MenuItem to={`/edit/${props.model.PublicID}`} component={Link} style={{ paddingRight: '50px' }}>
                    <CreateIcon className={'text-gray-button m-2'} />Edit
                </MenuItem>)}

                <MenuItem style={{ paddingRight: '50px' }} onClick={() => {
                    // if (props.model.SupporterOnly && !isSupporter) {
                    //     enqueueSnackbar("You need to be a supporter in to do that", { variant: 'info', });
                    //     return;
                    // }
                    var url = document.querySelector('model-viewer').toDataURL('image/png');
                    downloadURI(url, props.model.Title + '.png');
                    setAttributionState(true);
                    window?.plausible('Screenshot', { props: { model: props.model.Title, loggedIn: props.isLoggedIn } });
                }}> <CameraAltIcon className={'text-gray-button m-2'} />Screenshot</MenuItem>

                <MenuItem className="mr-12" onClick={() => setAttributionState(true)}>
                    <ReceiptIcon className={'text-gray-button m-2'} />Attribution
                </MenuItem>

                <MenuItem className="mr-12" onClick={() => setSendToOpen(true)}>
                    <LaunchIcon className={'text-gray-button m-2'} />Send To App
                </MenuItem>

                <MUILink target='_blank' rel="noopener" color="textPrimary" href={`https://docs.google.com/forms/d/e/1FAIpQLSdPwCRv9Gt36TJb-GRbygco2R-UTdRiJpzVLEgamBABknFEUQ/viewform`}><MenuItem style={{ paddingRight: '50px' }}> <FlagIcon className={'text-gray-button'} style={{ margin: '7px' }} />Report</MenuItem></MUILink>
            </Menu>

            <AttributionPopup
                handleClose={() => { setAttributionState(false) }}
                snackbar={enqueueSnackbar}
                open={attributionPopupState}
                model={props.model}
                adText={puppies && puppies[adIndex]}
                ToggleLike={() => ToggleLikeState()}
                LikeState={likeState}
            />

            {popupIsOpen && <AffiliateInfoPopup
                handleDialogClose={() => setPopupOpen(false)}
                open={popupIsOpen}
            //model={props.model}
            />}
            <SimplePopup
                handleClose={() => { setadblockNotice(false); Download('zip') }}
                open={adblockNotice}
                closeText='Nah'
                title={'⚠️ DOWNLOAD BLOCKED - ADBLOCK DETECTED ⚠️'}
                action={() => window.open('/support', '_blank').focus()}
                actionText='Support the site'
                body={
                    <div>
                        <Typography style={{ marginBottom: '25px', fontWeight: '500' }} color="textPrimary">Haha nah just kidding</Typography>
                        <Typography style={{ marginBottom: '25px' }} color="textPrimary">Unlike other sites we'll never put downloads behind a paywall, login or adblock wall. Because that shit is annoying!</Typography>
                        <Typography style={{ marginBottom: '25px' }} color="textPrimary">We'd like to keep everything free but if server costs become too much we'll be forced to shut down the site</Typography>
                        <Typography style={{ marginBottom: '25px' }} color="textPrimary">So please think about turning off your adblocker (our ads are tiny and won't track you)</Typography>
                        <Typography style={{ marginBottom: '25px' }} color="textPrimary">If you really hate ads keep it on and consider donating a few dollars</Typography>
                        <Typography style={{ marginBottom: '25px' }} color="textPrimary">Or don't do any of that! That's cool too. Thanks for using Poly Pizza!</Typography>
                    </div>
                }
            />

            <SimplePopup
                handleClose={() => { setdownloadBlockNotice(false); Download('zip') }}
                open={downloadBlockNotice}
                closeText='Nah'
                title={'⚠️ DOWNLOAD LIMIT REACHED ⚠️'}
                action={() => window.open('/support', '_blank').focus()}
                actionText='Support the site'
                body={
                    <div>
                        <Typography style={{ marginBottom: '25px', fontWeight: '500' }} color="textPrimary">Haha nah just kidding</Typography>
                        <Typography style={{ marginBottom: '25px' }} color="textPrimary">Unlike other sites we'll never put downloads behind a paywall, login or adblock wall. Because that shit is annoying!</Typography>
                        <Typography style={{ marginBottom: '25px' }} color="textPrimary">We'd like to keep everything free but if server costs become too much we'll be forced to shut down the site</Typography>
                        <Typography style={{ marginBottom: '25px' }} color="textPrimary">So please consider donating a few dollars if the site is useful to you</Typography>
                        <Typography style={{ marginBottom: '25px' }} color="textPrimary">Or just keep using the site, that's cool too. Thanks for using Poly Pizza!</Typography>
                    </div>
                }
            />
            <ListSelectDialog
                open={listSelectOpen}
                setOpen={setListSelectOpen}
                model={props.model}
            />

            <SendToDialog open={sendToOpen} setOpen={setSendToOpen} model={props.model} />
        </div>
    )
}

function downloadURI(uri, name) {
    var link = document.createElement("a");
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}


Detail.getInitialProps = async ({ req, res, match, history, location, ...ctx }) => {
    var promises = [];

    //stupid seo thing
    if (match.url.endsWith('/')) return { redirectTo: match.url.substring(0, match.url.length - 1) }

    //Add query string if coming from edit page to refresh cache so changes are acually updated
    let qs = '';
    try {
        if (history.location.state.from === 'edit') {
            qs = '?time=' + new Date().getTime();
        }
    } catch (e) { }

    //Catch axios errors
    let statusCode;
    const getStatus = (e) => {
        if (e.response && e.response.status) {
            statusCode = e.response.status
            console.log(e.response.status)
        }
        else
            console.log(e.message);
    };

    //only get user when not SSR
    const User = !req ? JSON.parse(localStorage.getItem('user')) : {};

    //append authed details request to public one
    let model = {};
    let interactions = {};
    // const setModelData = (response) => {
    //     Object.keys(response.data).map(k => model[k] = response.data[k]);
    // };

    //Get Model details 
    promises.push(
        axios.get(`${process.env.SITE_ROOT}/api/model/${match.params.id}/details${qs}`)
            .then((d) => model = d.data)
            .catch(getStatus)
    );

    //if logged in + SSR get interactions with model, like if the user liked it and if they can edit it  
    if (ctx.authed && req !== undefined && req.headers.cookie) {
        const URL = `${process.env.SITE_ROOT}/api/model/${match.params.id}/details/interactions`;
        promises.push(axios.get(URL, { headers: { cookie: req.headers.cookie } }) //send through cookies from original request
            .then((r) => interactions = r.data)
            .catch(getStatus)
        );
    }
    else { //otherwise default to no - authed client users will fetch from use effect since this isn't essential for page load
        interactions.CanEdit = false;
        interactions.LikedByUser = false;
    }

    //Only get suggested after page load, shit takes ages lol
    let suggested = new Array(4).fill(null);
    //Get Suggested on server render only, otherwise get on client so glb can load in parallel bc get suggested is heavy when uncached
    // if (req) {
    //     promises.push(
    //         axios.get(`${process.env.SITE_ROOT}/api/model/${match.params.id}/suggested`)
    //             .then((response) => suggested = response.data)
    //             .catch(getStatus)
    //     );
    // }

    await Promise.all(promises);
    if (statusCode !== undefined) return { statusCode };

    let isLoggedIn;
    let darkMode;

    isLoggedIn = req !== undefined ? ctx.authed : authed;  //if SSR, use authed from context, otherwise use local var. This is because ctx isn't updated by authed status :(
    darkMode = ctx.darkMode;

    return { model, suggested, isLoggedIn, User, darkMode, interactions };
}


export default Detail;